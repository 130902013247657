import React from 'react';
import InvoiceBill from './InvoiceBill';
import ConfirmationPopup from './ConfirmationPopup';
import giftBox from "../Assets/Billingpage/gift-box.png";

const SalesEditsPopup = ({
    isOpen,
    handleUpdate,
    handleClosePopup,
    editData,
    handleSearchChange,
    searchQuery,
    filteredProducts,
    handleProductSelection,
    isProductSelected,
    clearSearchResults,
    deleteButtonProductIds,
    handleDeleteItem,
    getProductQuantity,
    decrementQuantity,
    incrementQuantity,
    handleRemoveItem,
    gst,
    setGst,
    discount,
    setDiscount,
    shippingCharge,
    setShippingCharge,
    removedProductsTotal,
    overallTotal,
    finalTotal,
    invoicePopupOpen,
    setInvoicePopupOpen,
    billData,
    confirmationPopupOpen,
    confirmRemoveItem,
    cancelRemoveItem,
    selectedItemName,
    selectedStatus,
    handleQuantityChange,
    handleUpdateButtonClick,
    paymentStatus,
    setPaymentStatus,
    paymentMode,
    setPaymentMode,
    subtotals,
    overallSubtotal,
    handleRemoveAll,
    selectedGiftPrice,
}) => {
    if (!isOpen) return null;

    const handleStatusChange = (e) => {
        const newStatus = e.target.value;
        setPaymentStatus(newStatus);
    };

    const handlePaymentModeChange = (e) => {
        const newMode = e.target.value;
        setPaymentMode(newMode);
    };



    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-80">
            <div className="p-6 bg-gray-50 max-w-5/6 w-full h-full overflow-y-auto ml-8">
                <form className="bg-white p-6 rounded-lg shadow-md" onSubmit={handleUpdate}>
                    <div className="grid grid-cols-7 gap-4">
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Date</label>
                            <input type="date" className="border rounded w-full py-2 px-3 text-gray-700 bg-green-200 cursor-not-allowed" value={editData.date} readOnly />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Warehouse</label>
                            <input type="text" className="border rounded w-full py-2 px-3 text-gray-700 bg-green-200 cursor-not-allowed" value={editData.warehouse} readOnly />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Customer</label>
                            <input type="text" className="border rounded w-full py-2 px-3 text-gray-700 bg-green-200 cursor-not-allowed" value={editData.customer} readOnly />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Search</label>
                            <input type="text" className="border rounded-lg w-full py-2 px-3 text-gray-700 bg-green-200" placeholder="Search Product" value={searchQuery}
                                onChange={handleSearchChange} />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Payment Status</label>
                            <select value={paymentStatus || editData.status} onChange={handleStatusChange} className="border rounded w-full py-2 px-3 text-gray-700 bg-green-200">
                                <option value="Paid">Paid</option>
                                <option value="Pending">Pending</option>
                                <option value="COD">COD</option>
                                <option value="Cancelled">Cancelled</option>
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Payment Type</label>
                            <select value={paymentMode || editData.paymentMode} onChange={handlePaymentModeChange} className="border rounded w-full py-2 px-3 text-gray-700 bg-green-200">
                                <option value="cash">Cash</option>
                                <option value="card">Card</option>
                                <option value="digital">UPI</option>
                            </select>
                        </div>

                        <div className="mb-4 pt-7">
                            <button type="button" onClick={handleClosePopup} className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 mr-4">Cancel</button>
                            <button type="submit" onClick={handleUpdateButtonClick} className=" text-gray-700 px-4 py-2 rounded-lg " style={{ backgroundColor: "#0bfc03" }}>
                                Update
                            </button>
                        </div>

                    </div>

                    <div className="mb-4 p-1 bg-yellow-100 ">

                        {filteredProducts.length === 0 && searchQuery.trim() !== '' && (
                            <div className="text-red-500 mt-2">Search product not available</div>
                        )}
                        {filteredProducts.length > 0 && (
                            <div className="relative mt-4">
                                <div className="overflow-y-auto h-52">
                                    <div className="grid grid-cols-4 gap-4">
                                        {filteredProducts.map((product, index) => (
                                            <div
                                                key={index}
                                                className={`p-2 cursor-pointer border rounded shadow-md shadow-lime-300 bg- ${isProductSelected(product.productId) ? 'bg-orange-400' : 'hover:bg-green-400'}`}
                                                onClick={() => handleProductSelection(product)}
                                            >
                                                <p className="text-center">{product.name}</p>
                                                <p className="text-center text-sm font-semibold text-purple-700">{product.productsCode}</p>
                                                <p className="text-center text-sm font-semibold text-red-500">Quantity: {product.quantity}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <button
                                    onClick={clearSearchResults}
                                    className="block w-full text-center py-2 bg-orange-300 hover:bg-red-400 text-gray-800 font-semibold rounded-lg mt-2"
                                >
                                    Clear Search Results
                                </button>
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Order items:</label>
                        <div className="max-h-96 overflow-y-auto">
                            <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
                                <thead className="bg-blue-500">
                                    <tr>
                                        <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">PRODUCT</th>
                                        <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">NET UNIT PRICE</th>
                                        <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">UNIT</th>
                                        <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">QUANTITY</th>
                                        <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">SUBTOTAL</th>
                                        <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">
                                            Action
                                            <button
                                                type="button" // Ensure it's not a submit button
                                                onClick={handleRemoveAll}
                                                className="ml-2 px-2 py-1 bg-red-500 text-white rounded-lg text-xs hover:bg-red-600"
                                            >
                                                Return All
                                            </button>

                                        </th>

                                    </tr>
                                </thead>

                                <tbody className="divide-y divide-gray-200">
                                    {editData.orderItems.map((orderItem, index) => (
                                        <tr key={index} className="hover:bg-green-200" data-product-id={orderItem.productId}>
                                            <td className="py-2 px-4 text-left">
                                                {deleteButtonProductIds.includes(orderItem.productId) && (
                                                    <button type="button" onClick={() => handleDeleteItem(index)}
                                                        className="text-sm p-1 rounded bg-yellow-300 hover:bg-green-500"
                                                        title="Delete">
                                                        ❌
                                                    </button>
                                                )}
                                                {orderItem.name} <br />
                                                <span className="text-orange-600 font-semibold hidden" >Stocks Quantity : {getProductQuantity(orderItem.productId)}</span>
                                            </td>
                                            {/* <td className="py-2 px-4 text-left">₹ {orderItem.price ? orderItem.price.toFixed(2) : 'N/A'}</td> */}
                                            <td className="py-2 px-4 text-left">
                                                {orderItem.price > 0 ? (
                                                    `₹ ${orderItem.price.toFixed(2)}`
                                                ) : (
                                                    <img src={giftBox} alt="Gift Box" className="h-8 w-8" />
                                                )}
                                            </td>

                                            <td className="py-2 px-4 text-left">{orderItem.unit}</td>
                                            <td className="hidden">{orderItem.unitId}</td>
                                            <td className="py-2 px-4 text-left">
                                                <div className="flex items-center">
                                                    <button type="button" onMouseDown={() => decrementQuantity(index)} className="px-2 py-1 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded" > -</button>
                                                    <input type="number" value={orderItem.quantity}
                                                        onChange={(e) => {
                                                            let value = parseFloat(e.target.value);
                                                            if (isNaN(value) || value < 0.001) {
                                                                value = 0; // Set minimum value
                                                            }
                                                            // Limit to three decimal places
                                                            value = parseFloat(value.toFixed(3));
                                                            handleQuantityChange(index, value); // Handle quantity change
                                                        }}
                                                        step="0.001"
                                                        min="0.001"
                                                        className="w-20 text-center border-gray-300 border py-1 mx-2" />
                                                    <button type="button" onMouseDown={() => incrementQuantity(index)} className="px-2 py-1 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded" >
                                                        +
                                                    </button>
                                                </div>
                                            </td>

                                            <td className="py-2 px-4 text-left">
                                                {orderItem.price > 0 ? (
                                                    `₹ ${(orderItem.quantity * orderItem.price).toFixed(2)}`
                                                ) : (
                                                    <img src={giftBox} alt="Gift Box" className="h-8 w-8" />
                                                )}
                                            </td>


                                            {/* <td className="py-2 px-4 text-left">₹ {(orderItem.quantity * (orderItem.price || 0)).toFixed(2)}</td> */}
                                            <td className="py-2 px-0 text-center space-x-2">
                                                <button type="button" onClick={() => handleRemoveItem(index, 'Purchase')}
                                                    className={`text-sm p-1 rounded text-black ${orderItem.productStatus === 'Purchase' ? 'bg-green-400' : 'bg-yellow-100 border'}`}
                                                    style={{ backgroundColor: orderItem.productStatus === 'Purchase' ? '#03fc07' : '#b3d8e3' }}>
                                                    Purchase
                                                </button>
                                                <button type="button" onClick={() => handleRemoveItem(index, 'Removed')}
                                                    className={` text-sm p-1 rounded text-black ${orderItem.productStatus === 'Removed' ? 'bg-green-400' : 'bg-yellow-100 border'}`}
                                                    style={{ backgroundColor: orderItem.productStatus === 'Removed' ? '#03fc07' : '#b3d8e3' }}>
                                                    Return
                                                </button>
                                                <button type="button" onClick={() => handleRemoveItem(index, 'Wastage')}
                                                    className={`text-sm p-1 rounded text-black ${orderItem.productStatus === 'Wastage' ? 'bg-green-400' : 'bg-yellow-100 border'}`}
                                                    style={{ backgroundColor: orderItem.productStatus === 'Wastage' ? '#03fc07' : '#b3d8e3' }}>
                                                    Wastage
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-4 flex items-center justify-between bg-yellow-400 py-2 px-1 rounded-lg">

                            <div className="flex items-center">
                                <label className="font-semibold mr-4">GST (%):</label>
                                <input
                                    type="number" className="border p-1 w-20 rounded-lg font-bold mr-8" value={gst}
                                    onChange={(e) => {
                                        let value = Number(e.target.value);
                                        if (value > 100) {
                                            value = 100;
                                        }
                                        value = Math.max(0, value);
                                        setGst(value);
                                    }} placeholder="Enter discount percentage" />
                            </div>

                            <div className="flex items-center">
                                <label className="font-semibold mr-4">Discount (%):</label>
                                <input
                                    type="number" className="border p-1 w-20 rounded-lg font-bold mr-8" value={discount}
                                    onChange={(e) => {
                                        let value = Number(e.target.value);
                                        if (value > 100) {
                                            value = 100;
                                        }
                                        value = Math.max(0, value);
                                        setDiscount(value);
                                    }} placeholder="Enter discount percentage" />
                            </div>

                            <div className="flex items-center">
                                <label className="font-semibold mr-4">Shipping Charge (₹):</label>
                                <input type="number" className="border p-1 w-20 rounded-lg font-bold mr-8" value={shippingCharge}
                                    onChange={(e) => {
                                        const value = Math.max(0, Number(e.target.value)); // Ensure value is not negative
                                        setShippingCharge(value);
                                    }} />
                            </div>
                            <div>
                                {subtotals.map((subtotal, index) => (
                                    <p key={index} className="text-sm text-red-500 hidden">
                                        Subtotal: ₹ {subtotal.toFixed(2)}
                                    </p>
                                ))}
                                <p className="text-xl font-bold text-gray-700">
                                    New Pro Total: ₹ {overallSubtotal}
                                </p>
                            </div>
                            <p className="text-xl font-bold text-red-700">Return Total: ₹ {removedProductsTotal.toFixed(2)}</p>
                            <p className="font-semibold">Sub Total: ₹ {overallTotal.toFixed(2)}</p>
                            <p className="text-xl font-bold text-gray-700">Final Total : ₹ {finalTotal.toFixed(2)}</p>
                        </div>

                    </div>
                </form>
                {billData && (
                    <InvoiceBill isOpen={invoicePopupOpen} onClose={() => setInvoicePopupOpen(false)} billData={billData} editsClose={handleClosePopup} selectedGiftPrice={selectedGiftPrice} />
                )}

                {confirmationPopupOpen && (
                    <ConfirmationPopup isOpen={confirmationPopupOpen} onConfirm={confirmRemoveItem} onCancel={cancelRemoveItem} productName={selectedItemName.toLowerCase()}
                        message={selectedStatus.toLowerCase()} />
                )}
            </div>
        </div>
    );
};

export default SalesEditsPopup;