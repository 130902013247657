import React, { useEffect, useState } from 'react';

const MostSelling = ({ warehouseId }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem('token');

        let url = 'https://pos.farm2bag.com/api/v1/billingItems/mostSelling';
        if (warehouseId) {
            url += `?warehouseId=${warehouseId}`;
        }

        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(json => {
                if (json.status === 200) {
                    setData(json.data);
                }
            })
            .catch(error => console.error('Error fetching data:', error));
    }, [warehouseId]);

    const romanNumerals = ['Ⅰ', 'Ⅱ', 'Ⅲ', 'Ⅳ', 'Ⅴ', 'Ⅵ', 'Ⅶ', 'Ⅷ', 'Ⅸ', 'Ⅹ'];

    const rowColors = [
        'bg-green-100',
        'bg-red-100',
        'bg-blue-100',
        'bg-yellow-100',
        'bg-purple-100',
        'bg-orange-100',
        'bg-pink-100',
        'bg-teal-100',
        'bg-indigo-100',
        'bg-gray-200'
    ];

    return (
        <div>
            <table className="min-w-full bg-white rounded-lg ">
                <thead>
                    <tr className="bg-black text-white">
                        <th className="py-3 px-4 border-b-2 border-gray-300 text-center">Rank</th>
                        <th className="py-3 px-4 border-b-2 border-gray-300 text-left">Product Name</th>
                    </tr>
                </thead>
                <tbody>
                    {data.length > 0 ? (
                        data.map((item, index) => (
                            <tr key={item.productId} className={`hover:bg-orange-500 hover:text-white ${rowColors[index % rowColors.length]}`}>
                                <td className={`py-1 px-4 border-b border-gray-300 font-semibold `}>{romanNumerals[index] || 'N/A'}</td>
                                <td className={`py-1 font-serif px-4 border-b border-gray-300 text-left font-semibold truncate max-w-xs`} title={item.productName}>
                                    {item.productName}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="2" className="py-3 px-4 text-center border-b border-gray-300">No data available</td>
                        </tr>
                    )}
                </tbody>
            </table>
            <h2 className="text-xl text-center font-bold mb-2 text-blue-600">Top 10 Products by Customer Preference</h2>
        </div>
    );
}

export default MostSelling;
