import React from 'react';

const WastageDelete = ({ handleDelete, setShowDeletePopup, selectedWastage }) => {
  // Ensure selectedWastage is defined and has the expected structure
  if (!selectedWastage || !selectedWastage.wastageId || !selectedWastage.product.productId) {
    return null; // Or handle error state
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-xl font-semibold mb-4">Delete Wastage</h2>
        <p>Are you sure you want to delete this product from the wastage?</p>
        <div className="flex justify-end mt-4">
          <button
            type="button"
            className="bg-red-500 text-white px-4 py-2 rounded-lg mr-2"
            onClick={() => handleDelete(selectedWastage.wastageId, selectedWastage.product.productId)}
          >
            Delete
          </button>
          <button
            type="button"
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg"
            onClick={() => setShowDeletePopup(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default WastageDelete;
