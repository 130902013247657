import React from 'react';
import { toast } from 'react-toastify';

const QualityCheckingDelete = ({ id, onClose, fetchData, invoiceNo, purchaseOrder }) => {
    const handleDelete = async () => {
        const token = localStorage.getItem('token');
        const headers = new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        });

        try {
            const response = await fetch(`https://pos.farm2bag.com/api/v1/qualityChecking/${id}`, {
                method: 'DELETE',
                headers
            });

            if (response.ok) {
                toast.success("Successfully Deleted Quality Checking");
                fetchData();
                onClose();
            } else {
                toast.error("Failed to Delete Quality Checking");
            }
        } catch (error) {
            toast.error("An error occurred while deleting");
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                <h2 className="text-xl font-bold mb-4">Delete Quality Checking</h2>
                <p className="text-gray-600 mb-6">Are you sure you want to delete <strong className='text-black'>{invoiceNo}</strong> this quality checking record?</p>
                <div className="flex justify-evenly">
                    <button onClick={onClose} className="bg-green-500 text-black px-4 py-2 rounded mr-2">Cancel</button>
                    <button onClick={handleDelete} className="bg-red-500 text-white px-4 py-2 rounded"> Delete</button>
                </div>
            </div>
        </div>
    );
};

export default QualityCheckingDelete;
