import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoMdTrash } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import filter from "../Assets/tableicon/filter.png";
import f2bimage from '../Assets/login/f2bimage.png';
import AWS from 'aws-sdk';
import Switch from "react-switch";
import SearchInput from 'react-search-input';


const S3_BUCKET = 'f2b-pos';
const REGION = 'ap-south-1';
const ACCESS_KEY = 'AKIAQSNAUQE5MBTDHWHK';
const SECRET_ACCESS_KEY = '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';


const ProductCategory = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [isCreatePopupOpen, setCreatePopupOpen] = useState(false);
    const [isEditPopupOpen, setEditPopupOpen] = useState(false);
    const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [updateCategoryName, setUpdateCategoryName] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [image, setImage] = useState(null);
    const [sortBy, setSortBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');


    useEffect(() => {
        fetchData();
    }, [currentPage, itemsPerPage]);

    const fetchData = async () => {
        try {
            const token = localStorage.getItem('token');
    
            if (!token) {
                console.error('No token found in local storage');
                return;
            }
    
            const response = await fetch('https://pos.farm2bag.com/api/v1/categories/getAll/master', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const responseData = await response.json();
            const categoryData = responseData.data.map(category => ({
                id: category.id,
                image: category.image,
                categoryName: category.categoryName,
                count: category.productCount,
                status: category.status,
                createdAt: category.createdAt // Assuming createdAt is part of the response
            }));
    
            // Sort data by createdAt in descending order
            const sortedData = categoryData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    
            setData(sortedData);
            const totalPages = Math.ceil(sortedData.length / itemsPerPage);
            if (currentPage > totalPages) {
                setCurrentPage(totalPages);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    

    const handleSort = (key) => {
        const nextSortOrder = sortBy === key ? (sortBy === `${key}_desc` ? null : `${key}_desc`) : key;
        let sortedData;
    
        if (nextSortOrder) {
            sortedData = [...data].sort((a, b) => {
                const aValue = key === 'createdAt' ? new Date(a[key]) : a[key];
                const bValue = key === 'createdAt' ? new Date(b[key]) : b[key];
    
                if (nextSortOrder === `${key}_desc`) {
                    return bValue - aValue;  // Descending
                } else {
                    return aValue - bValue;  // Ascending
                }
            });
        } else {
            sortedData = [...data];
        }
    
        setData(sortedData);
        setSortBy(nextSortOrder);
    };
    
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data
        .filter(item =>
            item.categoryName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.count.toString().includes(searchTerm.toLowerCase())
        )
        .slice(indexOfFirstItem, indexOfLastItem);

    const handleEdit = (category) => {
        setSelectedCategory(category);
        setUpdateCategoryName(category.categoryName);
        setEditPopupOpen(true);
    };

    const handleDelete = (category) => {
        setSelectedCategory(category);
        setDeletePopupOpen(true);
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleItemsPerPageChange = (e) => {
        setCurrentPage(1);
        setItemsPerPage(parseInt(e.target.value));
    };

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const handleCreateCategory = async () => {
        try {
            if (!newCategoryName.trim()) {
                toast.error('Category name cannot be empty');
                return;
            }

            if (!image) {
                // No image provided, proceed to create the category with an empty image URL
                createCategoryWithImage("");
                return;
            }

            const s3 = new AWS.S3({
                accessKeyId: ACCESS_KEY,
                secretAccessKey: SECRET_ACCESS_KEY,
                region: REGION,
            });

            const params = {
                Bucket: S3_BUCKET,
                Key: image.name,
                Body: image,
                ContentType: image.type,
                ACL: 'public-read',
            };

            s3.upload(params, (err, data) => {
                if (err) {
                    console.error(err);
                    toast.error('Error uploading file to S3');
                } else {
                    console.log('File uploaded successfully:', data.Location);
                    createCategoryWithImage(data.Location);
                }
            });
        } catch (error) {
            console.error('Error creating category:', error);
            toast.error('Error creating category');
        }
    };

    const createCategoryWithImage = async (imageUrl) => {
        const token = localStorage.getItem('token');
        const role = localStorage.getItem('role');

        try {
            const response = await fetch('https://pos.farm2bag.com/api/v1/categories', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    categoryName: newCategoryName,
                    createdBy: role,
                    image: imageUrl || ""
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                toast.error(`Failed to create category: ${errorData.message || response.statusText}`);
                return; // Exit the function if the request was not successful
            }

            setCreatePopupOpen(false);
            setNewCategoryName('');
            setImage(null);

            fetchData();

            toast.success('Category created successfully');
        } catch (error) {
            console.error('Error creating category:', error);
            toast.error('Error creating category');
        }
    };

    const handleFileInput = (e) => {
        setImage(e.target.files[0]);
    };

    const handleUpdateCategory = async () => {
        try {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');

            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const id = selectedCategory.id;

            if (!updateCategoryName.trim()) {
                toast.error('Category name cannot be empty');
                return;
            }

            if (!image) {
                // No image provided, proceed to update the category with the existing or no image URL
                updateCategoryWithImage("");
                return;
            }

            const s3 = new AWS.S3({
                accessKeyId: ACCESS_KEY,
                secretAccessKey: SECRET_ACCESS_KEY,
                region: REGION,
            });

            const params = {
                Bucket: S3_BUCKET,
                Key: image.name,
                Body: image,
                ContentType: image.type,
                ACL: 'public-read',
            };

            s3.upload(params, async (err, data) => {
                if (err) {
                    console.error(err);
                    toast.error('Error uploading file to S3');
                } else {
                    console.log('File uploaded successfully:', data.Location);
                    updateCategoryWithImage(data.Location);
                }
            });
        } catch (error) {
            console.error('Error updating category:', error);
            toast.error('Error updating category');
        }
    };


    const updateCategoryWithImage = async (imageUrl) => {
        const token = localStorage.getItem('token');
        const role = localStorage.getItem('role');
    
        const response = await fetch(`https://pos.farm2bag.com/api/v1/categories/${selectedCategory.id}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                categoryName: updateCategoryName,
                modifiedBy: role,
                status: 1,
                image: imageUrl || selectedCategory.image || "" // Use new image URL or existing image URL
            })
        });
    
        if (!response.ok) {
            const errorData = await response.json();
            toast.warning(errorData.message || 'Failed to update category' ,{
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {
                  backgroundColor: "#ff9500",
                  color: "black",
                },
              });
            return;
        }
    
        setEditPopupOpen(false);
        setUpdateCategoryName('');
        setImage(null); // Clear the image state after updating
    
        fetchData();
    
        toast.success('Category updated successfully');
    };
    


    const toggleStatus = async (selectCategorys) => {
        try {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');

            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const id = selectCategorys.id;
            const newStatus = selectCategorys.status === 0 ? 1 : 0; // Toggle status value

            const response = await fetch(`https://pos.farm2bag.com/api/v1/categories/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    categoryName: selectCategorys.categoryName,
                    image: selectCategorys.image,
                    status: newStatus,
                    modifiedBy: role
                })
            });

            if (!response.ok) {
                throw new Error('Failed to toggle status');
            }

            console.log('Status toggled successfully');

            // Update the status value in the data array
            setData(data.map(item => item.id === id ? { ...item, status: newStatus } : item));
        } catch (error) {
            console.error('Error toggling status:', error);
        }
    };

    const handleDeleteCategory = async () => {
        try {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');
    
            if (!token) {
                console.error('No token found in local storage');
                return;
            }
    
            const id = selectedCategory.id;
    
            // Check if the selected category has products
            if (selectedCategory.count > 0) {
                toast.warning('This category cannot be deleted because it contains products.' ,{
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    style: {
                      backgroundColor: "#ff9500",
                      color: "black",
                    },
                  });
                return;
            }
    
            const response = await fetch(`https://pos.farm2bag.com/api/v1/categories/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ modifiedBy: role })
            });
    
            if (!response.ok) {
                throw new Error('Failed to delete category');
            }
    
            setDeletePopupOpen(false);
    
            // After successfully deleting the category, you might want to refresh the data
            fetchData();
    
            toast.success('Category deleted successfully');
    
        } catch (error) {
            console.error('Error deleting category:', error);
        }
    };
    

    return (
        <div className='bg-gray-100 ml-64 mt-20 p-12 h-screen'>
            <div className="container mx-auto">
                <div className="mb-10">
                    <h1 className="font-extrabold text-3xl text-left text-gray-500">Product Category</h1>
                    <div className="mt-2 border-t border-gray-500"></div>
                </div>
                <div className="flex items-center mb-5">
                    <div className="ml-0">
                        <form>
                            <div className="relative">
                                <SearchInput onChange={(term) => setSearchTerm(term)} placeholder="🔍 category name"
                                    style={{ padding: '6px', border: '1px solid #ccc', borderRadius: '5px', width: '120%', fontSize: '1rem', outline: 'none',fontWeight: 600 }} />
                            </div>
                        </form>
                    </div>
                    <div className="flex ml-auto mr-0 items-center space-x-4">
                        <img src={filter} alt="filter" className="w-12 h-10 rounded-lg cursor-pointer" onClick={() => handleSort('categoryName')} />
                        <button onClick={() => setCreatePopupOpen(true)} style={{ backgroundColor: 'rgba(66, 255, 97, 1)' }} className="w-48 h-10 font-medium text-sm rounded-lg">Create Product Category</button>
                    </div>
                </div>

                <div className="rounded-lg overflow-hidden">
                    <table className="table-auto w-full border-collapse border-gray-500 text-center">
                        <thead>
                            <tr className="bg-gray-200">
                                <th className="px-4 py-2 text-left pl-16">Image</th>
                                <th className="px-4 pl-16 py-2 text-left">Product Category</th>
                                <th className="px-4 py-2 text-center">Product Count</th>
                                <th className="px-4 py-2 text-center">Status</th>
                                <th className="px-4 py-2 text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((item, index) => (
                                <tr key={item.id} className={(index % 2 === 0) ? 'bg-white' : 'bg-gray-200'}>
                                    <td className="px-4 py-2 border-gray-500 pl-16"><img src={item.image || f2bimage} alt="Category" className='w-16 h-14' /></td>
                                    <td className="px-4 pl-16 py-2 border-gray-500 text-left">{item.categoryName}</td>
                                    <td className="px-4 py-2 border-gray-500">{item.count}</td>
                                    <td><Switch
                                        onChange={() => toggleStatus(item)}
                                        checked={item.status === 1}
                                    /> </td>

                                    <td className="px-4 py-2 border-gray-500">
                                        <button onClick={() => handleEdit(item)} className="mr-2 font-bold rounded">
                                            <FaRegEdit size={22} style={{ color: "blue" }} className="inline-block mr-1" />
                                        </button>

                                        <button onClick={() => handleDelete(item)} className=" text-white font-bold rounded">
                                            <IoMdTrash size={30} style={{ color: "red" }} className="inline-block mr-1" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {currentItems.length === 0 && (
                    <div className="text-center mt-4 text-gray-500">
                        No result found.
                    </div>
                )}

                {isCreatePopupOpen && (
                    <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
                        <div className="bg-gray-200 w-2/6 h-2/6 p-6 rounded-lg">
                            <h2 className="text-xl font-bold mb-4">Create New Category</h2>
                            <div className="mb-4 border-t border-gray-500"></div>
                            <input type="text" value={newCategoryName} onChange={(e) => setNewCategoryName(e.target.value)} placeholder="Enter category name" className="border border-gray-200 px-4 py-2 rounded-lg mb-4 w-4/5" /> <br />
                            <div className='p-2 bg-white w-4/5 rounded-lg ml-12'><input type="file" id="image" name="image" onChange={handleFileInput} /></div>
                            <br />
                            <button onClick={() => setCreatePopupOpen(false)} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg float-left ml-11 hover:bg-red-500">Cancel</button>
                            <button onClick={handleCreateCategory} className="bg-blue-500 text-white px-4 py-2 rounded-lg float-right mr-11 hover:bg-green-600">Create</button>
                        </div>
                    </div>
                )}

                {isEditPopupOpen && (
                    <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
                        <div className="bg-gray-200  w-2/6 h-2/6 p-6 rounded-lg">
                            <h2 className="text-xl font-bold mb-4">Edit Category</h2>
                            <input type="text" value={updateCategoryName} onChange={(e) => setUpdateCategoryName(e.target.value)} placeholder="Enter new category name" className="border border-gray-200 px-4 py-2 rounded-lg mb-4 w-4/5" />
                            <div className='p-2 bg-white w-4/5 rounded-lg ml-12'><input type="file" id="image" name="image" onChange={handleImageChange} /></div>
                            <button onClick={() => setEditPopupOpen(false)} className="bg-blue-500 text-white px-4 py-2 rounded-lg float-left mr-11 mt-10 hover:bg-red-600">Cancel</button>
                            <button onClick={handleUpdateCategory} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg ml-2 float-right ml-11 mt-10 hover:bg-green-500">Update</button>
                        </div>
                    </div>
                )}
                {isDeletePopupOpen && (
                    <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
                        <div className="bg-white p-6 rounded-lg">
                            <h2 className="text-xl font-bold mb-4">Delete Category</h2>
                            <p>Are you sure you want to delete the category " <span className='font-semibold text-red-600'>{selectedCategory.categoryName}</span> "?</p>
                            <button onClick={() => setDeletePopupOpen(false)} className="bg-blue-500 text-white px-4 py-2 rounded-lg float-left mr-11 mt-10 hover:bg-red-600">Cancel</button>
                            <button onClick={handleDeleteCategory} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg ml-2 float-right ml-11 mt-10 hover:bg-green-500">Delete</button>
                        </div>
                    </div>
                )}

                <div className="flex justify-between items-center mt-10 pb-10">
                    <div>
                        <label htmlFor="itemsPerPage" className="mr-2">Items per page:</label>
                        <select id="itemsPerPage" value={itemsPerPage} onChange={handleItemsPerPageChange} className="border border-gray-300 rounded-lg px-2 py-1">
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                            <option value={20}>20</option>
                        </select>
                    </div>
                    <div className="flex space-x-2">
                        {Array.from({ length: Math.ceil(data.length / itemsPerPage) }, (_, index) => (
                            <button
                                key={index}
                                onClick={() => paginate(index + 1)}
                                className={`px-3 py-1 rounded ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </div>

            </div>
            <ToastContainer />
        </div>
    );
};

export default ProductCategory;