import React, { useState, useEffect } from "react";
import Switch from "react-switch";
import { toast, ToastContainer } from "react-toastify";
import { IoMdTrash } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import Searchicon from "../Assets/tableicon/Searchicon.png";
import RolePopup from "../components/RolePopup";

const Role = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupType, setPopupType] = useState("");
  const [newRoleName, setNewRoleName] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [newRoleNameError, setNewRoleNameError] = useState("");
  const [selectedModelId, setSelectedModelId] = useState(null);


  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("No token found in local storage");
        return;
      }

      const response = await fetch("https://pos.farm2bag.com/api/v1/roles", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      const rolesData = responseData.data.map((role) => ({
        roleId: role.roleId,
        RoleName: role.roleName,
        status: role.status,
        modelsId: role.modelsId
      }));
      setData(rolesData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const handleEdit = (role) => {
    setSelectedRole(role);
    setNewRoleName(role.RoleName);
    setSelectedModelId(role.modelsId);
    setPopupType("edit");
    setIsPopupOpen(true);
    console.log("modelid", selectedRole);
  };

  const handleDelete = (role) => {
    setSelectedRole(role);
    setPopupType("delete");
    setIsPopupOpen(true);
  };


  const handleCreateRole = async (selectedModels) => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("No token found in local storage");
        return;
      }

      if (!newRoleName.trim()) {
        setNewRoleNameError("Role name cannot be empty");
        return;
      } else if (/\d/.test(newRoleName)) {
        setNewRoleNameError("Role name cannot contain numbers");
        return;
      }
      setNewRoleNameError("");

      const response = await fetch("https://pos.farm2bag.com/api/v1/roles", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          roleName: newRoleName,
          createdBy: "admin",
          modelsId: selectedModels, // Include selectedModels in the request body
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to create Role");
      }

      fetchData();
      setIsPopupOpen(false);
      setNewRoleName("");

      toast.success("Role created successfully");
    } catch (error) {
      console.error("Error creating Role:", error);
      toast.error(error.message || "Error creating Role");
    }
  };


  const handleUpdateRole = async (selectedModels) => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("No token found in local storage");
        return;
      }

      const roleId = selectedRole.roleId;

      if (!newRoleName.trim()) {
        toast.error("Role name cannot be empty");
        return;
      }

      const response = await fetch(
        `https://pos.farm2bag.com/api/v1/roles/${roleId}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            roleName: newRoleName,
            modelsId: selectedModels,
            status: 1,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update Role");
      }

      setIsPopupOpen(false);
      setNewRoleName("");
      fetchData();

      toast.success("Role updated successfully");
    } catch (error) {
      console.error("Error updating Role:", error);
      toast.error(error.message || "Error updating Role");
    }
  };


  const handleDeleteRole = async () => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("No token found in local storage");
        return;
      }

      const roleId = selectedRole.roleId;

      const response = await fetch(
        `https://pos.farm2bag.com/api/v1/roles/${roleId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete Role");
      }

      setIsPopupOpen(false);
      fetchData();
      toast.success("Role deleted successfully");
    } catch (error) {
      console.error("Error deleting Role:", error);
      toast.error(error.message || "Error deleting Role");
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleStatusChange = async (role) => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("No token found in local storage");
        return;
      }

      const roleId = role.roleId;
      const newStatus = role.status === 0 ? 1 : 0;

      const response = await fetch(
        `https://pos.farm2bag.com/api/v1/roles/${roleId}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            status: newStatus,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update Role status");
      }

      // Update status locally
      setData(
        data.map((item) =>
          item.roleId === roleId ? { ...item, status: newStatus } : item
        )
      );


      fetchData();
      toast.success("Role status updated successfully");
    } catch (error) {
      console.error("Error updating Role status:", error);
      toast.error(error.message || "Error updating Role status");
    }
  };

  const filteredData = data.filter((role) =>
    role.RoleName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <>
      <div className='bg-gray-100 ml-64 mt-20 p-12 h-screen'>
        <div className="container mx-auto">
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center">
              <h1 className="font-extrabold text-3xl text-gray-500 pl-5">
                Role
              </h1>
            </div>
          </div>
          <div className="ml-4 border-t border-gray-500 w-full"></div>
          <div className="my-4 flex justify-between items-center">
            <div className="relative w-full max-w-xs">
              <input type="text" value={searchQuery} onChange={handleSearch} placeholder="Search" className="border border-gray-300 px-4 py-2 rounded-lg w-full" />
              <img src={Searchicon} alt="Search Icon" className="absolute top-2 right-3" />
            </div>
            <button
              onClick={() => {
                setPopupType("create");
                setIsPopupOpen(true);
              }}
              className="bg-green-500 text-white px-4 py-2 rounded-lg" >
              Create Role
            </button>
          </div>

          <div className="rounded-lg overflow-hidden">
            <table className="table-auto w-full border-collapse border-gray-500 text-center">
              <thead>
                <tr className="bg-blue-500">
                  <th className="px-4 pl-16 py-2 text-left">Role Name</th>
                  <th className="px-4 py-2 pr-16 text-center">Status</th>
                  <th className="px-4 py-2 text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((role) => (
                  <tr key={role.roleId} className="border-t hover:bg-green-300">
                    <td className="px-4 pl-16 py-2 border-gray-500 text-left">{role.RoleName}</td>
                    <td className="px-4 py-2 border-gray-500">
                      <Switch checked={role.status} onChange={() => handleStatusChange(role)} />
                    </td>
                    <td className="px-4 py-2 border-gray-500">
                      <button onClick={() => handleEdit(role)} className="mr-2 font-bold  rounded" ><FaRegEdit size={22} style={{ color: "blue" }} className="inline-block mr-1" /></button>
                      <button onClick={() => handleDelete(role)} className=" text-white font-bold rounded" ><IoMdTrash size={30} style={{ color: "red" }} className="inline-block mr-1" /></button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex justify-between items-center mt-4">
            <div className="flex items-center">
              <button
                onClick={() =>
                  setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
                }
                className="px-4 py-2 bg-gray-300 rounded-lg"
              >
                Previous
              </button>
              <span className="px-4">
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={() =>
                  setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
                }
                className="px-4 py-2 bg-gray-300 rounded-lg"
              >
                Next
              </button>
            </div>
            <div className="flex items-center">
              <label htmlFor="itemsPerPage" className="mr-2">Items per page:</label>
              <select id="itemsPerPage" value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))} className="border border-gray-300 rounded-lg">
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <RolePopup
        isPopupOpen={isPopupOpen}
        onClose={() => {
          setIsPopupOpen(false);
          setNewRoleName("");
        }}
        onSubmit={
          popupType === "create"
            ? handleCreateRole
            : popupType === "edit"
              ? handleUpdateRole
              : handleDeleteRole
        }
        popupType={popupType}
        roleName={newRoleName}
        setRoleName={setNewRoleName}
        roleNameError={newRoleNameError}
        selectedRole={selectedRole}
        selectedModelId={selectedModelId}
      />

      <ToastContainer />
    </>

  );
};

export default Role;

