import React from 'react';

const StockDelete = ({ isVisible, onClose, onDeleteConfirmed, product, warehouseId }) => {
  if (!isVisible || !product || !warehouseId) return null;

  const handleDeleteConfirmed = async () => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("No token found in local storage");
        return;
      }

      const url = `https://pos.farm2bag.com/api/v1/stocks/${warehouseId}/${product.productId}`;

      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
     
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      onDeleteConfirmed();
      onClose(); // Close the popup after successful deletion
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-xl font-bold mb-4">Delete Product</h2>
        <p>Are you sure you want to delete the product "{product.name}"?</p>
        <div className="flex justify-between mt-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-500 text-white rounded-lg"
          >
            Cancel
          </button>
          <button
            onClick={handleDeleteConfirmed}
            className="px-4 py-2 bg-red-500 text-white rounded-lg"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default StockDelete;
