// import React, { useState, useEffect } from 'react';

// const QualityCheckingHistory = ({ selectedPurchaseOrder, onHistoryDataCalculated, invoiceNumber }) => {
//     const [historyData, setHistoryData] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);

//     useEffect(() => {
//         const fetchQualityCheckHistory = async () => {
//             if (!selectedPurchaseOrder) return; // Don't fetch if no purchase order is selected

//             const token = localStorage.getItem('token');
//             setLoading(true);
//             setError(null); // Reset error before new fetch

//             try {
//                 const response = await fetch(`https://pos.farm2bag.com/api/v1/qualityChecking/purchaseQuality?purchaseCode=${selectedPurchaseOrder}`, {
//                     method: 'GET',
//                     headers: {
//                         'Content-Type': 'application/json',
//                         'Authorization': `Bearer ${token}`
//                     }
//                 });

//                 const result = await response.json();
//                 if (result.status === 200) {
//                     setHistoryData(result.data);
//                     aggregateProductQuantities(result.data);
//                 } else {
//                     setError(result.message || 'Failed to fetch data');
//                 }
//             } catch (error) {
//                 setError('Error fetching data: ' + error.message);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchQualityCheckHistory();
//     }, [selectedPurchaseOrder]);

//     const aggregateProductQuantities = (data) => {
//         const productTotals = {};

//         // Loop through each record's products and sum quantities by productId
//         data.forEach(record => {
//             record.products.forEach(product => {
//                 const { productId, quantity, returnQuantity } = product;

//                 if (!productTotals[productId]) {
//                     productTotals[productId] = { totalQuantity: 0, totalReturnQuantity: 0 };
//                 }

//                 productTotals[productId].totalQuantity += quantity;
//                 productTotals[productId].totalReturnQuantity += returnQuantity;
//             });
//         });

//         // Send aggregated data back to the parent component
//         onHistoryDataCalculated(productTotals);

//         console.log("productTotals", productTotals);
//     };

//     return (
//         <div className="mt-6 bg-white shadow rounded">
//             <h2 className="text-xl font-bold mb-4">Quality Checking History</h2>

//             {loading ? (
//                 <p>Loading...</p>
//             ) : error ? (
//                 <p className="text-red-500">{error}</p>
//             ) : historyData.length > 0 ? (
//                 <table className="min-w-full bg-white border border-gray-300">
//                     <thead>
//                         <tr>
//                             <th className="py-2 px-4 border-b">Invoice No</th>
//                             <th className="py-2 px-4 border-b">Invoice Date</th>
//                             <th className="py-2 px-4 border-b">Product Name</th>
//                             <th className="py-2 px-4 border-b">Quantity</th>
//                             <th className="py-2 px-4 border-b">Return Quantity</th>
//                             <th className="py-2 px-4 border-b">Price</th>
//                             <th className="py-2 px-4 border-b">Status</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {historyData.map((record) => (
//                             record.products.map((product, index) => (
//                                 <tr key={index}>
//                                     <td className="py-2 px-4 border-b">{record.invoiceNo}</td>
//                                     <td className="py-2 px-4 border-b">{new Date(record.invoiceDate).toLocaleDateString()}</td>
//                                     <td className="py-2 px-4 border-b">{product.productName}</td>
//                                     <td className="py-2 px-4 border-b">{product.quantity}</td>
//                                     <td className="py-2 px-4 border-b">{product.returnQuantity}</td>
//                                     <td className="py-2 px-4 border-b">{product.price}</td>
//                                     <td className="py-2 px-4 border-b">{product.status}</td>
//                                 </tr>
//                             ))
//                         ))}
//                     </tbody>
//                 </table>
//             ) : (
//                 <p>No history available for the selected purchase order.</p>
//             )}
//         </div>
//     );
// };

// export default QualityCheckingHistory;




import React, { useState, useEffect } from 'react';

const QualityCheckingHistory = ({ selectedPurchaseOrder, onHistoryDataCalculated, invoiceNumber }) => {
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    useEffect(() => {
        const fetchQualityCheckHistory = async () => {
            if (!selectedPurchaseOrder) return; // Don't fetch if no purchase order is selected

            const token = localStorage.getItem('token');
            setLoading(true);
            setError(null); // Reset error before new fetch

            try {
                const response = await fetch(`https://pos.farm2bag.com/api/v1/qualityChecking/purchaseQuality?purchaseCode=${selectedPurchaseOrder}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                const result = await response.json();
                if (result.status === 200) {
                    // Filter out the records with the matching invoiceNumber if provided
                    let filteredData = result.data;

                    if (invoiceNumber) {
                        filteredData = filteredData.filter(record => record.invoiceNo !== invoiceNumber);
                    }

                    // Set the filtered data in the history state and aggregate the product quantities
                    setHistoryData(filteredData);
                    aggregateProductQuantities(filteredData);
                } else {
                    setError(result.message || 'Failed to fetch data');
                }
            } catch (error) {
                setError('Error fetching data: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchQualityCheckHistory();
    }, [selectedPurchaseOrder, invoiceNumber]); // Added invoiceNumber to the dependency array

    const aggregateProductQuantities = (data) => {
        const productTotals = {};

        // Loop through each record's products and sum quantities by productId
        data.forEach(record => {
            record.products.forEach(product => {
                const { productId, quantity, returnQuantity } = product;

                if (!productTotals[productId]) {
                    productTotals[productId] = { totalQuantity: 0, totalReturnQuantity: 0 };
                }

                productTotals[productId].totalQuantity += quantity;
                productTotals[productId].totalReturnQuantity += returnQuantity;
            });
        });

        // Send aggregated data back to the parent component
        onHistoryDataCalculated(productTotals);

    };

    return (
        <div className="mt-6 bg-white shadow rounded">
            <h2 className="text-xl font-bold mb-4">Quality Checking History</h2>

            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p className="text-red-500">{error}</p>
            ) : historyData.length > 0 ? (
                <table className="min-w-full bg-white border border-gray-300">
                    <thead>
                        <tr>
                            <th className="py-2 px-4 border-b">Invoice No</th>
                            <th className="py-2 px-4 border-b">Invoice Date</th>
                            <th className="py-2 px-4 border-b">Product Name</th>
                            <th className="py-2 px-4 border-b">Quantity</th>
                            <th className="py-2 px-4 border-b">Return Quantity</th>
                            <th className="py-2 px-4 border-b">Price</th>
                            <th className="py-2 px-4 border-b">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {historyData.map((record) => (
                            record.products.map((product, index) => (
                                <tr key={index}>
                                    <td className="py-2 px-4 border-b">{record.invoiceNo}</td>
                                    <td className="py-2 px-4 border-b">{new Date(record.invoiceDate).toLocaleDateString()}</td>
                                    <td className="py-2 px-4 border-b">{product.productName}</td>
                                    <td className="py-2 px-4 border-b">{product.quantity}</td>
                                    <td className="py-2 px-4 border-b">{product.returnQuantity}</td>
                                    <td className="py-2 px-4 border-b">{product.price}</td>
                                    <td className="py-2 px-4 border-b">{product.status}</td>
                                </tr>
                            ))
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No history available for the selected purchase order.</p>
            )}
        </div>
    );
};

export default QualityCheckingHistory;

