import React, { useState, useEffect } from 'react';
import tickImage from '../Assets/tableicon/check-mark.png';


const QualityCheckingView = ({ id, onClose }) => {
    const [qualityCheck, setQualityCheck] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchQualityCheckById = async () => {
        const token = localStorage.getItem('token');
        const headers = new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        });

        try {
            const response = await fetch(`https://pos.farm2bag.com/api/v1/qualityChecking/eyeIcon/${id}`, { headers });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            setQualityCheck(result.data);
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchQualityCheckById();
    }, [id]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-8 rounded shadow-lg w-6/12">
                <h2 className="text-xl font-semibold mb-4">Quality Checking Details</h2>
                {qualityCheck ? (
                    <div>
                        <div className="grid grid-cols-2 gap-4">
                            <div className='text-left bg-gradient-to-r from-orange-400 to-yellow-400 p-4 rounded-lg'>
                                <p className=' font-bold text-teal-800'><strong className='mr-2 text-red-500'>Purchase Order:</strong> {qualityCheck.purchaseOrder}</p>
                                <p className=' font-bold text-teal-800'><strong className='mr-2 text-red-500'>Warehouse :</strong> {qualityCheck.warehouseName}</p>
                            </div>
                            <div className='text-left pl-5 bg-gradient-to-r from-orange-400 to-yellow-400 p-4 rounded-lg'>
                                <p className=' font-bold text-teal-800'><strong className='mr-2 text-red-500'>Invoice Date:</strong> {new Date(qualityCheck.invoiceDate).toLocaleDateString()}</p>
                                <p className=' font-bold text-teal-800'><strong className='mr-2 text-red-500'>Invoice No:</strong> {qualityCheck.invoiceNo}</p>
                            </div>
                        </div>

                        <h3 className="font-bold mt-4 mb-2">Product Details:</h3>
                        <table className="min-w-full bg-white border border-gray-300">
                            <thead className="bg-gray-200">
                                <tr>
                                    <th className="py-2 px-4 border">Product ID</th>
                                    <th className="py-2 px-4 border-b items-center flex justify-center"><img src={tickImage} className='w-5 h-5 text-center mr-2' /> (QTY)</th>
                                    <th className="py-2 px-4 border-b">Price</th>
                                    <th className="py-2 px-4 border-b">❌ (QTY)</th>
                                    <th className="py-2 px-4 border">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {qualityCheck.product.map((product, index) => (
                                    <tr key={index}>
                                        <td className="py-2 px-4 border">{product.productName}</td>
                                        <td className="py-2 px-4 border">{product.quantity}</td>
                                        <td className="py-2 px-4 border">{product.price}</td>
                                        <td className="py-2 px-4 border">{product.returnQuantity}</td>
                                        <td className="py-2 px-4 border">{product.status === "OK" ? "Stock Updated" : "Not Updated Stock"}</td>                                   
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <button
                            onClick={onClose}
                            className="mt-4 bg-red-500 text-white p-2 rounded"
                        >
                            Close
                        </button>
                    </div>
                ) : (
                    <p>No data found</p>
                )}
            </div>
        </div>
    );
};

export default QualityCheckingView;
