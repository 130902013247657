import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import '../scss/SalesProfitOverview.scss';

const SalesProfitOverview = () => {
  const currentYear = new Date().getFullYear();
  const [selectedButton, setSelectedButton] = useState('monthly');
  const [series, setSeries] = useState([{ name: 'monthly', data: [] }]);
  const [xAxisCategories, setXAxisCategories] = useState(Array.from({ length: 31 }, (_, i) => (i + 1).toString()));
  const [showDropdowns, setShowDropdowns] = useState(true);
  const currentMonth = new Date().toLocaleString('default', { month: 'long' });
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [showYearDropdown, setShowYearDropdown] = useState(false);
  const [selectedYear, setSelectedYear] = useState(currentYear.toString());
  const [selectedYearForYearly, setSelectedYearForYearly] = useState(currentYear.toString());
  const [responseMessage, setResponseMessage] = useState('');

  const monthMapping = {
    January: '1',
    February: '2',
    March: '3',
    April: '4',
    May: '5',
    June: '6',
    July: '7',
    August: '8',
    September: '9',
    October: '10',
    November: '11',
    December: '12'
  };

  useEffect(() => {
    if (selectedButton === 'monthly') {
      const fetchData = async () => {
        try {
          const token = localStorage.getItem('token');
          const month = monthMapping[selectedMonth];
          const year = selectedYear;
          const response = await fetch(`https://pos.farm2bag.com/api/v1/registers/sales-by-month?month=${month}&year=${year}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const result = await response.json();

          if (!result.data) {
            setResponseMessage(result.message);
            setSeries([{ name: 'Sales of Day', data: [] }]);
            setXAxisCategories([]);
            return;
          }

          const data = result.data || [];

          const salesData = Array.from({ length: 31 }, (_, i) => {
            const day = (i + 1).toString().padStart(2, '0');
            const dateStr = `${day}/${month.padStart(2, '0')}/${year}`;
            const salesRecord = data.find(item => item.createdAt === dateStr);
            return salesRecord ? salesRecord.totalSalesAmount : 0;
          });

          // Remove trailing zeros from the end of the salesData array
          while (salesData[salesData.length - 1] === 0) {
            salesData.pop();
          }

          setSeries([{ name: 'Sales of Day', data: salesData }]);
          setXAxisCategories(Array.from({ length: salesData.length }, (_, i) => (i + 1).toString()));
          setResponseMessage('');
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [selectedMonth, selectedYear, selectedButton]);

  useEffect(() => {
    if (selectedButton === 'yearly') {
      const fetchYearlyData = async () => {
        try {
          const token = localStorage.getItem('token');
          const year = selectedYearForYearly;
          const response = await fetch(`https://pos.farm2bag.com/api/v1/registers/sales-by-year?year=${year}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const result = await response.json();

          if (!result.data) {
            setResponseMessage(result.message);
            setSeries([{ name: 'Sales of Month', data: [] }]);
            setXAxisCategories([]);
            return;
          }

          let data = result.data || [];

          // Filter out months with totalSalesAmount === 0
          data = data.filter(item => item.totalSalesAmount !== 0);

          // Sort data by month
          data.sort((a, b) => {
            const [aMonth, aYear] = a.createdAt.split('/');
            const [bMonth, bYear] = b.createdAt.split('/');
            if (aYear !== bYear) {
              return parseInt(aYear) - parseInt(bYear);
            }
            return parseInt(aMonth) - parseInt(bMonth);
          });

          const salesData = data.map(item => item.totalSalesAmount);
          const monthNames = data.map(item => item.monthName);

          setSeries([{ name: 'Sales of Month', data: salesData }]);
          setXAxisCategories(monthNames);
          setResponseMessage('');
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchYearlyData();
    }
  }, [selectedYearForYearly, selectedButton]);

  const handleMonthlyClick = () => {
    setSelectedButton('monthly');
    setShowDropdowns(true);
    setShowYearDropdown(false);
  };

  const handleYearlyClick = () => {
    setSelectedButton('yearly');
    setShowDropdowns(false);
    setShowYearDropdown(true);
  };

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const years = Array.from({ length: 5 }, (_, index) => (currentYear - index).toString());

  const options = {
    chart: {
      height: 350,
      type: 'area',
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      colors: ['#33FF57']
    },
    xaxis: {
      title: {
        text: selectedButton === 'monthly' ? `Selected Month: ${selectedMonth}` : `Selected Year: ${selectedYearForYearly}`,
        style: {
          fontSize: '16px',
          fontWeight: 600,
          fontFamily: 'Times New Roman, serif', // Times New Roman font family
          color: '#263238'
        }
      },
      type: 'category',
      categories: xAxisCategories
    },
    yaxis: {
      title: {
        text: 'Sales in Price ₹',
        style: {
          fontSize: '16px',
          fontWeight: 600,
          fontFamily: 'Times New Roman, serif', // Times New Roman font family
          color: '#263238'
        }
      },
      labels: {
        formatter: function (val) {
          return Math.round(val); // Round values to nearest integer
        }
      }
    },
    tooltip: {
      x: {
        formatter: function (val, { seriesIndex, dataPointIndex, w }) {
          if (xAxisCategories.length === 31) {
            return `Day ${val}`;
          } else {
            return xAxisCategories[dataPointIndex];
          }
        }
      }
    }
  };

  return (
    <div className="bg-white pt-3">
      <div id="chart" className="px-3">
        <div className="flex justify-between items-center">
          <h1 className="ml-0 font-semibold text-black text-xl">Sales</h1>
          <ul className="flex items-center mr-10 font-sans">
            {showDropdowns && (
              <>
                <select
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                  className="ml-4 px-3 py-0.5 rounded-lg border-none focus:outline-none bg-orange-100 text-black font-semibold"
                >
                  {months.map((month, index) => (
                    <option key={index} value={month}>{month}</option>
                  ))}
                </select>
                <select
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  className="ml-4 px-3 py-0.5 rounded-lg border-none focus:outline-none bg-orange-100 text-black font-semibold"
                >
                  {years.map((year, index) => (
                    <option key={index} value={year}>{year}</option>
                  ))}
                </select>
              </>
            )}
            <button
              onClick={handleMonthlyClick}
              className={`ml-4 px-3 py-0.5 rounded-lg border-none ${selectedButton === 'monthly' ? 'bg-green-500 text-white' : 'bg-gray-200'}`}
            >
              Monthly
            </button>
            {showYearDropdown && (
              <select
                value={selectedYearForYearly}
                onChange={(e) => setSelectedYearForYearly(e.target.value)}
                className="ml-4 px-3 py-0.5 rounded-lg  border-none focus:outline-none bg-orange-100 text-black font-semibold"
              >
                {years.map((year, index) => (
                  <option key={index} value={year}>{year}</option>
                ))}
              </select>
            )}
            <button
              onClick={handleYearlyClick}
              className={`ml-4 px-3 py-0.5 rounded-lg border-none  ${selectedButton === 'yearly' ? 'bg-green-500 text-white' : 'bg-gray-200'}`}
            >
              Yearly
            </button>
          </ul>
        </div>
        {series[0].data.length > 0 ? (
          <ReactApexChart options={options} series={series} type="area" height={350} />
        ) : (
          <p className="text-center font-bold text-red-500">{responseMessage}</p>
        )}
        {/* <p className="text-center font-semibold text-black ">
          {selectedButton === 'monthly' && `Selected Month: ${selectedMonth}`}
          {selectedButton === 'yearly' && `Selected Year: ${selectedYearForYearly}`}
        </p> */}
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default SalesProfitOverview;

