import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { IoMdTrash } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import Eye from "../Assets/tableicon/Eye.svg";
import filter from "../Assets/tableicon/filter.png";
import Searchicon from "../Assets/tableicon/Searchicon.png";
import Switch from "react-switch";
import PurchaseCreate from '../components/PurchaseCreate';
import PurchaseEdit from '../components/PurchaseEdit';
import PurchaseDelete from '../components/PurchaseDelete';
import PurchaseReturn from '../components/PurchaseReturn';

const Purchase = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [isCreatePopupOpen, setCreatePopupOpen] = useState(false);
    const [isEditPopupOpen, setEditPopupOpen] = useState(false);
    const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
    const [selectedPurchase, setSelectedPurchase] = useState(null);
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortBy, setSortBy] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [warehouses, setWarehouses] = useState([]);
    const [selectedWarehouseId, setSelectedWarehouseId] = useState(null);
    const [isReturnPopupOpen, setReturnPopupOpen] = useState(false);
    const [returnPurchaseId, setReturnPurchaseId] = useState(null);
    const [returnWarehouseId, setReturnWarehouseId] = useState(null);

    useEffect(() => {
        fetchData();
    }, [currentPage, itemsPerPage]);

    const fetchData = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found in local storage');
                return;
            }
            const response = await fetch('https://pos.farm2bag.com/api/v1/purchaseDetails', {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            const responseData = await response.json();
            const purchaseData = responseData.data.map(purchase => ({
                id: purchase.id,
                vendorName: purchase.vendorName,
                purchaseOrder: purchase.purchaseOrder,
                deliveryDate: purchase.deliverydate,
                status: purchase.status,
                warehouseId: purchase.warehouseId,
            }));
            setData(purchaseData);
            const totalPages = Math.ceil(purchaseData.length / itemsPerPage);
            if (currentPage > totalPages) setCurrentPage(totalPages);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        const fetchWarehouses = async () => {
            const token = localStorage.getItem('token');

            try {
                const response = await fetch('https://pos.farm2bag.com/api/v1/warehouse', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`  // Add the token to the Authorization header
                    }
                });

                const result = await response.json();
                if (response.ok) {
                    setWarehouses(result.data); // Set warehouse data
                } else {
                    console.error('Error fetching warehouse data:', result.message);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchWarehouses();
    }, []);


    const handleFilterClick = (key) => {
        if (sortBy === key) {
            const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
            setData([...data].sort((a, b) => {
                if (newSortDirection === 'asc') {
                    if (a[key] < b[key]) return -1;
                    if (a[key] > b[key]) return 1;
                } else {
                    if (a[key] < b[key]) return 1;
                    if (a[key] > b[key]) return -1;
                }
                return 0;
            }));
            setSortDirection(newSortDirection);
        } else {
            setData([...data].sort((a, b) => {
                if (a[key] < b[key]) return -1;
                if (a[key] > b[key]) return 1;
                return 0;
            }));
            setSortBy(key);
            setSortDirection('asc');
        }
    };

    // Filtering data based on selectedWarehouseId
    const filteredData = selectedWarehouseId
        ? data.filter(item => item.warehouseId === selectedWarehouseId)
        : data;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData
        .filter(item => !searchQuery || item.vendorName.toLowerCase().includes(searchQuery.toLowerCase()))
        .slice(indexOfFirstItem, indexOfLastItem);

    const handleEdit = (purchase) => {
        setSelectedPurchase(purchase);
        setEditPopupOpen(true);
    };

    const handleDelete = (purchase) => {
        setSelectedPurchase(purchase);
        setDeletePopupOpen(true);
    };

    const handleReturn = (purchaseId, warehouseId) => {
        setReturnPurchaseId(purchaseId);
        setReturnPopupOpen(true); 
        setReturnWarehouseId(warehouseId);

        console.log("returnWarehouseId", returnWarehouseId);

        console.log("returnPurchaseId", returnPurchaseId);
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleItemsPerPageChange = (e) => {
        setCurrentPage(1);
        setItemsPerPage(parseInt(e.target.value));
    };

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase().trim();
        setSearchQuery(query);
        const filteredData = data.filter(item => item.vendorName.toLowerCase().includes(query));
        const sortedData = filteredData.sort((a, b) => {
            const startsWithQueryA = a.vendorName.toLowerCase().startsWith(query);
            const startsWithQueryB = b.vendorName.toLowerCase().startsWith(query);
            if (startsWithQueryA && !startsWithQueryB) return -1;
            if (!startsWithQueryA && startsWithQueryB) return 1;
            return 0;
        });
        setData(sortedData);
    };

    useEffect(() => {
        if (searchQuery === '') {
            fetchData();
        }
    }, [searchQuery]);

    const toggleStatus = async (purchase) => {
        try {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');
            if (!token) {
                console.error('No token found in local storage');
                return;
            }
            const id = purchase.id;
            const newStatus = purchase.status === 'Active' ? 'Complete' : 'Active';
            const response = await fetch(`https://pos.farm2bag.com/api/v1/purchaseDetails/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    status: newStatus,
                    modifiedBy: role
                })
            });
            if (!response.ok) throw new Error('Failed to toggle status');
            setData(data.map(item => item.id === id ? { ...item, status: newStatus } : item));
        } catch (error) {
            console.error('Error toggling status:', error);
        }
    };

    const handleView = async (id) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found in local storage');
                return;
            }
            const response = await fetch(`https://pos.farm2bag.com/api/v1/purchaseDetails/pdf/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch PDF');
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            window.open(url);
        } catch (error) {
            console.error('Error fetching PDF:', error);
        }
    };

    return (
        <div className='bg-gray-100 ml-64 mt-20 p-12 h-screen'>
            <div className="container mx-auto">
                <div className="mb-10">
                    <h1 className="font-extrabold text-3xl text-left text-gray-500">Purchase</h1>
                    <div className="mt-2 border-t border-gray-500"></div>
                </div>

                <div className="flex items-center mb-5">
                    <div className="ml-0">
                        <form>
                            <div className="relative">
                                <input type="text" placeholder='search purchase' value={searchQuery} onChange={handleSearch} className="border border-gray-200 px-4 py-2 pl-10 rounded-lg focus:outline-none focus:border-blue-500" />
                                <img src={Searchicon} alt="search" className="absolute left-3 top-3 w-4 h-4" />
                            </div>
                        </form>
                    </div>

                    <div className="ml-4">
                        <select
                            value={selectedWarehouseId}
                            onChange={(e) => setSelectedWarehouseId(e.target.value)}
                            className="border border-gray-200 px-4 py-2 rounded-lg focus:outline-none focus:border-blue-500"
                        >
                            <option value="">All Warehouses</option>
                            {warehouses.map(warehouse => (
                                <option key={warehouse.id} value={warehouse.id}>
                                    {warehouse.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="flex ml-auto mr-0 items-center space-x-4">
                        <img src={filter} alt="filter" className="w-12 h-10 rounded-lg cursor-pointer" onClick={() => handleFilterClick('vendorName')} />
                        <button onClick={() => setCreatePopupOpen(true)} style={{ backgroundColor: 'rgba(66, 255, 97, 1)' }} className="w-48 h-10 font-medium text-sm rounded-lg">Create Purchase</button>
                    </div>
                </div>

                <div className="rounded-lg overflow-hidden">
                    <table className="table-auto w-full border-collapse border-gray-500 text-center">
                        <thead>
                            <tr className="bg-blue-500">
                                <th className="px-4 py-2 text-center text-white text-nowrap">S.No</th>
                                <th className="px-4 py-2 text-center text-white text-nowrap">Vendor Name</th>
                                <th className="px-4 py-2 text-center text-white text-nowrap">Purchase Order</th>
                                <th className="px-4 py-2 text-center text-white text-nowrap">Delivery Date</th>
                                <th className="px-4 py-2 text-center text-white text-nowrap">Status</th>
                                <th className="px-4 py-2 text-center text-white text-nowrap">Toggle Status</th>
                                <th className="px-4 py-2 text-center text-white text-nowrap">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((item, index) => (
                                <tr key={item.id} className={(index % 2 === 0) ? 'bg-white' : 'bg-gray-200'}>
                                    <td className="py-2 px-4 border-b text-center">{indexOfFirstItem + index + 1}</td>
                                    <td className="px-4 py-2 border-gray-500 text-center">{item.vendorName}</td>
                                    <td className="px-4 py-2 border-gray-500 text-center hidden">{item.warehouseId}</td>
                                    <td className="px-4 py-2 border-gray-500 text-center">{item.purchaseOrder}</td>
                                    <td className="px-4 py-2 border-gray-500 text-center">{item.deliveryDate}</td>
                                    <td className="px-4 py-2 border-gray-500 text-center">{item.status}</td>
                                    <td className="px-4 py-2 border-gray-500 text-center">
                                        <Switch onChange={() => toggleStatus(item)} checked={item.status === 'Complete'} />
                                    </td>
                                    <td className="px-4 py-2 border-gray-500 text-left flex justify-center">
                                    <button onClick={() => handleReturn(item.id, item.warehouseId)} className="border-none cursor-pointer text-lg">↩️</button>                                        <button className="flex items-center p-2 text-white rounded focus:outline-none" onClick={() => handleView(item.id)}>
                                            <img src={Eye} className="w-6 h-6 mr-2" alt="Eye Icon" />
                                        </button>
                                        <button onClick={() => handleEdit(item)} className="border-none cursor-pointer">
                                            <FaRegEdit size={24} color="blue" />
                                        </button>
                                        <button onClick={() => handleDelete(item)} className="ml-4 border-none cursor-pointer">
                                            <IoMdTrash size={26} color="red" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="flex justify-between items-center mt-4">
                    <div>
                        <label htmlFor="itemsPerPage" className="mr-2">Items per page:</label>
                        <select id="itemsPerPage" value={itemsPerPage} onChange={handleItemsPerPageChange} className="border border-gray-300 rounded-md px-2 py-1">
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                        </select>
                    </div>
                    <div>
                        <nav className="flex items-center">
                            <ul className="flex space-x-2">
                                {Array.from({ length: Math.ceil(data.length / itemsPerPage) }, (_, index) => (
                                    <li key={index}>
                                        <button
                                            onClick={() => paginate(index + 1)}
                                            className={`px-3 py-1 rounded-md ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-white border border-gray-300 text-gray-700'}`}
                                        >
                                            {index + 1}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <PurchaseCreate isOpen={isCreatePopupOpen} onClose={() => setCreatePopupOpen(false)} fetchData={fetchData} />
            {selectedPurchase && <PurchaseEdit isOpen={isEditPopupOpen} onClose={() => setEditPopupOpen(false)} purchase={selectedPurchase} fetchData={fetchData} />}
            {selectedPurchase && <PurchaseDelete isOpen={isDeletePopupOpen} onClose={() => setDeletePopupOpen(false)} purchase={selectedPurchase} fetchData={fetchData} />}
            <PurchaseReturn isOpen={isReturnPopupOpen} onClose={() => setReturnPopupOpen(false)} purchaseId={returnPurchaseId} warehouseId={returnWarehouseId}/>

            <ToastContainer />
        </div>
    );
};

export default Purchase;