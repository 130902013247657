import React from 'react';

const ConfirmationPopup = ({ isOpen, onConfirm, onCancel, message, productName }) => {
    if (!isOpen) return null;

    const displayMessage = message === "removed" ? "return" : message;

    return (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-4 rounded shadow-lg">
                <p className="mb-4">Do you want <span className='text-lg text-green-600 bg-yellow-200 px-2 rounded-sm'>{productName}</span> move to <span className='text-lg text-red-600 bg-yellow-200 px-2 rounded-sm'>{displayMessage}</span> ?</p>
                <div className="flex justify-between space-x-4">
                    <button className="px-4 py-2 bg-gray-300 rounded" onClick={onCancel}>Cancel</button>
                    <button className="px-4 py-2 bg-red-500 text-white rounded" onClick={onConfirm}>Confirm</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationPopup;
