import React from "react";
import { useNavigate } from "react-router-dom"; // Import React Router's useNavigate hook
import "../scss/NetworkSlowPopup.scss";

const NetworkSlowPopup = ({ isOpen, onClose }) => {
  const navigate = useNavigate(); // Initialize useNavigate hook

  if (!isOpen) return null;

  // Function to handle navigation to the sales page
  const handleSalesPageRedirect = () => {
    navigate('/sales');  // Change '/sales' to the actual path of your sales page
  };

  return (
    <div className="network-slow-popup-overlay">
      <div className="network-slow-popup">
        <h3>Network Timeout</h3>
        <div className="satellite-tower-animation">
          <div className="satellite" />
          <div className="signal" />
          <div className="tower" />
        </div>
        <p>Due to a network timeout, the invoice could not be opened. Kindly visit the sales page to print the invoice.</p>
        <div>
          <button onClick={onClose}>Close</button>
          <button className="sales-button" onClick={handleSalesPageRedirect}>Go to Sales</button>
        </div>
      </div>
    </div>
  );
};

export default NetworkSlowPopup;
