import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { IoMdTrash } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Searchicon from "../Assets/tableicon/Searchicon.png";
import 'react-toastify/dist/ReactToastify.css';

const Warehouses = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [isCreatePopupOpen, setCreatePopupOpen] = useState(false);
    const [isEditPopupOpen, setEditPopupOpen] = useState(false);
    const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
    const [newname, setNewname] = useState('');
    const [newphone, setNewphone] = useState('');
    const [newCountry, setNewCountry] = useState('');
    const [newCity, setNewCity] = useState('');
    const [newZipCode, setNewZipCode] = useState('');
    const [updatename, setUpdatename] = useState('');
    const [updatephone, setUpdatephone] = useState('');
    const [updateCountry, setUpdateCountry] = useState('');
    const [updateCity, setUpdateCity] = useState('');
    const [updateZipCode, setUpdateZipCode] = useState('');
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [phoneError, setPhoneError] = useState('');

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found in local storage');
                return;
            }
            const response = await fetch('https://pos.farm2bag.com/api/v1/warehouse', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const responseData = await response.json();
            setData(responseData.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data
        .filter(item => item.name.toLowerCase().includes(searchQuery.toLowerCase()))
        .slice(indexOfFirstItem, indexOfLastItem);

    const handleEdit = (warehouse) => {
        setSelectedWarehouse(warehouse);
        setUpdatename(warehouse.name);
        setUpdatephone(warehouse.phone);
        setUpdateCountry(warehouse.country);
        setUpdateCity(warehouse.city);
        setUpdateZipCode(warehouse.zipCode);
        setEditPopupOpen(true);
    };

    const handleDelete = (warehouse) => {
        setSelectedWarehouse(warehouse);
        setDeletePopupOpen(true);
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleItemsPerPageChange = (e) => {
        setCurrentPage(1);
        setItemsPerPage(parseInt(e.target.value));
    };

    const handleInputChange = (value, type) => {
        let error = '';
        switch (type) {            
            case 'phone':
                if (!value.trim()) {
                    error = 'Please enter a phone number.';
                }
                setNewphone(value);
                setPhoneError(error);
                break;
            default:
                break;
        }
    };

    const handleCreateWarehouse = async () => {
        try {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');
            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            // Check if any of the input values are empty
            if (!newname || !newphone || !newCountry || !newCity || !newZipCode) {
                toast.error('Please fill in all fields');
                return;
            }

            const response = await fetch('https://pos.farm2bag.com/api/v1/warehouse', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: newname, phone: newphone, country: newCountry, city: newCity, zipCode: newZipCode, createdBy: role
                })
            });
            if (!response.ok) {
                throw new Error('Failed to create Warehouse');
            }
            console.log('Warehouse created successfully');
            setCreatePopupOpen(false); setNewname(''); setNewphone(''); setNewCountry(''); setNewCity(''); setNewZipCode('');
            fetchData();
            toast.success('Warehouse created successfully');
        } catch (error) {
            console.error('Error creating Warehouse:', error);
        }
    };

    const handleUpdateWarehouse = async () => {
        try {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');
            if (!token) {
                console.error('No token found in local storage');
                return;
            }
            const id = selectedWarehouse.id;
            if (!updatename.trim() || !updatephone.trim() || !updateCountry.trim() || !updateCity.trim() || !updateZipCode.trim()) {
                toast.error('All fields are required');
                return;
            }
            const response = await fetch(`https://pos.farm2bag.com/api/v1/warehouse/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: updatename, phone: updatephone, country: updateCountry, city: updateCity, zipCode: updateZipCode, modifiedBy: role
                })
            });
            if (!response.ok) {
                throw new Error('Failed to update Warehouse');
            }
            console.log('Warehouse updated successfully');
            setEditPopupOpen(false);
            setUpdatename('');
            setUpdatephone('');
            setUpdateCountry('');
            setUpdateCity('');
            setUpdateZipCode('');
            fetchData();
            toast.success('Warehouse updated successfully');
        } catch (error) {
            console.error('Error updating Warehouse:', error);
        }
    };

    const handleDeleteWarehouse = async () => {
        try {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');
            if (!token) {
                console.error('No token found in local storage');
                return;
            }
            const id = selectedWarehouse.id;
            const response = await fetch(`https://pos.farm2bag.com/api/v1/warehouse/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ modifiedBy: role })
            });
            if (!response.ok) {
                throw new Error('Failed to delete Warehouse');
            }
            console.log('Warehouse deleted successfully');
            setDeletePopupOpen(false);
            fetchData();
            toast.success('Warehouse deleted successfully');
        } catch (error) {
            console.error('Error deleting Warehouse:', error);
        }
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    return (
        <div className='bg-gray-100 ml-64 mt-20 p-12 h-screen'>
            <div className="container mx-auto">
                <div className="mb-10">
                    <h1 className="font-extrabold text-3xl text-left text-gray-500">Warehouse</h1>
                    <div className="mt-2 border-t border-gray-500"></div>
                </div>
                <div className="flex items-center justify-between mb-5">
                    <div className="relative">
                        <input type="text" placeholder='search' value={searchQuery} onChange={handleSearch} className="border border-gray-200 px-4 py-2 pl-10 rounded-lg focus:outline-none focus:border-blue-500" />
                        <img src={Searchicon} alt="search" className="absolute left-3 top-3 w-4 h-4" />
                    </div>
                    <button onClick={() => setCreatePopupOpen(true)} style={{ backgroundColor: 'rgba(66, 255, 97, 1)' }} className="w-48 h-10 font-medium text-sm rounded-lg"> Create Warehouse </button>
                </div>

                <div className="rounded-lg overflow-hidden">
                    <table className="min-w-full bg-white border-gray-500 text-center">
                        <thead>
                            <tr className="bg-gray-200">
                                <th className="border-b border-gray-200 py-2">Name</th>
                                <th className="border-b border-gray-200 py-2">Phone</th>
                                <th className="border-b border-gray-200 py-2">Country</th>
                                <th className="border-b border-gray-200 py-2">City</th>
                                <th className="border-b border-gray-200 py-2">Zip Code</th>
                                <th className="border-b border-gray-200 py-2">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map(warehouse => (
                                <tr key={warehouse.id}>
                                    <td className="border-b border-gray-200 py-2">{warehouse.name}</td>
                                    <td className="border-b border-gray-200 py-2">{warehouse.phone}</td>
                                    <td className="border-b border-gray-200 py-2">{warehouse.country}</td>
                                    <td className="border-b border-gray-200 py-2">{warehouse.city}</td>
                                    <td className="border-b border-gray-200 py-2">{warehouse.zipCode}</td>
                                    <td className="border-b border-gray-200 py-2">
                                        <button onClick={() => handleEdit(warehouse)} className="mr-2">
                                            <FaRegEdit className="text-blue-500 hover:text-blue-500" size={30} />
                                        </button>
                                        <button onClick={() => handleDelete(warehouse)}>
                                            <IoMdTrash className="text-red-500 hover:text-red-500" size={30}/>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="flex justify-between mt-5">
                    <div>
                        <label>
                            Items per page:
                            <select value={itemsPerPage} onChange={handleItemsPerPageChange} className="ml-2 border border-gray-200 rounded-lg px-2 py-1 focus:outline-none focus:border-blue-500">
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                        </label>
                    </div>
                    <div>
                        <nav>
                            <ul className="flex">
                                {Array.from({ length: Math.ceil(data.length / itemsPerPage) }).map((_, index) => (
                                    <li key={index} className={`mx-1 ${currentPage === index + 1 ? 'font-bold' : ''}`}>
                                        <button onClick={() => paginate(index + 1)} className="px-2 py-1 border border-gray-200 rounded-lg focus:outline-none focus:border-blue-500">
                                            {index + 1}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>

            {isCreatePopupOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg p-8">
                        <h2 className="text-2xl font-bold mb-4">Create Warehouse</h2>
                        <div>
                            <label className="block mb-2">Name</label>
                            <input type="text" value={newname} onChange={(e) => setNewname(e.target.value)} className="border border-gray-200 rounded-lg px-4 py-2 w-full mb-4" />
                        </div>
                        <div>
                            <label className="block mb-2">Phone</label>
                            <PhoneInput country={'in'} value={newphone} onChange={(value) => handleInputChange(value, 'phone')} className="w-full mb-4" />
                            {phoneError && <p className="text-red-500 text-sm">{phoneError}</p>}
                        </div>
                        <div>
                            <label className="block mb-2">Country</label>
                            <input type="text" value={newCountry} onChange={(e) => setNewCountry(e.target.value)} className="border border-gray-200 rounded-lg px-4 py-2 w-full mb-4" />
                        </div>
                        <div>
                            <label className="block mb-2">City</label>
                            <input type="text" value={newCity} onChange={(e) => setNewCity(e.target.value)} className="border border-gray-200 rounded-lg px-4 py-2 w-full mb-4" />
                        </div>
                        <div>
                            <label className="block mb-2">Zip Code</label>
                            <input type="number" value={newZipCode} onChange={(e) => setNewZipCode(e.target.value)} className="border border-gray-200 rounded-lg px-4 py-2 w-full mb-4" />
                        </div>
                        <div className="flex justify-end">
                            <button onClick={() => setCreatePopupOpen(false)} className="mr-2 px-4 py-2 border border-gray-200 rounded-lg">Cancel</button>
                            <button onClick={handleCreateWarehouse} className="px-4 py-2 bg-blue-500 text-white rounded-lg">Create</button>
                        </div>
                    </div>
                </div>
            )}

            {isEditPopupOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg p-8">
                        <h2 className="text-2xl font-bold mb-4">Edit Warehouse</h2>
                        <div>
                            <label className="block mb-2">Name</label>
                            <input type="text" value={updatename} onChange={(e) => setUpdatename(e.target.value)} className="border border-gray-200 rounded-lg px-4 py-2 w-full mb-4" />
                        </div>
                        <div>
                            <label className="block mb-2">Phone</label>
                            <PhoneInput country={'in'} value={updatephone} onChange={(value) => setUpdatephone(value)} className="w-full mb-4" />
                        </div>
                        <div>
                            <label className="block mb-2">Country</label>
                            <input type="text" value={updateCountry} onChange={(e) => setUpdateCountry(e.target.value)} className="border border-gray-200 rounded-lg px-4 py-2 w-full mb-4" />
                        </div>
                        <div>
                            <label className="block mb-2">City</label>
                            <input type="text" value={updateCity} onChange={(e) => setUpdateCity(e.target.value)} className="border border-gray-200 rounded-lg px-4 py-2 w-full mb-4" />
                        </div>
                        <div>
                            <label className="block mb-2">Zip Code</label>
                            <input type="number" value={updateZipCode} onChange={(e) => setUpdateZipCode(e.target.value)} className="border border-gray-200 rounded-lg px-4 py-2 w-full mb-4" />
                        </div>
                        <div className="flex justify-end">
                            <button onClick={() => setEditPopupOpen(false)} className="mr-2 px-4 py-2 border border-gray-200 rounded-lg">Cancel</button>
                            <button onClick={handleUpdateWarehouse} className="px-4 py-2 bg-blue-500 text-white rounded-lg">Update</button>
                        </div>
                    </div>
                </div>
            )}

            {isDeletePopupOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg p-8">
                        <h2 className="text-2xl font-bold mb-4">Delete Warehouse</h2>
                        <p className="mb-4">Are you sure you want to delete this warehouse?</p>
                        <div className="flex justify-end">
                            <button onClick={() => setDeletePopupOpen(false)} className="mr-2 px-4 py-2 border border-gray-200 rounded-lg">Cancel</button>
                            <button onClick={handleDeleteWarehouse} className="px-4 py-2 bg-red-500 text-white rounded-lg">Delete</button>
                        </div>
                    </div>
                </div>
            )}

            <ToastContainer />
        </div>
    );
};

export default Warehouses;



