import React, { useState } from "react";
import * as XLSX from "xlsx";

 const ImportProductUpdate = () => {
    const [fileName, setFileName] = useState("")

    const handleFileImport = async (event) => {
        try {
          const file = event.target.files[0];
          if (!file) {
            throw new Error("No file selected");
          }
    
          setFileName(file.name); // Show selected file name
    
          const data = await readFileAsync(file);
          const workbook = XLSX.read(data, { type: "binary" });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(firstSheet);
    
          // Loop through the data and log the required fields
         // Transform the Excel data into the required format for bulk update
          const productList = jsonData.map((row) => {
      return {
        id: row["ID"], // Assuming 'ID' is the column header in Excel
        name: row["Name"], // Assuming 'Name' is the column header in Excel
        categoryName: row["Category Name"], // Assuming 'Category Name' is the column header in Excel
        brandName: row["Brand Name"], // Optional field if needed
      };
    
    
            // console.log(`Product ID: ${id}`);
            // console.log(`Product Name: ${name}`);
            // console.log(`Category Name: ${categoryName}`);
            // console.log(`Brand Name: ${brandName}`);
          });

       // Log or send the productList to the API for bulk update
    console.log("Formatted Product List:", productList);
    const token = localStorage.getItem("token");
    // Call your API here to send the productList for the bulk update
    // Example:
    const response = await fetch("https://pos.farm2bag.com/api/v1/products/bulk-update", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(productList),
    });

      // Check if the response is ok
      if (!response.ok) {
        const errorText = await response.text(); // Get error response as text
        throw new Error(`Failed to update products: ${errorText}`);
      }

    const result = await response.json();
    console.log("Products updated successfully:", result);
  } catch (error) {
    console.error("Error handling file import:", error);
  }
      };
    
      const readFileAsync = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => resolve(e.target.result);
          reader.onerror = (e) => reject(e);
          reader.readAsBinaryString(file);
        });
      };
  return (
    <div className="file-import-container">
  <label htmlFor="file-upload" className="custom-file-upload w-28 h-10 font-medium text-sm rounded-lg text-center flex items-center justify-center cursor-pointer"
   style={{ backgroundColor: "rgba(66, 255, 97, 1)" }}
  
  >

Update Product
  </label>
  <input
    
    id="file-upload"
    type="file"
    accept=".xlsx, .xls"
    onChange={handleFileImport}
    className="file-input hidden "
  />
</div>

  )
}
export default ImportProductUpdate;