import React from 'react';
import { toast, ToastContainer } from 'react-toastify';

const AdjustmentDelete = ({ isOpen, onClose, fetchData, selecteddata, warehouseId }) => {
    const handleDelete = async () => {
        try {
            const token = localStorage.getItem('token');

            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const response = await fetch(`https://pos.farm2bag.com/api/v1/adjustments/${selecteddata.id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                throw new Error('Failed to delete adjustment');
            }

            fetchData(warehouseId);

            toast.success('Adjustment deleted successfully', { autoClose: 1000 });

            setTimeout(() => {
                onClose();
            }, 2000);

        } catch (error) {
            console.error('Error deleting adjustment:', error);
            toast.error('Error deleting adjustment. Please try again.');
        }
    };

    return isOpen ? (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-70 z-50">
            <div className="relative rounded-lg p-6 w-11/12 md:w-2/3 lg:w-1/2 bg-white">
                <h2 className="text-xl font-bold mb-4 py-2">Delete Adjustment</h2>
                <hr />
                <p className="mb-4 leading-10">
                    Are you sure you want to delete the adjustment dated <br />
                    <span className="text-red-500 font-semibold bg-yellow-300 px-1">{selecteddata.date}</span> for <span className="text-red-500 font-semibold bg-yellow-300 px-1">{selecteddata.warehouse?.name}</span>?
                </p>

                <div className="flex justify-between">
                    <button type="button" className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg" onClick={onClose}>Cancel</button>
                    <button type="button" className="px-4 py-2 bg-red-500 text-white rounded-lg mr-2" onClick={handleDelete}>Delete</button>
                </div>
            </div>
            <ToastContainer />
        </div>
    ) : null;
};

export default AdjustmentDelete;
