import React, { useState } from 'react';
import SalesStatus from '../components/SalesStatus';
import StocksStatus from '../components/StocksStatus';
import arrow from '../Assets/tableicon/arrow.png';

const ShopStatus = () => {
  const [showSalesStatus, setShowSalesStatus] = useState(true);

  const handleTabClick = (status) => {
    setShowSalesStatus(status === 'sales');
  };

  return (
    <div className='ml-64 mt-20 p-10 h-screen relative'>
      <div
        className={`absolute inset-0 transition-all duration-700  ${
          showSalesStatus ? 'bg-gradient-to-r from-green-400 via-blue-500 to-purple-600' : 'bg-gradient-to-r from-pink-400 via-red-500 to-yellow-500'
        }`}
      />

      <div className="relative z-10">
        <div className="flex mb-6 justify-between">
          {showSalesStatus ? (
            <>
              <button
                onClick={() => handleTabClick('sales')}
                className={`px-6 py-3 rounded-l-lg text-lg font-semibold bg-green-600 text-white transition duration-300`}
                style={{ position: 'relative', zIndex: 2 }} 
              >
                Sales Status
              </button>
              <h1 className="text-5xl font-bold">
                <span className="text-[#30fc03]">farm</span>
                <span className="text-[#fa9f02]">2</span>
                <span className="text-[#30fc03]">bag</span>
              </h1>              <button
                onClick={() => handleTabClick('stocks')}
                className={`px-6 py-3 flex items-center rounded-r-lg text-lg font-semibold bg-gray-200 text-gray-800 transition duration-300`}
                style={{ position: 'relative', zIndex: 2 }} 
              >
                Stocks Status<img src={arrow} className='w-6 h-6 ml-2' />
              </button>
            </>
          ) : (
            <>
              <button
                onClick={() => handleTabClick('stocks')}
                className={`px-6 py-3 rounded-l-lg text-lg font-semibold bg-red-600 text-white transition duration-300`}
                style={{ position: 'relative', zIndex: 2 }} 
              >
                Stocks Status
              </button>
              <h1 className="text-5xl font-bold">
                <span className="text-[#30fc03]">farm</span>
                <span className="text-[#fa9f02]">2</span>
                <span className="text-[#30fc03]">bag</span>
              </h1>              <button
                onClick={() => handleTabClick('sales')}
                className={`px-6 py-3 flex items-center rounded-r-lg text-lg font-semibold bg-gray-200 text-gray-800 transition duration-300`}
                style={{ position: 'relative', zIndex: 2 }} 
              >
                Sales Status<img src={arrow} className='w-6 h-6 ml-2' />
              </button>
            </>
          )}
        </div>

        <div className="relative">
          {/* Sales Status Component */}
          <div
            className={`absolute w-full transition-all duration-100 transform ${
              showSalesStatus
                ? 'opacity-100 translate-y-0'
                : 'opacity-0 -translate-y-10 pointer-events-none'
            }`}
          >
            <div className="bg-white shadow-lg rounded-lg">
              <SalesStatus />
            </div>
          </div>

          {/* Stocks Status Component */}
          <div
            className={`absolute w-full transition-all duration-100 transform ${
              !showSalesStatus
                ? 'opacity-100 translate-y-0'
                : 'opacity-0 -translate-y-10 pointer-events-none'
            }`}
          >
            <div className="bg-white shadow-lg rounded-lg">
              <StocksStatus />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopStatus;