import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const CreateCapitalPopup = ({ capitalTypes, onClose, onCreate }) => {
    const [newCapital, setNewCapital] = useState({
        date: '',
        capitalTypeId: '',
        amount: '',
        description: '',
        wareHouseid: '' // Updated to wareHouseid
    });

    const [capitalTypesList, setCapitalTypesList] = useState([]); // State to store capital types
    const [warehouses, setWarehouses] = useState([]); // State to store warehouses

    // Fetch capital types (assuming capitalTypes are passed as props)
    useEffect(() => {
        const fetchCapitalTypes = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.error('No token found in local storage');
                    return;
                }

                const response = await fetch('https://pos.farm2bag.com/api/v1/capitalTypes', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch capital types');
                }

                const data = await response.json();
                setCapitalTypesList(data.data); // Ensure to set only the data array

            } catch (error) {
                console.error('Error fetching capital types:', error);
                toast.error('Error fetching capital types: ' + error.message);
            }
        };

        fetchCapitalTypes();
    }, []);

    // Fetch warehouses
    useEffect(() => {
        const fetchWarehouses = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.error('No token found in local storage');
                    return;
                }

                const response = await fetch('https://pos.farm2bag.com/api/v1/warehouse', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch warehouses');
                }

                const data = await response.json();
                setWarehouses(data.data); // Ensure to set only the data array

            } catch (error) {
                console.error('Error fetching warehouses:', error);
                toast.error('Error fetching warehouses: ' + error.message);
            }
        };

        fetchWarehouses();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewCapital(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCreateCapital = async () => {
        try {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');

            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const { date, capitalTypeId, amount, description, wareHouseid } = newCapital;

            // Validate amount should not be negative
            if (!date || !capitalTypeId || amount < 0 || !description || !wareHouseid) {
                toast.error('Please fill in all fields correctly and ensure amount is not negative');
                return;
            }

            // Log the data before sending
            console.log('Data to be sent:', {
                date,
                capitalTypeId,
                amount,
                description,
                wareHouseid, // Ensure wareHouseid is logged
                createdBy: role
            });

            const response = await fetch('https://pos.farm2bag.com/api/v1/capital', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    date,
                    capitalTypeId,
                    amount,
                    description,
                    wareHouseid, // Include wareHouseid in the payload
                    createdBy: role
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to create capital');
            }

            console.log('Capital created successfully');
            onClose();
            onCreate();
            toast.success('Capital created successfully');

        } catch (error) {
            console.error('Error creating capital:', error);
            toast.error('Error creating capital: ' + error.message);
        }
    };

    return (
        <div className="fixed inset-0 flex items-center bg-gray-500 bg-opacity-50 justify-center z-50">
            <div className="bg-yellow-400 rounded-lg p-6 shadow-lg w-11/12 md:w-2/3 lg:w-1/2">
                <h2 className="text-xl font-bold mb-4">Create Capital</h2>
                <form>
                    <div className="flex flex-wrap -mx-2">

                        <div className="w-full md:w-1/2 px-2 mb-4">
                            <label htmlFor="wareHouseid" className="block text-gray-700 font-medium mb-2">Warehouse</label>
                            <select id="wareHouseid" name="wareHouseid" value={newCapital.wareHouseid} onChange={handleInputChange} className="border border-gray-300 rounded-md p-2 w-full">
                                <option value="" className='hidden'>Select Warehouse</option>
                                {warehouses.map(warehouse => (
                                    <option key={warehouse.id} value={warehouse.id}>
                                        {warehouse.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="w-full md:w-1/2 px-2 mb-4">
                            <label htmlFor="date" className="block text-gray-700 font-medium mb-2">Date</label>
                            <input type="date" id="date" name="date" value={newCapital.date} onChange={handleInputChange} className="border border-gray-300 rounded-md p-2 w-full" />
                        </div>

                        <div className="w-full md:w-1/2 px-2 mb-4">
                            <label htmlFor="capitalTypeId" className="block text-gray-700 font-medium mb-2">Capital Type</label>
                            <select id="capitalTypeId" name="capitalTypeId" value={newCapital.capitalTypeId} onChange={handleInputChange} className="border border-gray-300 rounded-md p-2 w-full">
                                <option value="" className='hidden'>Select Capital Type</option>
                                {capitalTypesList.map(type => (
                                    <option key={type.id} value={type.id}>
                                        {type.capitalType}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="w-full md:w-1/2 px-2 mb-4">
                            <label htmlFor="amount" className="block text-gray-700 font-medium mb-2">Amount</label>
                            <input type="number" id="amount" name="amount" value={newCapital.amount} onChange={handleInputChange} className="border border-gray-300 rounded-md p-2 w-full" />
                        </div>
                        
                        <div className="w-full px-2 mb-4">
                            <label htmlFor="description" className="block text-gray-700 font-medium mb-2">Description</label>
                            <input type="text" id="description" name="description" value={newCapital.description} onChange={handleInputChange} className="border border-gray-300 rounded-md p-2 w-full" />
                        </div>
                    </div>
                    <div className="flex justify-between mt-4">
                        <button type="button" onClick={onClose} className="mr-4 px-4 py-2 bg-red-500 text-white rounded-lg">Cancel</button>
                        <button type="button" onClick={handleCreateCapital} className="px-4 py-2 bg-green-500 text-white rounded-lg">Save</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateCapitalPopup;
