import React, { useState, useEffect } from 'react';
import { IoMdClose } from "react-icons/io";
import tickImage from '../Assets/tableicon/check-mark.png';
import QualityCheckingHistory from './QualityCheckingHistory';

const QualityCheckingEdit = ({ id, onClose, toastMessage }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [items, setItems] = useState([]);
    const [products, setProducts] = useState([]);
    const [purchaseOrders, setPurchaseOrders] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState({});
    const [quantity, setQuantity] = useState({});
    const [returnQuantity, serReturnQuantity] = useState({});
    const [price, setPrice] = useState({});
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState('');
    const [selectedWarehouse, setSelectedWarehouse] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectedDate, setSelectedDate] = useState();
    const [historyTotals, setHistoryTotals] = useState({});

    useEffect(() => {
        const fetchQualityChecking = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`https://pos.farm2bag.com/api/v1/qualityChecking/${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                const result = await response.json();
                if (response.ok) {
                    setData(result.data);

                    // Set form fields based on the fetched data
                    setInvoiceNumber(result.data.invoiceNo);
                    setSelectedPurchaseOrder(result.data.purchaseCode);
                    setSelectedWarehouse(result.data.warehouseId);
                    setSelectedDate(result.data.invoiceDate)
                    // Update selectedProducts and items based on fetched products
                    const fetchedItems = result.data.product.map(p => ({
                        id: p.productId,
                        quantity: p.quantity,
                        price: p.price,
                        returnQuantity: p.returnQuantity,
                        status: p.status
                    }));
                    setItems(fetchedItems);

                    // Set selectedProducts state
                    const selectedProductsMap = {};
                    fetchedItems.forEach(item => {
                        selectedProductsMap[item.id] = item;
                    });
                    setSelectedProducts(selectedProductsMap);

                } else {
                    setError(result.message);
                }
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchQualityChecking();
    }, [id]);

    useEffect(() => {
        const fetchProducts = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch('https://pos.farm2bag.com/api/v1/products/all', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                const result = await response.json();
                if (result.status === 200) {
                    setProducts(result.data);
                    // setFilteredProducts(result.data);
                } else {
                    console.error('Failed to fetch products:', result.message);
                }
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };
        fetchProducts();
    }, []);

    useEffect(() => {
        const fetchPurchaseOrders = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch('https://pos.farm2bag.com/api/v1/purchaseDetails/purchaseOrder', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                const result = await response.json();
                if (result.status === 200) {
                    setPurchaseOrders(result.data);
                } else {
                    console.error('Failed to fetch purchase orders:', result.message);
                }
            } catch (error) {
                console.error('Error fetching purchase orders:', error);
            }
        };
        fetchPurchaseOrders();
    }, []);

    useEffect(() => {
        const fetchWarehouses = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch('https://pos.farm2bag.com/api/v1/warehouse', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                const result = await response.json();
                if (result.status === 200) {
                    setWarehouses(result.data);
                } else {
                    console.error('Failed to fetch warehouses:', result.message);
                }
            } catch (error) {
                console.error('Error fetching warehouses:', error);
            }
        };
        fetchWarehouses();
    }, []);


    useEffect(() => {
        if (selectedPurchaseOrder) {
            // Find the selected purchase order from the fetched purchaseOrders
            const selectedOrder = purchaseOrders.find(order => order.id === selectedPurchaseOrder);

            if (selectedOrder) {
                // Get the productId list and order quantities from the selected purchase order
                const productsWithQuantities = selectedOrder.products.map(p => ({
                    productId: p.productId,
                    orderQuantity: p.orderQuantity
                }));

                // Filter the fetched products to display only those that match the productIds in the selected purchase order
                const matchedProducts = products
                    .filter(product =>
                        productsWithQuantities.some(poProduct => poProduct.productId === product.id)
                    )
                    .map(product => {
                        // Find the corresponding order quantity from the selectedPurchaseOrder
                        const matchedProductInfo = productsWithQuantities.find(poProduct => poProduct.productId === product.id);

                        return {
                            ...product,
                            orderQuantity: matchedProductInfo ? matchedProductInfo.orderQuantity : 0 // Assign the correct orderQuantity
                        };
                    });

                // Update the filtered products to display
                setFilteredProducts(matchedProducts);
            } else {
                setFilteredProducts([]);  // Clear the product list if no purchase order is selected
            }
        } else {
            setFilteredProducts([]); // Clear when no purchase order is selected
        }
    }, [selectedPurchaseOrder, products, purchaseOrders]);

    const handlePurchaseOrderChange = (purchaseOrderId) => {
        setSelectedPurchaseOrder(purchaseOrderId);

        // Find the selected purchase order from the fetched purchaseOrders
        const selectedOrder = purchaseOrders.find(order => order.id === purchaseOrderId);

        if (selectedOrder) {
            // Get the productId list from the selected purchase order
            const productIdsFromOrder = selectedOrder.products.map(p => p.productId);

            // Filter the fetched products to display only those that match the productIds in the selected purchase order
            const matchedProducts = products.filter(product => productIdsFromOrder.includes(product.id));

            // Update the filtered products to display
            setFilteredProducts(matchedProducts);
        } else {
            setFilteredProducts([]);  // Clear the product list if no purchase order is selected
        }
    };

    useEffect(() => {
        // Update filteredProducts based on search query
        setFilteredProducts(prevProducts => prevProducts.filter(product =>
            product.name.toLowerCase().includes(searchQuery.toLowerCase())
        ));
    }, [searchQuery]);



    const handleCheckboxChange = (product) => {
        setSelectedProducts(prev => {
            const updated = { ...prev };
            if (updated[product.id]) {
                delete updated[product.id];
                setItems(prevItems => prevItems.filter(item => item.id !== product.id));
            } else {
                updated[product.id] = product;
                setItems(prevItems => [
                    ...prevItems,
                    {
                        ...product,
                        quantity: quantity[product.id] || '',
                        price: price[product.id] || '',
                        returnQuantity: returnQuantity[product.id] || '',
                        status: 'OK' // Default status
                    }
                ]);
            }
            return updated;
        });
    };

    const handleRemoveItem = (index) => {
        const productId = items[index].id;  // Get the ID of the product being removed

        // Remove the item from the items array
        const updatedItems = items.filter((_, i) => i !== index);
        setItems(updatedItems);

        // Unselect the corresponding checkbox by removing the product from selectedProducts
        setSelectedProducts(prev => {
            const updatedSelectedProducts = { ...prev };
            delete updatedSelectedProducts[productId];  // Remove the product from selectedProducts
            return updatedSelectedProducts;
        });
    };

    const handleQuantityChange = (index, field, value, orderQuantity) => {
        // Ensure quantity doesn't exceed orderQuantity
        const quantity = parseInt(value, 10);

        if (quantity > orderQuantity) {
            alert(`Quantity cannot exceed the order quantity of ${orderQuantity}`);
            return;
        }

        // Proceed with updating the state
        handleInputChange(index, field, value);
    };


    const handleInputChange = (index, field, value) => {
        setItems((prevItems) => {
            return prevItems.map((item, idx) => {
                if (index === idx) {
                    let newValue = value;

                    // Get the product corresponding to the current item
                    const product = filteredProducts.find(p => p.id === item.id);

                    if (field === 'quantity') {
                        // Ensure that quantity does not exceed the product's orderQuantity
                        if (product && value > product.orderQuantity) {
                            newValue = product.orderQuantity; // Set the value to orderQuantity if it exceeds
                            alert(`Quantity cannot exceed Order Quantity (${product.orderQuantity})`);
                        }
                    }

                    return { ...item, [field]: newValue };
                }
                return item;
            });
        });
    };



    const handleHistoryDataCalculated = (totals) => {
        setHistoryTotals(totals);
    };


    const handleStatusChange = (index, value) => {
        const newItems = [...items];
        newItems[index].status = value;
        setItems(newItems);
    };

    const handleDateChange = (e) => {
        const dateValue = e.target.value;
        setSelectedDate(new Date(dateValue));
    };

    function formatDateTimeLocal(date) {
        const d = new Date(date);
        d.setMinutes(d.getMinutes() - d.getTimezoneOffset()); // Adjust for timezone offset
        return d.toISOString().slice(0, 16); // Get YYYY-MM-DDTHH:MM format
    }



    const handleUpdate = async () => {
        if (!selectedDate) {
            alert("Please select a date.");
            return;
        }
        if (!invoiceNumber) {
            alert("Please provide an invoice number.");
            return;
        }
        if (!selectedPurchaseOrder) {
            alert("Please select a purchase order.");
            return;
        }
        if (!selectedWarehouse) {
            alert("Please select a warehouse.");
            return;
        }

        for (const item of items) {
            if (!item.quantity || item.quantity <= 0) {
                alert("Please provide a valid quantity for all selected products.");
                return;
            }
            if (!item.price || item.price <= 0) {
                alert("Please provide a valid price for all selected products.");
                return;
            }
            if (item.returnQuantity < 0) {
                alert("Please provide a valid return quantity for all selected products. Negative values are not allowed.");
                return;
            }
        }

        const token = localStorage.getItem('token');
        const role = localStorage.getItem('role');

        const payload = {
            warehouseId: selectedWarehouse,
            invoiceNo: invoiceNumber,
            purchaseCode: selectedPurchaseOrder,
            invoiceDate: selectedDate,
            product: items.map(item => ({
                productId: item.id,
                quantity: item.quantity,
                price: item.price,
                returnQuantity: item.returnQuantity,
                status: item.status
            })),
            modifiedBy: role,
        };

        try {
            const response = await fetch(`https://pos.farm2bag.com/api/v1/qualityChecking/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload)
            });
            const result = await response.json();

            console.log("result", result);

            if (response.ok) {
                toastMessage(result.message || 'Quality Checking Updated successfully!');

                setItems([]);
                setProducts([]);
                setPurchaseOrders([]);
                setWarehouses([]);
                setSelectedProducts({});
                setQuantity({});
                serReturnQuantity({});
                setPrice({});
                setInvoiceNumber('');
                setSelectedPurchaseOrder('');
                setSelectedWarehouse('');
                setSearchQuery('');
                setSelectedDate();
                setFilteredProducts([]);
                onClose();
            } else {
                alert(`Failed to update Quality Checking: ${result.message}`);
            }
        } catch (error) {
            alert(`Error: ${error.message}`);
        }
    };


    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-75 z-50">
            <div className="bg-white w-11/12 h-full overflow-y-auto p-6">
                <h2 className="text-2xl font-bold py-2 text-white bg-gradient-to-r from-green-600 to-lime-500 !important">Edit Quality Checking</h2>
                <div className="grid grid-cols-3 gap-4 bg-gradient-to-r from-yellow-400 to-yellow-400">

                <div className="flex items-center mb-4">
                        <label className="block font-bold w-1/3 text-right pr-4">Warehouse</label>
                        <select className="border border-gray-300 rounded w-2/3 py-2 px-3 bg-orange-500" value={selectedWarehouse} disabled onChange={(e) => setSelectedWarehouse(e.target.value)}>
                            <option value="">Select Warehouse</option>
                            {warehouses.map(wh => (
                                <option key={wh.id} value={wh.id}>{wh.name}</option>
                            ))}
                        </select>
                    </div>

                <div className="flex items-center mb-4">
                        <label className="block font-bold w-1/3 text-right pr-4"> Purchase Order</label>
                        <select value={selectedPurchaseOrder} disabled onChange={(e) => handlePurchaseOrderChange(e.target.value)}
                            className="border border-gray-300 bg-orange-500 rounded w-2/3 py-2 px-3">
                            <option value="">Select Purchase Order</option>
                            {purchaseOrders.map(purchase => (
                                <option key={purchase.id} value={purchase.id}>{purchase.purchaseOrder}</option>
                            ))}
                        </select>

                    </div>

                    <div className="flex items-center mb-4">
                        <label className="block font-bold w-1/3 text-right pr-4">Invoice Number</label>
                        <input type="text" value={invoiceNumber} onChange={(e) => setInvoiceNumber(e.target.value)} placeholder="Invoice Number"
                            className="border border-gray-300 rounded w-2/3 py-2 px-3" />
                    </div>

                    <div className="flex items-center mb-4">
                        <label className="block font-bold w-2/3 text-right pr-4 text-nowrap">Invoice Date & Time</label>
                        <input type="datetime-local" value={selectedDate ? formatDateTimeLocal(selectedDate) : ''} onChange={handleDateChange}
                            className="border border-gray-300 rounded w-2/3 py-2 px-3" />
                    </div>

                    <div className="flex items-center mb-4">
                        <label className="block font-bold w-1/3 text-right pr-4">Search Product</label>
                        <input type="text" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder="Search for product..."
                            className="border border-gray-300 rounded w-2/3 py-2 px-3"
                        />
                    </div>

                    <div className="flex items-center justify-evenly ml-20 mb-4">
                        <button onClick={onClose} className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-700">
                            Close
                        </button>
                        <button onClick={handleUpdate} className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-700">
                            Update
                        </button>
                    </div>
                </div>

                <div className='w-full h-64 overflow-auto bg-white p-4'>
                    <h3 className="font-bold text-lg mb-2">Available Products</h3>
                    <div className="grid grid-cols-4 gap-4">
                        {selectedPurchaseOrder && filteredProducts.length > 0 ? (
                            filteredProducts.map(product => (
                                <div key={product.id} className={`flex items-center p-2 border border-gray-200 rounded ${selectedProducts[product.id] ? 'bg-green-300' : 'bg-white'}`}>
                                    <input type="checkbox" id={product.id} checked={!!selectedProducts[product.id]} onChange={() => handleCheckboxChange(product)} className="mr-2" />
                                    <label htmlFor={product.id} className="text-sm font-semibold">
                                        {product.name}
                                        <span className="text-gray-500 text-sm ml-2"> (Order Qty: {product.orderQuantity || 0})</span>
                                    </label>
                                </div>
                            ))
                        ) : (
                            <div>No products available. Please select a purchase order.</div>
                        )}
                    </div>
                </div>

                <QualityCheckingHistory selectedPurchaseOrder={selectedPurchaseOrder} onHistoryDataCalculated={handleHistoryDataCalculated} invoiceNumber={invoiceNumber} />


                <div className="mb-6">
                    <h4 className="font-bold text-lg mb-2">Product Table</h4>
                    <table className="min-w-full bg-white border border-gray-300">
                        <thead>
                            <tr>
                                <th className="py-2 px-4 border-b">Name</th>
                                <th className="py-2 px-4 border-b">Order Quantity</th>
                                <th className="py-2 px-4 border-b">Price</th>
                                <th className="py-2 px-4 border-b items-center flex justify-center"><img src={tickImage} className='w-5 h-5 text-center mr-2' /> (QTY)</th>
                                <th className="py-2 px-4 border-b">❌ (QTY)</th>
                                <th className="py-2 px-4 border-b">Status</th>
                                <th className="py-2 px-4 border-b">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item, index) => {
                                // Find the product by item id to get its specific order quantity
                                const product = filteredProducts.find(p => p.id === item.id);

                                // Ensure each product's orderQuantity is fetched correctly
                                let orderQuantity = product?.orderQuantity || 0; // Default to 0 if not found

                                // Fetch the historyTotal for this item if it exists
                                const historyTotal = historyTotals[item.id];

                                // Initialize total quantities to 0 if historyTotal is not available or if totals are empty/null
                                let totalQuantity = 0;
                                let totalReturnQuantity = 0;

                                if (historyTotal) {
                                    totalQuantity = historyTotal.totalQuantity || 0; // Set to 0 if null/empty
                                    totalReturnQuantity = historyTotal.totalReturnQuantity || 0; // Set to 0 if null/empty
                                }

                                // Adjust orderQuantity based on historyTotal, if available
                                orderQuantity = orderQuantity - (totalQuantity + totalReturnQuantity);

                                // Ensure orderQuantity doesn't go below zero
                                if (orderQuantity < 0) {
                                    orderQuantity = 0;
                                }

                                return (
                                    <tr key={index}>
                                        <td className="py-2 px-4 border-b">{product?.name || 'N/A'}</td>
                                        <td className="py-2 px-4 border-b">{orderQuantity}</td> {/* Corrected orderQuantity */}
                                        <td className="py-2 px-4 border-b">
                                            <input
                                                type="number"
                                                value={item.price}
                                                onChange={(e) => handleInputChange(index, 'price', e.target.value)}
                                                className={`border-2 rounded w-full py-2 px-3 ${item.price ? 'border-[#07fa02]' : 'border-red-300'}`}
                                            />
                                        </td>
                                        <td className="py-2 px-4 border-b">
                                            <input
                                                type="number"
                                                value={item.quantity}
                                                onChange={(e) => handleQuantityChange(index, 'quantity', e.target.value, orderQuantity)} // Pass corrected orderQuantity
                                                className={`border-2 rounded w-full py-2 px-3 ${item.quantity ? 'border-[#07fa02]' : 'border-red-300'}`}
                                            />
                                        </td>
                                        <td className="py-2 px-4 border-b">
                                            <input
                                                type="number"
                                                value={item.returnQuantity}
                                                onChange={(e) => handleInputChange(index, 'returnQuantity', e.target.value)}
                                                className={`border-2 rounded w-full py-2 px-3 ${item.returnQuantity ? 'border-[#07fa02]' : 'border-red-300'}`}
                                            />
                                        </td>
                                        <td className="py-2 px-4 border-b">
                                            <select
                                                value={item.status}
                                                onChange={(e) => handleStatusChange(index, e.target.value)}
                                                className={`border-2 rounded-lg w-full py-2 px-3 ${item.status === "OK" ? "border-white bg-green-400" : "border-white bg-red-400"}`}
                                            >
                                                <option value="OK">Update Stock</option>
                                                <option value="Wastage">Add QC (Only)</option>
                                            </select>
                                        </td>
                                        <td className="py-2 px-4 border-b">
                                            <button onClick={() => handleRemoveItem(index)} className="text-red-500">
                                                <IoMdClose size={24} />
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default QualityCheckingEdit;