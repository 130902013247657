  import React, { useState, useEffect } from "react";
  import { FaTrash } from "react-icons/fa";

  const WastageCreate = ({
    formValues,
    setFormValues,
    setShowCreatePopup,
    fetchData,
  }) => {
    const [productNames, setProductNames] = useState([]);
    const [warehouseNames, setWarehouseNames] = useState([]);
    const [unitSymbols, setUnitSymbols] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [productList, setProductList] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [errors, setErrors] = useState({
      warehouseName: "",
      productName: "",
      quantity: "",
      productList: "", // New state for product list validation
    });

    useEffect(() => {
      // Fetch warehouse names on component mount
      const fetchWarehouseNames = async () => {
        try {
          const token = localStorage.getItem("token");
          if (!token) {
            console.error("No token found in local storage");
            return;
          }
          const response = await fetch("https://pos.farm2bag.com/api/v1/warehouse", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const responseData = await response.json();
          setWarehouseNames(responseData.data || []);
        } catch (error) {
          console.error("Error fetching warehouse names:", error);
        }
      };

      fetchWarehouseNames();
    }, []);

    const fetchProductNamesAndUnitSymbols = async (warehouseId) => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found in local storage");
          return;
        }
        const response = await fetch(
          `https://pos.farm2bag.com/api/v1/stocks/warehouse/${warehouseId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const responseData = await response.json();
        if (responseData.data && responseData.data.products) {
          setProductNames(responseData.data.products);
          const symbols = responseData.data.products.map((product) => ({
            id: product.unitSymbol,
            symbol: product.unitSymbol,
            unitId: product.unitId,
          }));
          setUnitSymbols(symbols);
        } else {
          setProductNames([]);
          setUnitSymbols([]);
        }
      } catch (error) {
        console.error("Error fetching product names and unit symbols:", error);
      }
    };

    const handleWarehouseChange = (e) => {
      const selectedWarehouseName = e.target.value;
      const selectedWarehouse = warehouseNames.find(
        (warehouse) => warehouse.name === selectedWarehouseName
      );
      if (selectedWarehouse) {
        fetchProductNamesAndUnitSymbols(selectedWarehouse.id);
        setFormValues({
          ...formValues,
          warehouseName: selectedWarehouseName,
          warehouseId: selectedWarehouse.id,
          productName: "",
          unitSymbol: "",
          unitId: "",
          quantity: "",
        });
        setSelectedProduct(null);
        // Clear warehouseName error
        setErrors({ ...errors, warehouseName: "" });
      }
    };

    const handleProductChange = (e) => {
      const selectedProductName = e.target.value;
      const selectedProduct = productNames.find(
        (product) => product.name === selectedProductName
      );
      if (selectedProduct) {
        setSelectedProduct(selectedProduct);
        setFormValues({
          ...formValues,
          productName: selectedProductName,
          productId: selectedProduct.productId,
          unitSymbol: selectedProduct.unitSymbol,
          unitId: selectedProduct.unitId,
        });
        // Clear productName error
        setErrors({ ...errors, productName: "" });
      } else {
        setFormValues({
          ...formValues,
          productName: "",
          productId: "",
          unitSymbol: "",
          unitId: "",
        });
      }
    };

    const handleQuantityChange = (e) => {
      const quantityValue = e.target.value;
      if (quantityValue >= 0) {
        setFormValues({ ...formValues, quantity: quantityValue });
        setErrors({ ...errors, quantity: "" });
      } else {
        setErrors({ ...errors, quantity: "Negative value should not be entered" });
      }
    };
    

    const handleAddProduct = () => {
      // Validate fields before adding product
      let valid = true;
      const newErrors = {
        warehouseName: "",
        productName: "",
        quantity: "",
      };

      if (!formValues.warehouseName) {
        newErrors.warehouseName = "Please select a warehouse";
        valid = false;
      }
      if (!formValues.productName) {
        newErrors.productName = "Please select a product";
        valid = false;
      }
      if (!formValues.quantity) {
        newErrors.quantity = "Please enter a quantity";
        valid = false;
      }

      setErrors(newErrors);

      if (valid) {
        const newProduct = {
          warehouseId: formValues.warehouseId,
          warehouseName: formValues.warehouseName,
          productId: formValues.productId,
          productName: formValues.productName,
          unitId: formValues.unitId,
          unitSymbol: formValues.unitSymbol,
          quantity: formValues.quantity,
        };
        setProductList([...productList, newProduct]);
        // Reset form values
        setFormValues({
          ...formValues,
          productName: "",
          productId: "",
          unitSymbol: "",
          unitId: "",
          quantity: "",
        });
      }
    };

    const postData = async () => {
      try {
        const token = localStorage.getItem("token");
        const role = localStorage.getItem("role");
        if (!token || !role) {
          console.error("No token or role found in local storage");
          return;
        }

        // Check if productList has at least one product
        if (productList.length === 0) {
          setErrors({
            ...errors,
            productList: "At least one product needs to be added",
          });
          return;
        } else {
          setErrors({ ...errors, productList: "" });
        }

        const postData = {
          warehouseId: formValues.warehouseId,
          createdBy: role,
          items: productList.map((item) => ({
            productId: item.productId,
            unitId: item.unitId,
            quantity: item.quantity,
          })),
        };

        const response = await fetch("https://pos.farm2bag.com/api/v1/wastages", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(postData),
        });

        const responseData = await response.json(); // Parse the response JSON

        if (!response.ok) {
          if (responseData.data) {
            setErrorMessage(responseData.data); // Set the error message from response
          } else {
            throw new Error("Network response was not ok");
          }
        } else {
          // Handle success response
          console.log("Wastage created successfully");
          setShowCreatePopup(false);
          fetchData();
        }
      } catch (error) {
        console.error("Error creating wastage:", error);
      }
    };

    const handleCancel = () => {
      setFormValues({
        ...formValues,
        warehouseName: "",
        productName: "",
        productId: "",
        unitSymbol: "",
        unitId: "",
        quantity: "",
      });
      setShowCreatePopup(false);
      setErrors({
        warehouseName: "",
        productName: "",
        quantity: "",
        productList: "",
      }); // Clear all errors on cancel
    };
    const handleRemoveProduct = (index) => {
      const updatedProductList = [...productList];
      updatedProductList.splice(index, 1);
      setProductList(updatedProductList);
    };
    

    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 ml-32  mt-28 h-fit">
        <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-4xl">
          <h2 className="text-xl font-semibold mb-4">Create Wastage</h2>
          {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
          <form>
            <div className="flex flex-wrap mb-4 gap-4">
              <div className="flex-1">
                <label className="block mb-2">Warehouse Name</label>
                <select
                  value={formValues.warehouseName}
                  onChange={handleWarehouseChange}
                  className="border border-gray-300 px-4 py-2 rounded-lg w-full"
                >
                  <option value="">Select Name</option>
                  {warehouseNames.map((warehouse) => (
                    <option key={warehouse.id} value={warehouse.name}>
                      {warehouse.name}
                    </option>
                  ))}
                </select>
                {errors.warehouseName && (
                  <p className="text-red-500 mt-1">{errors.warehouseName}</p>
                )}
              </div>

              <div className="flex-1">
                <label className="block mb-2">Product Name</label>
                <select
                  value={formValues.productName}
                  onChange={handleProductChange}
                  className="border border-gray-300 px-4 py-2 rounded-lg w-full"
                >
                  <option value="">Select  Name</option>
                  {productNames.map((product) => (
                    <option key={product.productId} value={product.name}>
                      {product.name}
                    </option>
                  ))}
                </select>
                {errors.productName && (
                  <p className="text-red-500 mt-1">{errors.productName}</p>
                )}
              </div>

              <div className="flex-1">
                <label className="block mb-2">Unit Symbol</label>
                <input
                  type="text"
                  value={formValues.unitSymbol}
                  readOnly
                  className="border border-gray-300 px-4 py-2 rounded-lg w-full"
                />
              </div>

              <div className="flex-1">
                <label className="block mb-2">Quantity</label>
                <input
                  type="number"
                    min="1"
                  value={formValues.quantity}
                  onChange={handleQuantityChange}
                  
                  className="border border-gray-300 px-4 py-2 rounded-lg w-full"
                />
                {errors.quantity && (
                  <p className="text-red-500 mt-1">{errors.quantity}</p>
                )}
              </div>
            </div>

            <div className="flex justify-end gap-2 mb-4">
              <button
                type="button"
                onClick={handleAddProduct}
                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg"
              >
                Add Product
              </button>
              <button
                type="button"
                onClick={handleCancel}
                className="bg-gray-500 hover:bg-gray-600 text-white font-semibold py-2 px-4 rounded-lg"
              >
                Cancel
              </button>
            </div>

            <div className="mb-4">
              <h3 className="text-lg font-semibold mb-2">Product List</h3>
              {errors.productList && (
                <p className="text-red-500 mt-1">{errors.productList}</p>
              )}
              <div className="overflow-y-auto max-h-40  rounded-lg">
                <table className="w-full border border-gray-300">
                  <thead className="bg-green-400 ">
                    <tr>
                      <th className="px-4 py-2 border-b text-left">Warehouse Name</th>
                      <th className="px-4 py-2 border-b text-left">Product Name</th>
                      <th className="px-4 py-2 border-b text-left">Quantity</th>
                      <th className="py-2 px-4 border-b border-gray-200 text-left text-sm leading-4 font-medium text-gray-700 uppercase tracking-wider">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productList.map((product, index) => (
                      <tr key={index}>
                        <td className="px-4 py-2 border-b text-left">
                          {product.warehouseName}
                        </td>
                        <td className="px-4 py-2 border-b text-left">
                          {product.productName} 
                        </td>
                        <td className="px-4 py-2 border-b text-left">{product.quantity}
                          <span className="ml-2 px-2 bg-yellow-300 rounded-md text-left"> {product.unitSymbol}</span>
                          </td>
                          <td className="border border-gray-300 px-4 py-2">
                      <button
                        type="button"
                        onClick={() => handleRemoveProduct(index)}
                        className="text-red-500 hover:text-red-700 focus:outline-none"
                      >
                        <FaTrash/>
                      </button>
                    </td>
                             
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="flex justify-end">
              <button
                type="button"
                onClick={postData}
                className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-lg"
              >
                Create Wastage
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  export default WastageCreate;
