import React, { useState, useEffect } from 'react';
import SearchInput from 'react-search-input';
import filter from "../Assets/tableicon/filter.png";
import { IoMdTrash } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import Eye from "../Assets/tableicon/Eye.svg";
import CreateQualityCheckingPopup from '../components/CreateQualityCheckingPopup';
import QualityCheckingView from '../components/QualityCheckingView';
import QualityCheckingEdit from '../components/QualityCheckingEdit';
import QualityCheckingDelete from '../components/QualityCheckingDelete';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { startOfDay, endOfDay, parseISO, isWithinInterval } from "date-fns";


const QualityChecking = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
    const [editId, setEditId] = useState(null);
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [deleteInfo, setDeleteInfo] = useState('');
    const [isViewPopupOpen, setIsViewPopupOpen] = useState(false);
    const [viewId, setViewId] = useState(null);
    const [warehouses, setWarehouses] = useState([]);
    const [selectedWarehouseId, setSelectedWarehouseId] = useState(null);


    const fetchData = async () => {
        const token = localStorage.getItem('token');
        const headers = new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        });
    
        // Construct the URL with the selected warehouse as a query parameter
        const url = selectedWarehouseId 
            ? `https://pos.farm2bag.com/api/v1/qualityChecking/all?warehouseId=${selectedWarehouseId}` 
            : 'https://pos.farm2bag.com/api/v1/qualityChecking/all';
    
        try {
            const response = await fetch(url, { headers });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            const sortedResult = [...result.data || []].sort((a, b) => {
                const invoiceNoA = a.invoiceNo.toLowerCase();
                const invoiceNoB = b.invoiceNo.toLowerCase();
                if (invoiceNoA < invoiceNoB) return sortOrder === 'asc' ? -1 : 1;
                if (invoiceNoA > invoiceNoB) return sortOrder === 'asc' ? 1 : -1;
                return 0;
            });
            setData(sortedResult);
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };
    

    useEffect(() => {
        const fetchWarehouses = async () => {
            const token = localStorage.getItem('token');

            try {
                const response = await fetch('https://pos.farm2bag.com/api/v1/warehouse', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`  // Add the token to the Authorization header
                    }
                });

                const result = await response.json();
                if (response.ok) {
                    setWarehouses(result.data); // Set warehouse data
                } else {
                    console.error('Error fetching warehouse data:', result.message);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchWarehouses();
    }, []);


    useEffect(() => {
        fetchData();
    }, [sortOrder, selectedWarehouseId]);  // Add sortOrder here to refetch data when sortOrder changes


    const filteredData = data.filter(item => {
        const invoiceDate = parseISO(item.invoiceDate);
        const createdAt = parseISO(item.createdAt);
        const withinDateRange =
            (!startDate || !endDate || (isWithinInterval(invoiceDate, { start: startOfDay(startDate), end: endOfDay(endDate) }) || isWithinInterval(createdAt, { start: startOfDay(startDate), end: endOfDay(endDate) })));
        return item.invoiceNo.toLowerCase().includes(searchQuery.toLowerCase()) && withinDateRange;
    });

    const sortedData = [...filteredData].sort((a, b) => {
        const invoiceNoA = a.invoiceNo.toLowerCase();
        const invoiceNoB = b.invoiceNo.toLowerCase();
        if (invoiceNoA < invoiceNoB) return sortOrder === 'asc' ? -1 : 1;
        if (invoiceNoA > invoiceNoB) return sortOrder === 'asc' ? 1 : -1;
        return 0;
    });

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedData = filteredData.slice(startIndex, startIndex + itemsPerPage);

    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');

        return `${day}-${month}-${year} ${formattedHours}:${minutes} ${ampm}`;
    };

    const handlePopupClose = () => {
        setIsPopupOpen(false);
        setIsEditPopupOpen(false);
        setIsDeletePopupOpen(false);
        fetchData();
    };

    const handleEditClick = (id) => {
        setEditId(id);
        setIsEditPopupOpen(true);
    };

    const handleDeleteClick = (id, invoiceNo, purchaseOrder) => {
        setDeleteId(id);
        setIsDeletePopupOpen(true);
        setDeleteInfo({ invoiceNo, purchaseOrder });
    };
    const handleEyeClick = (id) => {
        setViewId(id);
        setIsViewPopupOpen(true);
    };

    const createMessage = (message) => {
        toast.success(message || "Successfully Created Quality Checking");
    };

    const updateMessage = (message) => {
        toast.success(message || "Successfully Update Quality Checking");
    }

    const handlePreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };
    const clearDateFilters = () => {
        setStartDate(null);
        setEndDate(null);
    };
    const handleFilterClick = () => {
        setSortOrder(prevOrder => prevOrder === 'asc' ? 'desc' : 'asc');
    };

    return (
        <div className='bg-gray-100 ml-64 mt-20 p-12 h-screen'>
            <div className="container mx-auto">
                <div className="mb-10">
                    <h1 className="font-extrabold text-3xl text-left text-gray-500">Quality Checking</h1>
                    <div className="mt-2 border-t border-gray-500"></div>
                </div>
                <div className="flex items-center mb-5">
                    <div className="ml-0">
                        <form>
                            <div className="relative">
                                <SearchInput
                                    placeholder="🔍 Invoice Number"
                                    onChange={setSearchQuery}
                                    style={{ padding: '6px', border: '1px solid #ccc', borderRadius: '5px', width: '110%', fontSize: '1rem', outline: 'none', fontWeight: 600 }}
                                />
                            </div>
                        </form>
                    </div>

                    <div className="flex items-center ml-10">
                        <select
                            value={selectedWarehouseId}
                            onChange={(e) => setSelectedWarehouseId(e.target.value)}
                            className="border border-gray-200 px-4 py-2 rounded-lg focus:outline-none focus:border-blue-500"
                        >
                            <option value="">All Warehouses</option>
                            {warehouses.map(warehouse => (
                                <option key={warehouse.id} value={warehouse.id}>
                                    {warehouse.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="flex items-center">
                        <label className="mr-2 ml-5">From:</label>
                        <DatePicker
                            selected={startDate}
                            onChange={date => setStartDate(date)}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="Select start date"
                            className="border border-gray-300 p-2 rounded w-36 mr-4"
                        />
                        <label className="mr-2">To:</label>
                        <DatePicker
                            selected={endDate}
                            onChange={date => setEndDate(date)}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="Select end date"
                            className="border border-gray-300 p-2 w-36 rounded"
                        />
                        <button onClick={clearDateFilters} className="bg-red-500 text-white p-2 ml-2 rounded"> Clear Dates </button>
                    </div>

                    <div className="flex ml-auto mr-0 items-center space-x-4">
                        <img src={filter} alt="filter" className="w-12 h-10 rounded-lg cursor-pointer" onClick={handleFilterClick} />
                        <button onClick={() => setIsPopupOpen(true)} style={{ backgroundColor: 'rgba(66, 255, 97, 1)' }} className="w-48 h-10 font-medium text-sm rounded-lg">
                            Create Quality Checking
                        </button>
                    </div>
                </div>

                <div className="overflow-x-auto rounded-lg">
                    <table className="min-w-full bg-white border border-gray-300">
                        <thead className='bg-blue-600'>
                            <tr>
                                <th className="py-2 px-4 border-b text-white">S.No</th>
                                <th className="py-2 px-4 border-b text-white">Invoice No</th>
                                <th className="py-2 px-4 border-b text-white">Purchase Code</th>
                                <th className="py-2 px-4 border-b text-white">Invoice Date</th>
                                <th className="py-2 px-4 border-b text-white">Product Count</th>
                                <th className="py-2 px-4 border-b text-white">Created At</th>
                                <th className="py-2 px-4 border-b text-white">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedData.map((item, index) => (
                                <tr key={item.id}>
                                    <td className="py-2 px-4 border-b">{startIndex + index + 1}</td>
                                    <td className="py-2 px-4 border-b">{item.invoiceNo}</td>
                                    <td className="py-2 px-4 border-b">{item.purchaseOrder}</td>
                                    <td className="py-2 px-4 border-b">{formatDateTime(item.invoiceDate)}</td>
                                    <td className="py-2 px-4 border-b">{item.productCount}</td>
                                    <td className="py-2 px-4 border-b">{formatDateTime(item.createdAt)}</td>
                                    <td className="py-2 px-4 border-b">
                                        <button className="mr-2 font-bold rounded" onClick={() => handleEyeClick(item.id)}>
                                            <img src={Eye} className="inline-block w-6 h-6 mr-2" alt="Eye Icon" />
                                        </button>

                                        <button onClick={() => handleEditClick(item.id)} className="mr-2 font-bold rounded">
                                            <FaRegEdit size={22} style={{ color: "blue" }} className="inline-block mr-1" />
                                        </button>
                                        <button onClick={() => handleDeleteClick(item.id, item.invoiceNo, item.purchaseOrder)} className="text-white font-bold rounded">
                                            <IoMdTrash size={30} style={{ color: "red" }} className="inline-block mr-1" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="flex items-center justify-between mt-4">
                    <div>
                        <label htmlFor="itemsPerPage" className="mr-2">Items per page:</label>
                        <select
                            id="itemsPerPage"
                            value={itemsPerPage}
                            onChange={handleItemsPerPageChange}
                            className="border border-gray-300 p-1.5 rounded"
                        >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                        </select>
                    </div>

                    <div className="flex items-center space-x-4">
                        <button
                            onClick={handlePreviousPage}
                            disabled={currentPage === 1}
                            className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50">
                            Previous
                        </button>
                        <span className="text-gray-600 font-medium">
                            {currentPage} of {totalPages}
                        </span>
                        <button
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                            className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50">
                            Next
                        </button>
                    </div>
                </div>
            </div>
            <ToastContainer />

            {isPopupOpen && <CreateQualityCheckingPopup onClose={handlePopupClose} toastMessage={createMessage} />}

            {isViewPopupOpen && <QualityCheckingView id={viewId} onClose={() => setIsViewPopupOpen(false)} />}


            {isEditPopupOpen && <QualityCheckingEdit id={editId} onClose={handlePopupClose} toastMessage={updateMessage} />}

            {isDeletePopupOpen && (<QualityCheckingDelete id={deleteId} invoiceNo={deleteInfo.invoiceNo} purchaseOrder={deleteInfo.purchaseOrder}
                onClose={handlePopupClose} toastMessage={() => toast.success("Successfully Deleted Quality Checking")} />)}
        </div>
    );
}

export default QualityChecking;