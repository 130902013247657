import React, { useState, useEffect } from 'react';
import Searchicon from "../Assets/tableicon/Searchicon.png";
import { isSameDay, isWithinInterval, parse, isValid } from 'date-fns'; 

const StocksHistory = () => {
    const [warehouses, setWarehouses] = useState([]);
    const [selectedWarehouse, setSelectedWarehouse] = useState('');
    const [stockHistories, setStockHistories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedDateRange, setSelectedDateRange] = useState('');
    const [customStartDate, setCustomStartDate] = useState(null);
    const [customEndDate, setCustomEndDate] = useState(null);
    const [filteredStockHistories, setFilteredStockHistories] = useState([]);
    const [showDateRangeSelect, setShowDateRangeSelect] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('token');
        fetchWarehouses(token);
    }, []);

    useEffect(() => {
        if (warehouses.length > 0) {
            const defaultWarehouseId = warehouses[0].id;
            setSelectedWarehouse(defaultWarehouseId);
            fetchStockHistories(defaultWarehouseId);
        }
    }, [warehouses]);

    useEffect(() => {
        if (selectedDateRange === 'Custom Date' && customStartDate && customEndDate) {
            const filteredData = stockHistories.filter(item => {
                if (!item.date) {
                    console.warn(`Item with missing date: ${JSON.stringify(item)}`);
                    return false;
                }
                const itemDate = parse(item.date, 'dd/MM/yyyy', new Date());
                if (!isValid(itemDate)) {
                    console.warn(`Invalid date format for item: ${JSON.stringify(item)}`);
                    return false;
                }
                const isWithinOrEqualInterval = isWithinInterval(itemDate, {
                    start: customStartDate,
                    end: customEndDate,
                }) || (isSameDay(itemDate, customStartDate) || isSameDay(itemDate, customEndDate));
                return isWithinOrEqualInterval;
            });
            setFilteredStockHistories(filteredData);
        } else {
            setFilteredStockHistories(stockHistories);
        }
    }, [selectedDateRange, customStartDate, customEndDate, stockHistories]);

    const fetchWarehouses = (token) => {
        fetch('https://pos.farm2bag.com/api/v1/warehouse', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (Array.isArray(data.data)) {
                    setWarehouses(data.data);
                } else {
                    throw new Error('Data format is not valid');
                }
            })
            .catch(error => setError(error.message));
    };

    const fetchStockHistories = (warehouseId) => {
        const token = localStorage.getItem('token');
        setLoading(true);

        fetch(`https://pos.farm2bag.com/api/v1/stockHistory/warehouse/${warehouseId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (Array.isArray(data.data)) {
                    const flattenedStockHistories = data.data.flatMap(stockHistory => stockHistory.products.map(product => ({
                        ...product,
                        date: product.date // Ensure the date is present
                    })));
                    setStockHistories(flattenedStockHistories);
                    setFilteredStockHistories(flattenedStockHistories); // Set initial filtered data
                } else {
                    throw new Error('Data format is not valid');
                }
            })
            .catch(error => setError(error.message))
            .finally(() => setLoading(false));
    };

    const handleWarehouseChange = (event) => {
        const warehouseId = event.target.value;
        setSelectedWarehouse(warehouseId);
        fetchStockHistories(warehouseId);
        setCurrentPage(1);
    };

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to first page when search query changes
    };

    const handleItemsPerPageChange = (event) => {
        const newItemsPerPage = Number(event.target.value);
        console.log(`Items per page changed. Old value: ${itemsPerPage}, New value: ${newItemsPerPage}`);

        setItemsPerPage(newItemsPerPage);
        setCurrentPage(1); // Reset to first page when items per page changes

        console.log(`Items per page set: ${newItemsPerPage}`);
    };

    const handleCustomStartDateChange = (event) => {
        setCustomStartDate(new Date(event.target.value));
    };

    const handleCustomEndDateChange = (event) => {
        setCustomEndDate(new Date(event.target.value));
    };

    const handleDateRangeChange = (event) => {
        const value = event.target.value;
        setSelectedDateRange(value);
        if (value === 'Custom Date') {
            setShowDateRangeSelect(false);
        }
    };

    const handleCustomDateButtonClick = () => {
        setSelectedDateRange('Custom Date');
        setShowDateRangeSelect(false);
        setCurrentPage(1);

    };

    // const filteredStockHistoriesBySearch = filteredStockHistories.filter(stockHistory =>
    //     stockHistory.name?.toLowerCase().includes(searchQuery.toLowerCase())
    // );

    const filteredStockHistoriesBySearch = filteredStockHistories.filter(stockHistory =>
        stockHistory.name?.toLowerCase().includes(searchQuery.toLowerCase()) || 
        stockHistory.productsCode?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    

    // Pagination logic
    const totalPages = Math.ceil(filteredStockHistoriesBySearch.length / itemsPerPage);

    const handleClickPage = (page) => {
        setCurrentPage(page);
    };

    // Adjust for "Previous" button behavior
    const handlePreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleReset = () => {
        setCustomStartDate(null); // Reset custom start date
        setCustomEndDate(null); // Reset custom end date
        setSelectedDateRange(''); // Reset selected date range to default
        setShowDateRangeSelect(true); // Show date range select again
    };

    const paginatedStockHistories = filteredStockHistoriesBySearch.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const hasNextPage = currentPage < totalPages;
    const hasPreviousPage = currentPage > 1;

    return (
        <div className='bg-gray-100 ml-64 mt-20 p-12 h-screen'>
            <div className='container mx-auto'>
                <div className="mb-10">
                    <h1 className="font-extrabold text-3xl text-left text-gray-800">Stocks History</h1>
                    <div className="mt-2 border-t border-gray-300"></div>
                </div>

                {warehouses.length > 0 && (
                    <div className="flex justify-between items-center mb-5">
                        <div className="flex items-center space-x-4">
                            <div className="relative">
                                <input type="text" placeholder='Search Products' value={searchQuery} onChange={handleSearchInputChange} className="border font-semibold border-gray-300 px-4 py-2 pl-10 rounded-lg focus:outline-none focus:border-blue-500" />
                                <img src={Searchicon} alt="search" className="absolute left-3 top-3 w-4 h-4" />
                            </div>
                            <select value={selectedWarehouse} onChange={handleWarehouseChange} style={{ backgroundColor: "#1afc05" }} className="border border-gray-300 px-4 py-2 rounded-lg focus:outline-none focus:border-blue-500">
                                {warehouses.map(warehouse => (
                                    <option key={warehouse.id} value={warehouse.id}>
                                        {warehouse.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="flex items-center space-x-4 mt-0">
                            {showDateRangeSelect ? (
                                <button onClick={handleCustomDateButtonClick} className="px-4 py-2 rounded-lg bg-yellow-400 text-black">Custom Date 📅</button>
                            ) : (
                                <div className="flex items-center space-x-4 mt-0">
                                    <input type="date" className="p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" value={customStartDate ? customStartDate.toISOString().substr(0, 10) : ""}
                                        onChange={handleCustomStartDateChange}
                                    />
                                    <input type="date" className="p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        value={customEndDate ? customEndDate.toISOString().substr(0, 10) : ""}
                                        onChange={handleCustomEndDateChange}
                                    />
                                    <button className="w-28 h-10 ml-2 font-medium text-sm rounded-lg" style={{ backgroundColor: "#1afc05" }} onClick={handleReset}>
                                        Clear
                                    </button>
                                </div>
                            )}
                        </div>
                        <div>
                            <label htmlFor="itemsPerPage" className="mr-2">Items per page:</label>
                            <select id="itemsPerPage" value={itemsPerPage} onChange={handleItemsPerPageChange} className="border border-gray-300 px-4 py-1 rounded-lg focus:outline-none focus:border-blue-500">
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                                <option value={100}>100</option>
                            </select>
                        </div>
                    </div>
                )}

                <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
                    <thead className="bg-blue-500">
                        <tr>
                            <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">ProductS CODE</th>
                            <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">Product Name</th>
                            <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">Date</th>
                            <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">Quantity</th>
                            <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">SELLING PRICE ( ₹ )</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {paginatedStockHistories.map((stockHistory, index) => (
                            <tr key={index} className="hover:bg-green-200">
                                <td className="py-3 px-4 text-left">{stockHistory.productsCode}</td>
                                <td className="py-3 px-4 text-left">{stockHistory.name}</td>
                                <td className="py-3 px-4 text-left">{stockHistory.date}</td>
                                <td className="py-3 px-4 text-left">{stockHistory.quantity} <span className='bg-yellow-300 text-gray-700 text-sm px-1 rounded ml-2'>{stockHistory.unitSymbol}</span></td>
                                <td className="py-3 px-4 text-left">₹ {stockHistory.price}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="flex justify-between mt-4">
                    <button onClick={handlePreviousPage} disabled={!hasPreviousPage} className={`px-4 py-2 rounded-lg ${hasPreviousPage ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700 cursor-not-allowed'}`}>
                        Previous
                    </button>
                    <div>
                        Page {currentPage} of {totalPages}
                    </div>
                    <button onClick={() => handleClickPage(currentPage + 1)} disabled={!hasNextPage} className={`px-4 py-2 rounded-lg ${hasNextPage ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700 cursor-not-allowed'}`}>
                        Next
                    </button>
                </div>

                {loading && <p>Loading...</p>}
                {error && <p>Error: {error}</p>}
            </div>
        </div>
    );
};

export default StocksHistory;
