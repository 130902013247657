import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2'; // Import Pie chart component from react-chartjs-2
import 'chart.js/auto'; // Ensure chart.js is imported
import { Link, useParams } from 'react-router-dom';

const   CustomerAnalytics = () => {
    
    const { customerId, customerName } = useParams();
    const [billingItems, setBillingItems] = useState([]);
    const [billings, setBillings] = useState([]);
    const [topProducts, setTopProducts] = useState([]);
    const[topCategories, setTopcategories]= useState([]);

    const [error, setError] = useState(null);
const [stats, setStats] = useState([]);
    const [customerAnalytics, setCustomerAnalytics] = useState(null);
    const [loading, setLoading] = useState(true);
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Purchases by Category',
                data: [],
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'], // More vibrant colors
                hoverOffset: 6, // Increase hover effect
                borderWidth: 2, // Add border for better visual contrast
                borderColor: '#ffffff', // White border for pie slices
            },
        ],
    });
    const pieOptions = {
        responsive: true,
        maintainAspectRatio: false, // Allow manual width and height control
        plugins: {
            legend: {
                position: 'bottom', // Move legend to the bottom
                labels: {
                    font: {
                        size: 14, // Customize legend font size
                        family: "'Helvetica Neue', 'Arial', sans-serif",
                    },
                    color: '#374151', // Dark gray text for labels
                },
            },
            tooltip: {
                backgroundColor: '#374151', // Darker background for tooltip
                titleColor: '#ffffff',
                bodyColor: '#ffffff',
                borderColor: '#6366F1',
                borderWidth: 1,
            },
        },
        // layout: {
        //     padding: 20, // Add padding to slightly increase the overall chart size
        // },
    };
        
    useEffect(() => {
        const fetchRevenue = async () => {
            try {
                const token = localStorage.getItem('token'); // Get token from local storage
    
                // Make the API request to fetch customer billing stats
                const response = await fetch(`https://pos.farm2bag.com/api/v1/billing/customers/${customerId}/stats`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
    
                // Parse the response as JSON
                const result = await response.json();
    
                // Check if the response was successful
                if (response.ok && result.status === 200) {
                    // Set the customer billing stats data
                    setStats(result.data); // result.data contains the actual CustomerBillingStats object
                    console.log(result.data); // Log the data for debugging
                } else {
                    // Handle the error based on the API's error message
                    setError(result.message || 'Error fetching revenue data.');
                }
            } catch (err) {
                // Handle any network or unexpected errors
                setError('An unexpected error occurred.');
            }
        };
        const fetchBillingItems = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`https://pos.farm2bag.com/api/v1/billing/customers/${customerId}/billing-items`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
        
                const data = await response.json();
                console.log(data); // Log the response to check the structure
        
                if (response.ok) {
                    setBillingItems(data); // Set billing items from the response
                    
                    // Only calculate top products if data is an array
                    if (Array.isArray(data)) {
                        calculateTopProducts(data); 
                        calculateChartData(data); 
                    } else {
                        setTopProducts([]); // Reset top products if no data
                    }
                } else {
                    setError(data.message || 'Error fetching billing items.');
                }
            } catch (err) {
                setError('An unexpected error occurred.');
            }
        };
        
        const calculateChartData = (billingItems) => {
            const categoryTotals = {};
    
            // Grouping by categoryName and summing the totalPrice
            billingItems.forEach(item => {
                const { categoryName, totalPrice } = item;
                categoryTotals[categoryName] = (categoryTotals[categoryName] || 0) + totalPrice;
            });
    
            // Prepare data for the pie chart
            const categories = Object.keys(categoryTotals);
            const totals = Object.values(categoryTotals);
    
            setChartData({
                labels: categories,
                datasets: [{
                    data: totals,
                    backgroundColor: [
                        '#FF6384',
                        '#36A2EB',
                        '#FFCE56',
                        '#4BC0C0',
                        '#9966FF',
                        '#FF9F40'
                    ],
                    hoverBackgroundColor: [
                        '#FF6384',
                        '#36A2EB',
                        '#FFCE56',
                        '#4BC0C0',
                        '#9966FF',
                        '#FF9F40'
                    ],
                }]
            });
        };
    
        const fetchBillings = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`https://pos.farm2bag.com/api/v1/billing/customers/${customerId}/billings`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                const data = await response.json();
                if (response.ok) {
                    setBillings(data);
                } else {
                    setError(data.message || 'Error fetching billings.');
                }
            } catch (err) {
                setError('An unexpected error occurred.');
            }
        };

        fetchBillingItems();
        fetchBillings();
        // Trigger the API call inside useEffect
        fetchRevenue();
    }, [customerId]); // Re-run the effect if customerId changes
    const formatHour = (hour) => {
        if (hour === -1) return 'No Data';
        const suffix = hour >= 12 ? 'PM' : 'AM';
        const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
        return `${formattedHour} ${suffix}`;
    };


    const calculateTopProducts = async (billingItems) => {
        const productCount = {};
    
        // Check if billingItems is an array
        if (!Array.isArray(billingItems)) {
            console.error("billingItems is not an array");
            return;
        }
    
        // Iterate over billingItems to count products by their names and units
        billingItems.forEach(item => {
            // Get product name, unit, and quantity directly from the item
            const productName = item.productName; // Get product name
            const unit = item.unit; // Get product unit
            const quantity = item.quantity; // Get product quantity
    
            // Ensure that productName, unit, and quantity are defined
            if (productName && unit && quantity !== undefined) {
                // Create a unique key for each product with its unit
                const key = `${productName} (${unit})`;
    
                // Increment quantity for the product
                if (productCount[key]) {
                    productCount[key] += quantity; // Increment quantity
                } else {
                    productCount[key] = quantity; // Initialize quantity
                }
            } else {
                console.warn("Item is missing productName, unit, or quantity:", item);
            }
        });
    
        // Sort the products by quantity to determine top-selling products
        const sortedProducts = Object.entries(productCount)
            .sort(([, quantityA], [, quantityB]) => quantityB - quantityA)
            .slice(0, 3) // Get top 3 products
            .map(([name, quantity]) => ({ name, quantity }));
    
        setTopProducts(sortedProducts); // Set top-selling products
    };
    
console.log(topProducts);    
    
    useEffect(() => {
        if (billingItems.length > 0) {
            calculateTopProducts();
        }
    }, [billingItems]);



    return (
        <div className='bg-gray-100 ml-64 mt-20 p-12 h-full'>
            {/* Header section */}
            <div className="mb-12 text-center p-6 bg-white rounded-lg shadow-md border border-gray-200">
    <h1 className="text-5xl font-bold text-gray-800">Customer Analytics</h1>
    <div className="mt-4 p-4 bg-gray-50 rounded-lg shadow-inner">
        <p className="text-lg text-gray-600">Detailed insights for:</p>
        {/* <p className="text-2xl font-semibold text-teal-500">
            Customer ID: <span className="font-bold">{customerId}</span>
        </p> */}
        <p className="text-2xl font-semibold text-teal-500">
            Name: <span className="font-bold">{customerName}</span>
        </p>
    </div>
</div>



            {/* Analytics metrics in a modern card grid */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 mb-8">
                <div className="p-6 bg-white rounded-xl shadow-lg hover:shadow-2xl transition-shadow">
                    <h2 className="text-lg font-medium text-gray-700">Total Revenue</h2>
                    <p className="text-3xl font-bold text-indigo-600 mt-2">₹
                    {stats.totalRevenue || 'Loading...'}</p>
                </div>
                <div className="p-6 bg-white rounded-xl shadow-lg hover:shadow-2xl transition-shadow">
    <h2 className="text-lg font-medium text-gray-700">Avg. Transaction Amount</h2>
    <p className="text-3xl font-bold text-teal-500 mt-2">
        ₹ {stats.averageTransactionAmount ? stats.averageTransactionAmount.toFixed(2) : 'Loading...'}
    </p>
</div>

                <div className="p-6 bg-white rounded-xl shadow-lg hover:shadow-2xl transition-shadow">
                    <h2 className="text-lg font-medium text-gray-700">Number of Transactions</h2>
                    <p className="text-3xl font-bold text-yellow-500 mt-2">{stats.numberOfTransactions || 'Loading...'}</p>
                </div>
                <div className="p-6 bg-white rounded-xl shadow-lg hover:shadow-2xl transition-shadow">
                    <h2 className="text-lg font-medium text-gray-700">Total Visits</h2>
                    <p className="text-3xl font-bold text-red-500 mt-2">{stats.totalVisits || 'Loading...'}</p>
                </div>
            </div>

            {/* New Analytics Details */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
                <div className="bg-white rounded-xl shadow-lg p-6">
      
    <h3 className="text-3xl font-bold text-blue-600 mb-6">Top Products Purchased</h3>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {topProducts.map((product, index) => (
            <div key={index} className="bg-gray-100 rounded-lg p-4 shadow-md transition-transform transform hover:scale-105">
                <h4 className="text-xl font-semibold text-gray-800">{product.name}</h4>
                <p className="text-gray-600">{product.quantity} units</p>
            </div>
        ))}
    </div>


                </div>

                <div className="bg-white rounded-xl shadow-lg p-6 border border-gray-200">
    <h3 className="text-3xl font-bold text-blue-600 mb-6">Most Frequent Purchase Time</h3>
    <div className="flex items-center justify-center bg-gray-100 rounded-lg p-4 shadow-md">
        <p className="text-4xl font-semibold text-gray-800">
            {formatHour(stats.mostFrequentHour)}
        </p>
    </div>
</div>

            </div>

            {/* Transaction history */}
            <div className="bg-white rounded-xl shadow-lg p-6 mb-8">
                <h3 className="text-2xl font-semibold text-gray-800 mb-4">Transaction History</h3>
                <ul className="divide-y divide-gray-200">
                {billings.map((billing, index) => (
                   <li key={index} className="py-4 flex justify-between items-center">
                   <span className="font-medium text-gray-700">
                     Invoice ID:
                     <Link
                       to={`/sales/${billing.invoice}`} // Navigate to the sales page with the invoice number
                       className="ml-1 font-medium  underline text-blue-500 focus:text-blue-500 hover:bg-blue-100 transition-colors duration-200"
                     >
                       {billing.invoice}
                     </Link>
                   </span>
                   <span className="text-teal-600 font-bold">${billing.totalPrice.toFixed(2)}</span>
                   <span className="text-gray-500">{new Date(billing.createdAt).toLocaleDateString()}</span>
                 </li>
                 
                ))}
            </ul>
            </div>
{/* Analytics metrics and other components remain unchanged */}
<div className="bg-white rounded-xl shadow-lg p-6">
    <h3 className="text-2xl font-semibold text-gray-800 mb-4">Purchases by Category</h3>
    <div className="relative w-full h-80"> {/* Increased height to h-80 for a larger chart */}
        <Pie data={chartData} options={pieOptions} />
    </div>
</div>


        </div>
    );
};

export default CustomerAnalytics;

