import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import { IoMdTrash } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import filter from "../Assets/tableicon/filter.png";
import Searchicon from "../Assets/tableicon/Searchicon.png";
import Switch from "react-switch";
import SearchInput from 'react-search-input';


const Brand = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [isCreatePopupOpen, setCreatePopupOpen] = useState(false);
    const [isEditPopupOpen, setEditPopupOpen] = useState(false);
    const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
    const [newBrandName, setNewBrandName] = useState('');
    const [updateBrandName, setUpdateBrandName] = useState('');
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [switchStatus, setSwitchStatus] = useState(false);
    const [sortBy, setSortBy] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc'); // Initialize sort direction
    const [searchTerm, setSearchTerm] = useState('');





    useEffect(() => {
        fetchData();
    }, [currentPage, itemsPerPage]);

    const fetchData = async () => {
        try {
            const token = localStorage.getItem('token');
    
            if (!token) {
                console.error('No token found in local storage');
                return;
            }
    
            const response = await fetch('https://pos.farm2bag.com/api/v1/brand/master', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    
            const responseData = await response.json();
    
            // Ensure that the response contains the createdAt field
            const BrandData = responseData.data.map(Brand => ({
                brandName: Brand.brandName,
                id: Brand.id,
                status: Brand.status,
                createdAt: Brand.createdAt // Make sure this field exists in your response
            }));
    
            // Sort by createdAt in descending order
            const sortedBrandData = BrandData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    
            setData(sortedBrandData);
    
            // Adjust the current page if necessary
            const totalPages = Math.ceil(sortedBrandData.length / itemsPerPage);
            if (currentPage > totalPages) {
                setCurrentPage(totalPages);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    

    const handleFilterClick = (key) => {
        if (sortBy === key) {
            // Toggle sorting direction
            const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
            setData([...data].sort((a, b) => {
                if (newSortDirection === 'asc') {
                    if (a[key] < b[key]) return -1;
                    if (a[key] > b[key]) return 1;
                } else {
                    if (a[key] < b[key]) return 1;
                    if (a[key] > b[key]) return -1;
                }
                return 0;
            }));
            setSortDirection(newSortDirection);
        } else {
            // Sort by the selected key in ascending order by default
            setData([...data].sort((a, b) => {
                if (a[key] < b[key]) return -1;
                if (a[key] > b[key]) return 1;
                return 0;
            }));
            setSortBy(key);
            setSortDirection('asc'); // Set default direction to ascending
        }
    };
    
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data
        .filter(item => {
            if (!searchTerm) return true; // Return true if searchTerm is empty
            return item.brandName.toLowerCase().includes(searchTerm.toLowerCase());
        })
        .slice(indexOfFirstItem, indexOfLastItem);


    const handleEdit = (brand) => {
        setSelectedBrand(brand);
        setUpdateBrandName(brand.brandName);
        setEditPopupOpen(true);
    };

    const handleDelete = (brand) => {
        setSelectedBrand(brand);
        setDeletePopupOpen(true);
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleItemsPerPageChange = (e) => {
        setCurrentPage(1);
        setItemsPerPage(parseInt(e.target.value));
    };

    const handleCreateBrand = async () => {
        try {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');

            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            // Check if newBrandName is null or empty
            if (!newBrandName.trim()) {
                // Notify the user that the brand name cannot be empty
                toast.error('Brand name cannot be empty');
                return;
            }

            const response = await fetch('https://pos.farm2bag.com/api/v1/brand', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    brandName: newBrandName,
                    createdBy: role,
                    image: "nothing"
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                toast.error(`Failed to create Brands: ${errorData.message || response.statusText}`);
                return; // Exit the function if the request was not successful
            }

            console.log('Brand created successfully');
            setCreatePopupOpen(false);
            setNewBrandName('');

            // After successfully creating the Brand, you might want to refresh the data
            fetchData();
            toast.success('Brand created successfully');

        } catch (error) {
            console.error('Error creating Brand:', error);
        }
    };


    const handleUpdateBrand = async () => {
        try {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');

            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const id = selectedBrand.id;

            if (!updateBrandName.trim()) {
                // Notify the user that the category name cannot be empty
                toast.error('Category name cannot be empty');
                return;
            }

            const response = await fetch(`https://pos.farm2bag.com/api/v1/brand/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    brandName: updateBrandName,
                    status: 1,
                    modifiedBy: role, // Ensure modifiedBy is included in the request body
                })
            });

            if (!response.ok) {
                throw new Error('Failed to update Brand');
            }

            console.log('Brand updated successfully');
            setEditPopupOpen(false);
            setUpdateBrandName('');

            // After successfully updating the Brand, you might want to refresh the data
            fetchData();

            toast.success('Brand Update successfully');

        } catch (error) {
            console.error('Error updating Brand:', error);
        }
    };

    //--------------------------------------------------------------------------------

    const toggleStatus = async (brand) => {
        try {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');

            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const id = brand.id;
            const newStatus = brand.status === 0 ? 1 : 0; // Toggle status value

            const response = await fetch(`https://pos.farm2bag.com/api/v1/brand/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    brandName: brand.brandName, // Retain the current brandName
                    status: newStatus,
                    modifiedBy: role
                })
            });

            if (!response.ok) {
                throw new Error('Failed to toggle status');
            }

            console.log('Status toggled successfully');

            // Update the status value in the data array
            setData(data.map(item => item.id === id ? { ...item, status: newStatus } : item));
        } catch (error) {
            console.error('Error toggling status:', error);
        }
    };

    //---------------------------------------------------------------------------------

    const handleDeleteBrand = async () => {
        try {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');

            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const id = selectedBrand.id;

            const response = await fetch(`https://pos.farm2bag.com/api/v1/brand/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ modifiedBy: role })
            });

            if (!response.ok) {
                throw new Error('Failed to delete Brand');
            }

            console.log('Brand deleted successfully');
            setDeletePopupOpen(false);

            // After successfully deleting the Brand, you might want to refresh the data
            fetchData();

            toast.success('Brand Deleted successfully');

        } catch (error) {
            console.error('Error deleting Brand:', error);
        }
    };


    return (
        <div className='bg-gray-100 ml-64 mt-20 p-12 h-screen'>
            <div className="container mx-auto">
                <div className="mb-10">
                    <h1 className="font-extrabold text-3xl text-left text-gray-500">Brand</h1>
                    <div className="mt-2 border-t border-gray-500"></div>
                </div>
                <div className="flex items-center mb-5">
                    <div className="ml-0">
                        <form>
                        <div className="relative">
                                <SearchInput onChange={(term) => setSearchTerm(term)} placeholder="🔍 category name"
                                    style={{ padding: '6px', border: '1px solid #ccc', borderRadius: '5px', width: '120%', fontSize: '1rem', outline: 'none',fontWeight: 600 }} />
                            </div>
                        </form>
                    </div>
                    <div className="flex ml-auto mr-0 items-center space-x-4">
                        <img src={filter} alt="filter" className="w-12 h-10 rounded-lg cursor-pointer" onClick={() => handleFilterClick('brandName')} />
                        <button onClick={() => setCreatePopupOpen(true)} style={{ backgroundColor: 'rgba(66, 255, 97, 1)' }} className="w-48 h-10 font-medium text-sm rounded-lg">Create Product Brand</button>
                    </div>
                </div>

                <div className="rounded-lg overflow-hidden">
                    <table className="table-auto w-full border-collapse border-gray-500 text-center">
                        <thead>
                            <tr className="bg-gray-200">
                                <th className="px-4 py-2 text-left pl-16">S.No</th>
                                <th className="px-4 py-2 text-left pl-16">Brand</th>
                                <th className="px-4 py-2 text-left pl-16">Status</th>
                                <th className="px-4 py-2 text-left pl-16">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((item, index) => (
                                <tr key={item.id} className={(index % 2 === 0) ? 'bg-white' : 'bg-gray-200'}>
                                    <td className="py-2 px-4 border-b text-center">{indexOfFirstItem + index + 1}</td>
                                    <td className="px-4 py-2 border-gray-500 text-left pl-16">{item.brandName}</td>
                                    <td className="px-4 py-2 border-gray-500 text-left pl-16">
                                        <Switch
                                            onChange={() => toggleStatus(item)}
                                            checked={item.status === 1}
                                        />
                                    </td>
                                    <td className="px-4 py-2 border-gray-500 text-left pl-16">
                                        <button onClick={() => handleEdit(item)} className="mr-2 font-bold  rounded">
                                            <FaRegEdit size={22} style={{ color: "blue" }} className="inline-block mr-1" />
                                        </button>

                                        <button onClick={() => handleDelete(item)} className=" text-white font-bold rounded">
                                            <IoMdTrash size={30} style={{ color: "red" }} className="inline-block mr-1" />
                                        </button>
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {currentItems.length === 0 && (
                    <div className="text-center mt-4 text-gray-500">
                        No result found.
                    </div>
                )}

                {isCreatePopupOpen && (
                    <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
                        <div className="bg-gray-200 w-2/6 h-2/6 p-6 rounded-lg ">
                            <h2 className="text-xl font-bold mb-4">Create New Brand</h2>
                            <div className="mb-4 border-t border-gray-500"></div>
                            <input type="text" value={newBrandName} onChange={(e) => setNewBrandName(e.target.value)} placeholder="Enter Brand name" className="border border-gray-200 px-4 py-2 rounded-lg mb-4 w-4/5" /> <br />
                            <button onClick={() => setCreatePopupOpen(false)} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg ml-2 float-left ml-11 mt-10 hover:bg-red-500">Cancel</button>
                            <button onClick={handleCreateBrand} className="bg-blue-500 text-white px-4 py-2 rounded-lg float-right mr-11 mt-10 hover:bg-green-600">Create</button>
                        </div>
                    </div>
                )}

                {isEditPopupOpen && (
                    <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
                        <div className="bg-white p-6 rounded-lg">
                            <h2 className="text-xl font-bold mb-4">Edit Brand</h2>
                            <input type="text" value={updateBrandName} onChange={(e) => setUpdateBrandName(e.target.value)} placeholder="Enter new Brand name" className="border border-gray-200 px-4 py-2 rounded-lg mb-4 w-4/5" />
                            <button onClick={() => setEditPopupOpen(false)} className="bg-blue-500 text-white px-4 py-2 rounded-lg float-left mr-11 mt-10 hover:bg-red-600">Cancel</button>
                            <button onClick={handleUpdateBrand} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg ml-2 float-right ml-11 mt-10 hover:bg-green-500">Update</button>
                        </div>
                    </div>
                )}

                {isDeletePopupOpen && (
                    <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
                        <div className="bg-white p-6 rounded-lg">
                            <h2 className="text-xl font-bold mb-4">Delete Brand</h2>
                            <p>Are you sure you want to delete the Brand "{selectedBrand.brandName}"?</p>
                            <button onClick={() => setDeletePopupOpen(false)} className="bg-blue-500 text-white px-4 py-2 rounded-lg float-left mr-11 mt-10 hover:bg-red-600">Cancel</button>
                            <button onClick={handleDeleteBrand} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg ml-2 float-right ml-11 mt-10 hover:bg-green-500">Delete</button>
                        </div>
                    </div>
                )}

                <div className="flex justify-between items-center mt-5 pb-10">
                    <div>
                        <label htmlFor="itemsPerPage" className="mr-2">Items per page:</label>
                        <select id="itemsPerPage" value={itemsPerPage} onChange={handleItemsPerPageChange} className="border border-gray-300 rounded-lg px-2 py-1">
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                            <option value={20}>20</option>
                        </select>
                    </div>
                    <div className="flex space-x-2">
                        {Array.from({ length: Math.ceil(data.length / itemsPerPage) }, (_, index) => (
                            <button
                                key={index}
                                onClick={() => paginate(index + 1)}
                                className={`px-3 py-1 rounded ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default Brand;
