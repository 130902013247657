import React, { useEffect, useState } from 'react';

const SalesStatus = () => {
    const [salesData, setSalesData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [selectedWarehouse, setSelectedWarehouse] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [error, setError] = useState(null);
    const [apiMessage, setApiMessage] = useState(''); // State for API message
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');

    const token = localStorage.getItem('token');

    const fetchSalesStatus = async () => {
        try {
            const url = new URL('https://pos.farm2bag.com/api/v1/billingItems/status');

            // Add params to the URL if available
            if (selectedWarehouse) url.searchParams.append('warehouseId', selectedWarehouse);
            if (selectedCategory) url.searchParams.append('categoryId', selectedCategory);
            if (fromDate) url.searchParams.append('fromDate', fromDate);
            if (toDate) url.searchParams.append('toDate', toDate);

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            const result = await response.json();

            if (response.ok) {
                setSalesData(result.data);
                // Set API message if data is empty
                if (result.data.length === 0) {
                    setApiMessage(result.message);
                } else {
                    setApiMessage(''); // Clear message if data is present
                }
            } else {
                throw new Error(result.message);
            }
        } catch (err) {
            setError(err.message);
        }
    };

    // Fetch categories and warehouses on component load
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch('https://pos.farm2bag.com/api/v1/categories/getAll/master', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                const result = await response.json();

                if (response.ok) {
                    setCategories(result.data);
                } else {
                    throw new Error(result.message);
                }
            } catch (err) {
                setError(err.message);
            }
        };

        const fetchWarehouses = async () => {
            try {
                const response = await fetch('https://pos.farm2bag.com/api/v1/warehouse', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                const result = await response.json();

                if (response.ok) {
                    setWarehouses(result.data);
                    if (result.data.length > 0) {
                        setSelectedWarehouse(result.data[0].id);
                    }
                } else {
                    throw new Error(result.message);
                }
            } catch (err) {
                setError(err.message);
            }
        };

        fetchCategories();
        fetchWarehouses();
    }, [token]);

    // Re-fetch sales data when filter conditions change
    useEffect(() => {
        fetchSalesStatus();
    }, [selectedWarehouse, selectedCategory, fromDate, toDate]);

    // Filtered sales data based on search query
    const filteredSalesData = salesData.filter(item =>
        item.productName && item.productName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Calculate pagination data
    const totalItems = filteredSalesData.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredSalesData.slice(indexOfFirstItem, indexOfLastItem);

    // Reset page to 1 when filters change
    useEffect(() => {
        setCurrentPage(1);
    }, [selectedWarehouse, selectedCategory, fromDate, toDate, searchQuery]);

    const totalSalesPrice = filteredSalesData.reduce((acc, item) => acc + item.totalPrice, 0);

    if (error) {
        return <div className="text-center text-red-600">Error: {error}</div>;
    }

    return (
        <div className="container mx-auto p-6 bg-gray-50 min-h-screen">
            <h1 className="text-3xl font-bold text-blue-700 mb-6 text-center">Sales Status</h1>

            <div className="flex flex-wrap space-x-2 mb-6 justify-center">
                <div className="mb-4 text-center">
                    <label htmlFor="search" className="block mb-2 text-lg text-black font-semibold">Search Product:</label>
                    <input
                        type="text"
                        placeholder="Search by Product Name"
                        className="border border-gray-300 rounded p-2 w-64 text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>

                {/* Warehouse and Category selectors */}
                <div className="mb-4">
                    <label htmlFor="warehouse" className="block mb-2 text-lg text-black font-semibold">Select Warehouse:</label>
                    <select
                        id="warehouse"
                        className={`border border-gray-300 rounded p-2 w-64 text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 ${selectedWarehouse ? 'bg-green-400' : 'bg-white'}`}
                        value={selectedWarehouse}
                        onChange={(e) => setSelectedWarehouse(e.target.value)}
                    >
                        <option value="">-- Select a Warehouse --</option>
                        {warehouses.map(warehouse => (
                            <option key={warehouse.id} value={warehouse.id}>
                                {warehouse.name} - {warehouse.city}, {warehouse.zipCode}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="mb-4">
                    <label htmlFor="category" className="block mb-2 text-lg text-black font-semibold">Select Category:</label>
                    <select
                        id="category"
                        className={`border border-gray-300 rounded p-2 w-64 text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 ${selectedCategory ? 'bg-green-400' : 'bg-white'}`}
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                        <option value="">-- Select a Category --</option>
                        {categories.map(category => (
                            <option key={category.id} value={category.id}>
                                {category.categoryName}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="mb-4">
                    <label htmlFor="fromDate" className="block mb-2 text-lg text-black font-semibold">From Date:</label>
                    <input
                        type="date"
                        id="fromDate"
                        className={`border border-gray-300 rounded p-2 w-40 text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 ${fromDate ? 'bg-green-400' : 'bg-white'}`}
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="toDate" className="block mb-2 text-lg text-black font-semibold">To Date:</label>
                    <input
                        type="date"
                        id="toDate"
                        className={`border border-gray-300 rounded p-2 w-40 text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 ${toDate ? 'bg-green-400' : 'bg-white'}`}
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                    />
                </div>
            </div>

            <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
                <thead>
                    <tr className="bg-blue-600 text-white uppercase text-sm leading-normal">
                        <th className="py-3 px-6 text-left">S.No</th>
                        <th className="py-3 px-6 text-left">Product Name</th>
                        <th className="py-3 px-6">Quantity</th>
                        <th className="py-3 px-6">Unit</th>
                        <th className="py-3 px-6">Total Price</th>
                    </tr>
                </thead>
                <tbody className="text-black text-sm font-semibold">
                    {currentItems.length > 0 ? (
                        currentItems.map((item, index) => (
                            <tr key={item.productId} className="border-b border-gray-200 hover:bg-gray-100">
                                <td className="py-3 px-6 text-black">{index + 1 + indexOfFirstItem}</td>
                                <td className="py-3 px-6 text-left text-black">{item.productName}</td>
                                <td className="py-3 px-6 text-black">{item.quantity.toFixed(2)}</td>
                                <td className="py-3 px-6 text-black">{item.unit}</td>
                                <td className="py-3 px-6 text-black">₹ {item.totalPrice.toFixed(2)}</td>
                            </tr>
                        ))
                    ) : (
                        <tr className="border-b border-gray-200">
                            <td colSpan="5" className="py-3 px-6 text-center text-red-600 bg-yellow-200">
                                {apiMessage || 'No sales data available for the selected filters.'}
                            </td>
                        </tr>
                    )}
                </tbody>

                <tfoot>
                    <tr className="bg-gray-100 font-bold text-black">
                        <td colSpan="4" className="py-3 px-6 text-right">Total Price:</td>
                        <td className="py-3 px-6">₹ {totalSalesPrice.toFixed(2)}</td>
                    </tr>
                </tfoot>
            </table>

            <div className="flex justify-between items-center mt-6">
                <span className="text-gray-700">
                    Page {currentPage} of {totalPages}
                </span>
                <div>
                    <button
                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                        className="bg-blue-500 text-white px-4 py-2 rounded-l hover:bg-blue-600 disabled:bg-gray-300 transition-all duration-200 ease-in-out"
                    >
                        Previous
                    </button>
                    <button
                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                        disabled={currentPage === totalPages}
                        className="bg-blue-500 text-white px-4 py-2 rounded-r hover:bg-blue-600 disabled:bg-gray-300 transition-all duration-200 ease-in-out"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SalesStatus;