import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import filter from "../Assets/tableicon/filter.png";

const StockAlert = () => {
  const [currentPageStockAlerts, setCurrentPageStockAlerts] = useState(1);
  const [warehouses, setWarehouses] = useState([]);
  const [error, setError] = useState(null);
  const [selectedWarehouseStockAlerts, setSelectedWarehouseStockAlerts] =
    useState(null);
  const [loading, setLoading] = useState(true);
  const [lowQuantityProducts, setLowQuantityProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const recordsPerPage = 7;
  const [showFilters, setShowFilters] = useState(false);

  const handleWarehouseStockAlerts = (e) => {
    const warehouseId =
      e.target.value === "All Warehouse" ? null : e.target.value;
    setSelectedWarehouseStockAlerts(warehouseId);
    setCurrentPageStockAlerts(1);
  };

  const handleStartDateChange = (date) => setStartDate(date);
  const handleEndDateChange = (date) => setEndDate(date);

  // Function to reset the date inputs and show all products
  const handleResetFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setCurrentPageStockAlerts(1);

    // Only update lowQuantityProducts if dates are selected
    if (startDate || endDate) {
      setLowQuantityProducts(
        filteredProducts.filter((product) => product.quantity <= 25)
      );
    } else {
      // If no dates are selected, keep the original filtered products
      setLowQuantityProducts((prevProducts) =>
        prevProducts.filter((product) => product.quantity <= 25)
      );
    }
  };

  // Fetch Warehouse Data
  const fetchWarehouses = async () => {
    try {
      const token = localStorage.getItem("token");
      const warehouseResponse = await fetch(
        "https://pos.farm2bag.com/api/v1/warehouse",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!warehouseResponse.ok) {
        throw new Error(`HTTP error! status: ${warehouseResponse.status}`);
      }
      const warehouseData = await warehouseResponse.json();
      if (warehouseData && Array.isArray(warehouseData.data)) {
        setWarehouses(warehouseData.data);
      } else {
        throw new Error("Unexpected warehouse data format");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  // Fetch Stock Data
  const fetchStockData = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found");
      }

      const stockAlert = selectedWarehouseStockAlerts
        ? `?warehouseId=${selectedWarehouseStockAlerts}`
        : "";
      const response = await fetch(
        `https://pos.farm2bag.com/api/v1/stocks${stockAlert}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const res = await response.json();
      if (res && Array.isArray(res.data)) {
        let updatedStockData = res.data.map((item) => ({
          ...item,
          products: item.products.map((product) => ({
            ...product,
            warehouse: warehouses.find(
              (stockWarehouse) => stockWarehouse.id === item.wareHouseId
            )?.name,
            wareHouseId: item.wareHouseId,
          })),
        }));

        let allProducts = updatedStockData.map((item) => item.products).flat();
        setFilteredProducts(allProducts);
        setLowQuantityProducts(
          allProducts.filter((product) => product.quantity <= 25)
        );
      } else {
        throw new Error("Data format is not an array");
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWarehouses();
  }, []);

  useEffect(() => {
    fetchStockData();
  }, [selectedWarehouseStockAlerts]);

  useEffect(() => {
    const filterByDateRange = (products) => {
      if (!startDate || !endDate) return products;

      const start = startDate;
      const end = endDate;

      return products.filter((product) => {
        const expiryDate = new Date(product.exp_Date);
        return expiryDate >= start && expiryDate <= end;
      });
    };

    if (selectedWarehouseStockAlerts === null) {
      setLowQuantityProducts(
        filterByDateRange(
          filteredProducts.filter((product) => product.quantity <= 25)
        )
      );
    } else {
      setLowQuantityProducts(
        filterByDateRange(
          filteredProducts.filter(
            (product) =>
              product.quantity <= 25 &&
              product.wareHouseId === selectedWarehouseStockAlerts
          )
        )
      );
    }
  }, [selectedWarehouseStockAlerts, filteredProducts, startDate, endDate]);

  const paginateStockAlerts = (pageNumber) =>
    setCurrentPageStockAlerts(pageNumber);

  const sortedLowQuantityProducts = lowQuantityProducts
    .slice()
    .sort((a, b) => a.quantity - b.quantity);

  const indexOfLastStockAlerts = currentPageStockAlerts * recordsPerPage;
  const indexOfFirstStockAlerts = indexOfLastStockAlerts - recordsPerPage;
  const currentStockAlerts = sortedLowQuantityProducts.slice(
    indexOfFirstStockAlerts,
    indexOfLastStockAlerts
  );
  const totalPagesStockAlerts = Math.ceil(
    sortedLowQuantityProducts.length / recordsPerPage
  );

  return (
    <div className="w-9/12 rounded-lg p-3 ml-72 mt-10">
      <div className="flex justify-between items-center mb-4">
        <h1 className="font-extrabold text-3xl text-left text-gray-500 mt-8">
          Inventory Alert
        </h1>
        <div
          className="flex items-center mt-12 px-2 rounded-lg"
          style={{ backgroundColor: "#fcec00" }}
        >
          {showFilters && (
            <div className="flex">
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
                dateFormat="dd/MM/yyyy"
                placeholderText="Start Date"
                className="mr-2 p-2 border rounded-lg bg-gray-200"
              />
              <DatePicker
                selected={endDate}
                onChange={handleEndDateChange}
                dateFormat="dd/MM/yyyy"
                placeholderText="End Date"
                className="p-2 border rounded-lg bg-gray-200"
              />
              <button
                onClick={handleResetFilters}
                className="mx-2 p-2 text-black rounded-lg"
                style={{ backgroundColor: "#ff8a05" }}
              >
                Reset
              </button>
            </div>
          )}
          <div className="p-2 flex rounded-lg">
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="flex items-center  mr-4 p-2  text-black border rounded-lg bg-gray-200   "
            >
              <img src={filter} alt="Filter" className="w-5 h-5 mr-2" />
              {showFilters ? "Hide Filters" : "Show Filters"}
            </button>
            <select
              className="py-2 border w-40 rounded-lg bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="All Warehouse"
              aria-label="Select Warehouse"
              onChange={handleWarehouseStockAlerts}
              style={{ backgroundColor: "#05ff2f" }}
            >
              <option>All Warehouse</option>
              {warehouses.map((warehouse) => (
                <option key={warehouse.id} value={warehouse.id}>
                  {warehouse.name}
                </option>
              ))}
            </select>
            
          </div>
        </div>
      </div>

      <div className="border-t border-gray-500"></div>

      <h2 className="mt-4 mb-3 text-left text-slate-500">
        Low Quantity Inventory
      </h2>

      {currentStockAlerts.length === 0 && (
        <div className="text-center py-4 text-gray-700">
          No Low Quantity found for the Specified Warehouse
        </div>
      )}
      {currentStockAlerts.length > 0 && (
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-blue-500 text-white">
            <tr>
              <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">
                Product Name
              </th>
              <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">
                Quantity
              </th>
              <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">
                Manufacture Date
              </th>
              <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">
                Expiry Date
              </th>
            </tr>
          </thead>
          <tbody>
            {currentStockAlerts.map((product) => {
              const currentDate = new Date();
              const expDate = new Date(product.exp_Date);

              // Calculate the difference in days
              const diffInDays = Math.ceil(
                (expDate - currentDate) / (1000 * 60 * 60 * 24)
              );

              // Debug: Log the difference in days for this product
              console.log(product.name, diffInDays);
              // Determine background color based on the days difference
              let backgroundColor = "";
              // Determine background color based on the days difference
              if (diffInDays <= 7) {
                backgroundColor = "bg-red-500 text-white"; // Red background for products expiring within 7 days
                console.log(
                  `Expiring soon (within 7 days): ${product.name} - ${diffInDays} days left`
                );
              } else if (diffInDays > 7 && diffInDays <= 14) {
                backgroundColor = "bg-yellow-300"; // Yellow background for products expiring within 7-14 days
                console.log(
                  `Expiring soon (within 7-14 days): ${product.name} - ${diffInDays} days left`
                );
              }

              return (
                <tr key={product.id} className="border-b">
                  <td className="py-2 px-4 text-left">{product.name}</td>
                  <td className="py-2 px-4 text-left">
                    {product.quantity}{" "}
                    <span className="bg-yellow-400 px-2 rounded-md ">
                      {product.unitSymbol}
                    </span>
                  </td>
                  <td className="py-2 px-4 text-left">{product.maf_Date}</td>
                  <td className="py-2 px-4 text-left">
                    <span className={`px-1 rounded ${backgroundColor}`}>
                      {product.exp_Date}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {totalPagesStockAlerts > 1 && (
        <div className="flex justify-between items-center mt-4">
          <div className="w-full flex justify-center">
            <span className="text-center">
              Page {currentPageStockAlerts} of {totalPagesStockAlerts}
            </span>
          </div>
          <div className="flex">
            <button
              disabled={currentPageStockAlerts === 1}
              onClick={() => paginateStockAlerts(currentPageStockAlerts - 1)}
              className="p-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400"
            >
              Previous
            </button>
            <button
              disabled={currentPageStockAlerts === totalPagesStockAlerts}
              onClick={() => paginateStockAlerts(currentPageStockAlerts + 1)}
              className="ml-2 p-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400"
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default StockAlert;
