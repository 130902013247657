import React, { useState, useEffect } from 'react';

const SearchQuery = () => {
  const [productName, setProductName] = useState('');
  const [warehouseName, setWarehouseName] = useState('f2b-omr');
  const [stockData, setStockData] = useState(null);

  const handleInputChange = (event) => {
    setProductName(event.target.value);
  };

  const handleWarehouseChange = (event) => {
    setWarehouseName(event.target.value);
  };

  useEffect(() => {
    if (productName) {
      const fetchStockData = async () => {
        const url = `https://pos.farm2bag.com/api/v1/stocks/search?warehouseName=${warehouseName}&productName=${productName}`;
        const token = localStorage.getItem('token');

        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          setStockData(data.data); 
        } catch (error) {
          console.error('There has been a problem with your fetch operation:', error);
        }
      };

      fetchStockData();
    }
  }, [productName, warehouseName]); 

  return (
    <div className='bg-gray-100 ml-64 mt-20 p-12 h-screen'>
      <div className='flex'>
        <div className='flex items-center mr-6'>
          <label htmlFor="warehouseName" className=" text-sm font-medium text-gray-700">Warehouse Name:</label>
          <select
            id="warehouseName"
            value={warehouseName}
            onChange={handleWarehouseChange}
            className="mt-1 block w-40 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="f2b-cbe">f2b-cbe</option>
            <option value="f2b-omr">f2b-omr</option>
          </select>
        </div>
        <div className='flex items-center'>
          <label htmlFor="productName" className=" text-sm font-medium text-gray-700">Product Name:</label>
          <input
            id="productName"
            type="text"
            value={productName}
            onChange={handleInputChange}
            className="mt-1  w-80 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
      </div>
      <div className="mt-8">
        {stockData && stockData.length > 0 && (
          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr>
                <th className="px-4 py-2 border-b-2 border-gray-300 text-left">Warehouse Name</th>
                <th className="px-4 py-2 border-b-2 border-gray-300 text-left">Product Code</th>
                <th className="px-4 py-2 border-b-2 border-gray-300 text-left">Category Name</th>
                <th className="px-4 py-2 border-b-2 border-gray-300 text-left">Product Name</th>
                <th className="px-4 py-2 border-b-2 border-gray-300 text-left">Unit Symbol</th>
              </tr>
            </thead>
            <tbody>
              {stockData.map((item, index) => (
                item.products.map((product, productIndex) => (
                  <tr key={`${index}-${productIndex}`}>
                    <td className="px-4 py-2 border-b border-gray-300">{item.warehouseName}</td>
                    <td className="px-4 py-2 border-b border-gray-300">{product.productsCode}</td>
                    <td className="px-4 py-2 border-b border-gray-300">{product.categoryName}</td>
                    <td className="px-4 py-2 border-b border-gray-300">{product.name}</td>
                    <td className="px-4 py-2 border-b border-gray-300">{product.unitSymbol}</td>
                  </tr>
                ))
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default SearchQuery;