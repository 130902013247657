import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const EditExpensePopup = ({ expense, expenseCategories, onClose, onUpdate }) => {
    const [updateExpense, setUpdateExpense] = useState({
        date: '',
        expenseCategoryId: '',
        amount: '',
        description: '',
        warehouseId: '', // Add warehouseId to the state
    });

    const [warehouses, setWarehouses] = useState([]); // State to store warehouses

    useEffect(() => {
        const fetchWarehouses = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.error('No token found in local storage');
                    return;
                }

                const response = await fetch('https://pos.farm2bag.com/api/v1/warehouse', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch warehouses');
                }

                const data = await response.json();
                setWarehouses(data.data); // Ensure to set only the data array

            } catch (error) {
                console.error('Error fetching warehouses:', error);
                toast.error('Error fetching warehouses: ' + error.message);
            }
        };

        fetchWarehouses();
    }, []);

    useEffect(() => {
        if (expense) {
            setUpdateExpense({
                date: expense.date,
                expenseCategoryId: expense.expenseCategoryId,
                amount: expense.amount,
                description: expense.description,
                warehouseId: expense.warehouseId || '', // Set warehouseId if present
            });
        }
    }, [expense]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUpdateExpense(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleUpdateExpense = async () => {
        try {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');

            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const { date, expenseCategoryId, amount, description, warehouseId } = updateExpense;

            // Validate amount should not be negative
            if (!date || !expenseCategoryId || amount < 0 || !description || !warehouseId) {
                toast.error('Please fill in all fields correctly and ensure amount is not negative');
                return;
            }

            const id = expense.id;

            const response = await fetch(`https://pos.farm2bag.com/api/v1/expense/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    date,
                    expenseCategoryId,
                    amount,
                    description,
                    warehouseId, // Include warehouseId in the payload
                    modifiedBy: role,
                })
            });

            if (!response.ok) {
                throw new Error('Failed to update expense');
            }

            console.log('Expense updated successfully');
            onClose();
            onUpdate();
            toast.success('Expense updated successfully');

        } catch (error) {
            console.error('Error updating expense:', error);
            toast.error('Error updating expense');
        }
    };

    return (
        <div className="fixed inset-0 flex items-center bg-gray-500 bg-opacity-50 justify-center z-50">
            <div className="bg-yellow-400 rounded-lg p-6 shadow-lg w-full md:w-3/4 lg:w-1/2">
                <h2 className="text-xl font-bold mb-4">Edit Expense</h2>
                <form className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="mb-4">
                        <label htmlFor="date" className="block text-gray-700 font-medium mb-2">Date</label>
                        <input type="date" id="date" name="date" value={updateExpense.date} onChange={handleInputChange} className="border border-gray-300 rounded-md p-2 w-full" />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="warehouseId" className="block text-gray-700 font-medium mb-2">Warehouse</label>
                        <select id="warehouseId" name="warehouseId" value={updateExpense.warehouseId} onChange={handleInputChange} className="border border-gray-300 rounded-md p-2 w-full">
                            <option value="">Select Warehouse</option>
                            {warehouses.map(warehouse => (
                                <option key={warehouse.id} value={warehouse.id}>
                                    {warehouse.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="expenseCategoryId" className="block text-gray-700 font-medium mb-2">Expense Category</label>
                        <select id="expenseCategoryId" name="expenseCategoryId" value={updateExpense.expenseCategoryId} onChange={handleInputChange} className="border border-gray-300 rounded-md p-2 w-full">
                            <option value="">Select Category</option>
                            {expenseCategories.map(category => (
                                <option key={category.id} value={category.id}>{category.expenseCategoryName}</option>
                            ))}
                        </select>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="amount" className="block text-gray-700 font-medium mb-2">Amount</label>
                        <input type="number" id="amount" name="amount" value={updateExpense.amount} onChange={handleInputChange} className="border border-gray-300 rounded-md p-2 w-full" />
                    </div>
                </form>
                <div className="mb-4">
                    <label htmlFor="description" className="block text-gray-700 font-medium mb-2">Description</label>
                    <input type="text" id="description" name="description" value={updateExpense.description} onChange={handleInputChange} className="border border-gray-300 rounded-md p-2 w-full" />
                </div>
                <div className="flex justify-between mt-4">
                    <button type="button" onClick={onClose} className="mr-4 px-4 py-2 bg-red-500 text-white rounded-lg float-left">Cancel</button>
                    <button type="button" onClick={handleUpdateExpense} className="px-4 py-2 bg-green-500 text-white rounded-lg">Save</button>
                </div>
            </div>
        </div>
    );
};

export default EditExpensePopup;
