import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoMdTrash } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import filter from "../Assets/tableicon/filter.png";
import Searchicon from "../Assets/tableicon/Searchicon.png";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import CustomerLogo from "../Assets/SideIcon/Customer_icon.png";
import { Link } from 'react-router-dom';
import CustomerAnalytics from './CustomerAnalytics';


const Customer = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [isCreatePopupOpen, setCreatePopupOpen] = useState(false);
    const [isEditPopupOpen, setEditPopupOpen] = useState(false);
    const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
    const [newCustomerName, setNewCustomerName] = useState('');
    const [newPinCode, setNewPinCode] = useState('');
    const [updateCustomerName, setUpdateCustomerName] = useState('');
    const [updatePinCode, setUpdatePinCode] = useState('');
    const [updateCustomerContact, setUpdateCustomerContact] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortColumn, setSortColumn] = useState('name');
    const [nameError, setNameError] = useState('');
    const [pinError, setPinError] = useState('');
    const [phoneError, setPhoneError] = useState("");


    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (isCreatePopupOpen) {
            handleGetLocation('new');
        }
    }, [isCreatePopupOpen]);


    const handleGetLocation = async (type) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(async (position) => {
                const { latitude, longitude } = position.coords;
                const pinCode = await fetchPinCode(latitude, longitude);
                if (type === 'new') {
                    setNewPinCode(pinCode);
                }
            });
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    };

    const fetchPinCode = async (lat, lng) => {
        try {
            const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDrxIN5pCE5TTrjdLWMq7VlMCxsj8FGD6k`
            );
            const data = await response.json();
            if (data.results && data.results.length > 0) {
                const addressComponents = data.results[0].address_components;
                const pinCodeComponent = addressComponents.find((component) =>
                    component.types.includes('postal_code')
                );
                return pinCodeComponent ? pinCodeComponent.long_name : '';
            }
        } catch (error) {
            console.error('Error fetching pin code:', error);
        }
        return '';
    };


    const fetchData = async () => {
        try {
            const token = localStorage.getItem('token');

            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const response = await fetch('https://pos.farm2bag.com/api/v1/customers', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseData = await response.json();

            if (Array.isArray(responseData.data)) {
                setData(responseData.data);
            } else {
                console.error('Invalid response format - result is not an array:', responseData);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Sorting logic
    const sortedData = [...data].sort((a, b) => {
        if (data.length === 1) {
            return a[sortColumn][0].localeCompare(b[sortColumn][0]);
        } else {
            if (sortOrder === 'asc') {
                return a[sortColumn].localeCompare(b[sortColumn]);
            } else {
                return b[sortColumn].localeCompare(a[sortColumn]);
            }
        }
    });


    const handleSort = (column) => {
        setSortColumn(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = sortedData
        .slice(indexOfFirstItem, indexOfLastItem)
        .filter(item =>
            item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.pincode.includes(searchQuery) ||
            item.contactNumber.includes(searchQuery)
        );

    const handleEdit = (customer) => {
        setSelectedCustomer(customer);
        setUpdateCustomerName(customer.name);
        setUpdatePinCode(customer.pincode);
        setUpdateCustomerContact(customer.contactNumber);
        setEditPopupOpen(true);
    };

    const handleDelete = (customer) => {
        setSelectedCustomer(customer);
        setDeletePopupOpen(true);
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleItemsPerPageChange = (e) => {
        setCurrentPage(1);
        setItemsPerPage(parseInt(e.target.value));
    };

    const handleInputChange = (value, type) => {
        let error = '';
        switch (type) {
            case 'name':
                if (!value.trim()) {
                    error = 'Please enter a customer name.';
                }
                setNewCustomerName(value);
                setNameError(error);
                break;
            case 'pincode':
                if (!value.trim()) {
                    error = 'Please enter PinCode.';
                } else if (!/^\d{6}$/.test(value.trim())) {
                    error = 'PinCode should be exactly 6 digits.';
                }
                setNewPinCode(value.trim().substring(0, 6)); // Restrict input to maximum 6 digits
                setPinError(error);
                break;
            case 'phone':
                if (!value.trim()) {
                    error = 'Please enter a phone number.';
                }
                setPhoneNumber(value);
                setPhoneError(error);
                break;
            default:
                break;
        }
    };


    const handleCreateCustomer = async () => {
        // Check if there are any validation errors before making the API request
        if (!newCustomerName.trim() || !newPinCode.trim() || !phoneNumber.trim()) {
            toast.error('Please fill in all fields');
            return;
        }
        try {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');

            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const response = await fetch('https://pos.farm2bag.com/api/v1/customers', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: newCustomerName,
                    pincode: newPinCode,
                    contactNumber: phoneNumber,
                    createdBy: role
                })
            });

            if (!response.ok) {
                throw new Error('Failed to create customer');
            }

            setCreatePopupOpen(false);
            setNewCustomerName('');
            setNewPinCode('');
            setPhoneNumber('');

            // After successfully creating the customer, refresh the data
            fetchData();

            toast.success('Customer created successfully');

        } catch (error) {
            console.error('Error creating customer:', error);
            // Notify error
            toast.error('Error creating customer');
        }
    };


    const handleUpdateCustomer = async () => {
        try {

            // Rest of the code remains the same
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');
            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const id = selectedCustomer.id;

            const response = await fetch(`https://pos.farm2bag.com/api/v1/customers/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: updateCustomerName,
                    pincode: updatePinCode,
                    contactNumber: updateCustomerContact,
                    modifiedBy: role
                })
            });

            if (!response.ok) {
                throw new Error('Failed to update customer');
            }

            console.log('Customer updated successfully');
            setEditPopupOpen(false);
            setUpdateCustomerName('');
            setUpdatePinCode('');
            setUpdateCustomerContact('');

            // After successfully updating the customer, you might want to refresh the data
            fetchData();

            toast.success('Customer updated successfully');

        } catch (error) {
            console.error('Error updating customer:', error);
            // Notify error
            toast.error('Error updating customer');
        }
    };

    const isFormValid = updateCustomerName && updatePinCode && updateCustomerContact;


    const handleDeleteCustomer = async () => {
        try {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');

            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const id = selectedCustomer.id;

            const response = await fetch(`https://pos.farm2bag.com/api/v1/customers/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ modifiedBy: role })
            });

            if (!response.ok) {
                throw new Error('Failed to delete customer');
            }

            console.log('Customer deleted successfully');
            setDeletePopupOpen(false);

            // After successfully deleting the customer, you might want to refresh the data
            fetchData();

            // Check if the current page still has items after deletion
            const currentPageItemsCount = currentItems.length;
            if (currentPageItemsCount === 1 && currentPage > 1) {
                // If the current page has only one item after deletion and it's not the first page,
                // paginate to the previous page
                paginate(currentPage - 1);
            }

            toast.success('Customer deleted successfully');

        } catch (error) {
            console.error('Error deleting customer:', error);
            // Notify error
            toast.error('Error deleting customer');
        }
    };

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase().trim();
        setSearchQuery(query);

        // Filter the data based on the search query for name only
        const filteredData = data.filter(item =>
            item.name.toLowerCase().includes(query)
        );

        // Sort the filtered data so that items whose names start with the query come first
        const sortedData = filteredData.sort((a, b) => {
            const lowerCaseA = a.name.toLowerCase();
            const lowerCaseB = b.name.toLowerCase();

            // Compare the lowercased names to maintain case-insensitivity
            if (lowerCaseA.startsWith(query) && !lowerCaseB.startsWith(query)) return -1;
            if (!lowerCaseA.startsWith(query) && lowerCaseB.startsWith(query)) return 1;

            // If neither starts with the query, sort alphabetically
            return lowerCaseA.localeCompare(lowerCaseB);
        });

        setData(sortedData);
    };

    useEffect(() => {
        if (searchQuery === '') {
            fetchData(); // Fetch initial data again
        }
    }, [searchQuery]);

    const handleFilterClick = () => {
        handleSort('name'); // Default sorting by name when filter button is clicked
    };

    return (
        <div className='bg-gray-100 ml-64 mt-20 p-12 h-screen'>
            <div className="container mx-auto">
                <div className="mb-10">
                    <ul className='flex' >
                        <li className='mt-1'><img src={CustomerLogo} className="h-8 w-15" alt="" /></li>
                        <li><h1 className="font-extrabold text-3xl text-left text-gray-500">Customers</h1></li>
                    </ul>
                    <div className="mt-2 border-t border-gray-500"></div>
                </div>
                <div className="flex items-center mb-5">
                    <div className="ml-0">
                        <form>
                            <div className="relative">
                                <input type="text" placeholder='search' value={searchQuery} onChange={handleSearch} className="border border-gray-200 px-4 py-2 pl-10 rounded-lg focus:outline-none focus:border-blue-500" />
                                <img src={Searchicon} alt="search" className="absolute left-3 top-3 w-4 h-4" />
                            </div>
                        </form>
                    </div>
                    <div className="flex ml-auto mr-0 items-center space-x-4">
                        <img src={filter} alt="filter" className="w-12 h-10 rounded-lg cursor-pointer" onClick={handleFilterClick} />
                        <button onClick={() => setCreatePopupOpen(true)} style={{ backgroundColor: 'rgba(66, 255, 97, 1)' }} className="w-48 h-10 font-medium text-sm rounded-lg">Create Customer</button>
                    </div>
                </div>
                <div className="rounded-lg overflow-hidden">
                    <table className="table-auto w-full border-collapse border-gray-500 text-center">
                        <thead>
                            <tr className="bg-gray-200">
                                <th className="px-4 pl-16 py-2 text-left" onClick={() => handleSort('name')}>Name</th>
                                <th className="px-4 py-2 text-center" onClick={() => handleSort('pincode')}>PinCode</th>
                                <th className="px-4 py-2 text-center" onClick={() => handleSort('contactNumber')}>Contact Number</th>
                                <th className="px-4 py-2 text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.length > 0 ? (
                                currentItems.map((item, index) => (
                                    <tr key={item.id} className={(index % 2 === 0) ? 'bg-white' : 'bg-gray-200'}>
                                        {/* Updated Link to include customerId */}
                                        <Link to={`/Customer-Analytics/${item.id}/${encodeURIComponent(item.name)}`} className="text-blue-500 hover:underline">
                                            {item.name}
                                        </Link>

                                        <td className="px-4 py-2 border-gray-500">{item.pincode}</td>
                                        <td className="px-4 py-2 border-gray-500">{item.contactNumber}</td>
                                        <td className="px-4 py-2 border-gray-500">
                                            <button onClick={() => handleEdit(item)} className="mr-2 font-bold rounded">
                                                <FaRegEdit size={22} className="inline-block mr-1 text-blue-500" />
                                            </button>
                                            <button onClick={() => handleDelete(item)} className="text-white font-bold rounded">
                                                <IoMdTrash size={30} style={{ color: "red" }} className="inline-block mr-1" />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4" className="text-center py-4">No results found.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                {isCreatePopupOpen && (
                    <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
                        <div className="bg-gray-200 w-2/6 h-3/3 p-6 rounded-lg ">
                            <h2 className="text-xl font-bold mb-4">Create New Customer</h2>
                            <div className="mb-4 border-t border-gray-500"></div>
                            <input type="text" value={newCustomerName} onChange={(e) => handleInputChange(e.target.value, 'name')} placeholder="Enter customer name"
                                className="border border-gray-200 px-4 py-2 rounded-lg mb-4 w-4/5" />
                            {nameError && <p className="text-red-500">{nameError}</p>}
                            <input type="number" value={newPinCode} onChange={(e) => handleInputChange(e.target.value, 'pincode')} placeholder="Enter PinCode" className="border border-gray-200 px-4 py-2 rounded-lg mb-4 w-4/5" maxLength={6} />
                            {pinError && <p className="text-red-500">{pinError}</p>}
                            <div className="mb-4">
                                <div className="w-2/5 ml-14">
                                    <PhoneInput country={"in"} value={phoneNumber} onChange={(value, country, e, formattedValue) => handleInputChange(formattedValue || '', 'phone')}
                                        inputClass="mt-1 border rounded-3xl w-36" placeholder="Enter phone number" />
                                    {phoneError && (
                                        <div className="text-red-500 mt-2">{phoneError}</div>
                                    )}
                                </div>
                            </div>
                            <button onClick={() => setCreatePopupOpen(false)} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg ml-2 float-left ml-12 mt-10 hover:bg-red-500">Cancel</button>
                            <button onClick={handleCreateCustomer} className="bg-blue-500 text-white px-4 py-2 rounded-lg float-right mr-11 mt-10 hover:bg-green-600">Create</button>
                        </div>
                    </div>
                )}



                {isEditPopupOpen && (
                    <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
                        <div className="bg-white p-8 rounded-lg shadow-lg w-11/12 max-w-md">
                            <h2 className="text-2xl font-bold mb-6 text-center">Edit Customer</h2>

                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">Customer Name</label>
                                    <input
                                        type="text"
                                        value={updateCustomerName}
                                        onChange={(e) => setUpdateCustomerName(e.target.value)}
                                        placeholder="Enter new customer name"
                                        className="border border-gray-300 px-4 py-2 rounded-lg w-full focus:outline-none focus:border-blue-500"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">Pincode</label>
                                    <input
                                        type="text"
                                        value={updatePinCode}
                                        onChange={(e) => setUpdatePinCode(e.target.value)}
                                        placeholder="Enter Pincode"
                                        className="border border-gray-300 px-4 py-2 rounded-lg w-full focus:outline-none focus:border-blue-500"
                                        maxLength={6}
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">Phone Number</label>
                                    <input
                                        type="text"
                                        value={updateCustomerContact}
                                        onChange={(e) => setUpdateCustomerContact(e.target.value)}
                                        placeholder="Enter phone number"
                                        className="border border-gray-300 px-4 py-2 rounded-lg w-full focus:outline-none focus:border-blue-500"
                                    />
                                </div>
                            </div>

                            <div className="flex justify-between mt-6">
                                <button
                                    onClick={() => setEditPopupOpen(false)}
                                    className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 focus:outline-none"
                                >
                                    Cancel
                                </button>

                                <button
                                    onClick={() => {
                                        let missingFields = [];
                                        if (!updateCustomerName) missingFields.push("Customer Name");
                                        if (!updatePinCode) missingFields.push("Pincode");
                                        if (!updateCustomerContact) missingFields.push("Phone Number");

                                        if (missingFields.length > 0) {
                                            toast.error(`Please enter: ${missingFields.join(", ")}`);
                                        } else {
                                            handleUpdateCustomer();
                                        }
                                    }}
                                    className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none"
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                )}


                {isDeletePopupOpen && (
                    <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
                        <div className="bg-white p-6 rounded-lg">
                            <h2 className="text-xl font-bold mb-4">Delete Customer</h2>
                            <p>Are you sure you want to delete the customer "{selectedCustomer.name}"?</p>
                            <button onClick={() => setDeletePopupOpen(false)} className="bg-blue-500 text-white px-4 py-2 rounded-lg float-right mr-11 mt-10 hover:bg-red-600">Cancel</button>
                            <button onClick={handleDeleteCustomer} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg ml-2 float-left ml-11 mt-10 hover:bg-green-500">Delete</button>
                        </div>
                    </div>
                )}

                <div className="flex justify-between items-center mt-5 pb-10">
                    <div>
                        <label htmlFor="itemsPerPage" className="mr-2">Items per page:</label>
                        <select id="itemsPerPage" value={itemsPerPage} onChange={handleItemsPerPageChange} className="border border-gray-300 rounded-lg px-2 py-1">
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                            <option value={20}>20</option>
                        </select>
                    </div>

                    <div className="text-gray-700">
                        {`Page ${currentPage} of ${Math.ceil(data.length / itemsPerPage)}`}
                    </div>

                    <div className="flex space-x-2">
                        <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} className={`px-3 py-1 rounded ${currentPage === 1 ? 'bg-gray-300 text-gray-400' : 'bg-blue-500 text-white'}`}>
                            Prev
                        </button>
                        <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(data.length / itemsPerPage)}
                            className={`px-3 py-1 rounded ${currentPage === Math.ceil(data.length / itemsPerPage) ? 'bg-gray-300 text-gray-400' : 'bg-blue-500 text-white'}`} >
                            Next
                        </button>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default Customer;