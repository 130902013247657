import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-zoom';
import { Button, Input, Select, DatePicker } from 'antd';
import dayjs from 'dayjs';
import "../scss/chart.scss";


const { Option } = Select;

const SalesInventoryChart = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [productsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [categories, setCategories] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [stockQuantities, setStockQuantities] = useState([]);
  const [soldQuantities, setSoldQuantities] = useState([]);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  // Function to truncate product name
  const truncateName = (name) => {
    const maxLength = 15; // Set the max length for display
    return name.length > maxLength ? name.slice(0, maxLength - 3) + '...' : name;
  };

  // Fetch categories from API
  useEffect(() => {
    const fetchCategories = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch('https://pos.farm2bag.com/api/v1/categories/getAll/master', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();

        if (response.ok) {
          setCategories(data.data);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  // Fetch warehouses from API
  useEffect(() => {
    const fetchWarehouses = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch('https://pos.farm2bag.com/api/v1/warehouse', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();

        if (response.ok) {
          setWarehouses(data.data);
          // Automatically select the first warehouse if available
          if (data.data.length > 0) {
            setSelectedWarehouseId(data.data[0].id); // Set the initial selected warehouse
          }
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error('Error fetching warehouses:', error);
      }
    };

    fetchWarehouses();
  }, []);

  // Fetch stock quantities based on selected warehouse and category
  useEffect(() => {
    const fetchStockQuantities = async () => {
      if (!selectedWarehouseId) return;

      const token = localStorage.getItem('token');
      try {
        const url = selectedCategory
          ? `https://pos.farm2bag.com/api/v1/stocks/status/${selectedWarehouseId}?productCategoryId=${selectedCategory}`
          : `https://pos.farm2bag.com/api/v1/stocks/status/${selectedWarehouseId}`;

        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();

        if (response.ok) {
          setStockQuantities(data.data.products);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error('Error fetching stock quantities:', error);
      }
    };

    fetchStockQuantities();
  }, [selectedWarehouseId, selectedCategory]);

  // Fetch sold quantities based on selected parameters
  useEffect(() => {
    const fetchSoldQuantities = async () => {
      const token = localStorage.getItem('token');
      try {
        const url = new URL('https://pos.farm2bag.com/api/v1/billingItems/status');

        if (selectedWarehouseId) url.searchParams.append('warehouseId', selectedWarehouseId);
        if (selectedCategory) url.searchParams.append('categoryId', selectedCategory);
        if (fromDate) url.searchParams.append('fromDate', dayjs(fromDate).format('YYYY-MM-DD'));
        if (toDate) url.searchParams.append('toDate', dayjs(toDate).format('YYYY-MM-DD'));

        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();

        if (response.ok) {
          setSoldQuantities(data.data);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error('Error fetching sold quantities:', error);
      }
    };

    fetchSoldQuantities();
  }, [selectedWarehouseId, selectedCategory, fromDate, toDate]);

  // Prepare data for the combined chart
  useEffect(() => {
    const prepareChartData = () => {
      const combinedData = [];
      const stockMap = stockQuantities.reduce((map, stock) => {
        map[stock.productId] = Math.max(stock.quantity, 0); // Ensure non-negative stock
        return map;
      }, {});

      const filteredSoldQuantities = soldQuantities.filter(sold =>
        sold.productName && sold.productName.toLowerCase().includes(searchTerm.toLowerCase())
      );

      filteredSoldQuantities.forEach(sold => {
        const stockQuantity = stockMap[sold.productId] || 0; // Get stock quantity or 0 if not found
        combinedData.push({
          productName: sold.productName,
          stockQuantity: stockQuantity,
          soldQuantity: sold.quantity,
        });
      });

      // Prepare data for chart, truncating names for the labels
      setChartData({
        labels: combinedData.map(item => truncateName(item.productName)), // Truncate product names for the chart
        datasets: [
          {
            label: 'Stock Quantity',
            data: combinedData.map(item => item.stockQuantity),
            backgroundColor: 'rgba(7, 245, 71, 0.6)',
          },
          {
            label: 'Sales Quantity',
            data: combinedData.map(item => item.soldQuantity),
            backgroundColor: 'rgba(3, 69, 252, 0.6)',
          },
        ],
      });
    };

    prepareChartData();
  }, [stockQuantities, soldQuantities, searchTerm]);

  // Paginate the chart data
  const totalPages = Math.ceil(chartData.labels.length / productsPerPage);
  const paginatedData = {
    labels: chartData.labels.slice(currentPage * productsPerPage, (currentPage + 1) * productsPerPage),
    datasets: chartData.datasets.map(dataset => ({
      ...dataset,
      data: dataset.data.slice(currentPage * productsPerPage, (currentPage + 1) * productsPerPage),
    })),
  };


  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: 'Product Name',
          font: {
            size: 14,
            weight: 'bold',
          },
        },
        ticks: {
          font: {
            size: 10,
          },
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Quantity',
          font: {
            size: 14,
            weight: 'bold',
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      zoom: {
        pan: { enabled: true, mode: 'x' },
        zoom: { enabled: true, mode: 'x' },
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            const index = tooltipItems[0].dataIndex;
            return paginatedData.labels[index] || '';
          },
          label: (tooltipItem) => {
            const datasetLabel = tooltipItem.dataset.label || '';
            const value = tooltipItem.raw || 0;
            return `${datasetLabel}: ${value}`;
          },
        },
      },
    },
    backgroundColor: 'white',
  };
  


  return (
    <div className='bg-white p-2 rounded-lg'>
      <h3 className="text-2xl font-extrabold text-gray-800 mb-4 text-center" style={{ fontFamily: 'Times New Roman, serif' }}> Real-Time Inventory & Sales Performance</h3>
      <div
        className='rounded-lg'
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          marginBottom: '10px',
          backgroundColor: '#0c1a5e',
          padding: '10px'
        }}
      >
        <Input
          placeholder="Search product..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{
            width: 200,
            marginRight: 10,
            backgroundColor: '#333',
            color: '#1cff03'
          }}
          className="input-placeholder" here
        />


        <Select
          placeholder="Select Category"
          style={{
            width: 200,
            marginRight: 10,
            backgroundColor: '#333',
            color: '#1cff03'
          }}
          onChange={(value) => {
            setSelectedCategory(value);
            setCurrentPage(0);
          }}
          dropdownStyle={{
            backgroundColor: '#000',
            color: '#1cff03',
          }}
        >
          {categories.map(category => (
            <Option key={category.id} value={category.id} style={{ color: '#1cff03' }}>
              {category.categoryName}
            </Option>
          ))}
        </Select>


        <Select
          value={selectedWarehouseId}
          onChange={(value) => { setSelectedWarehouseId(value); setCurrentPage(0); }}
          style={{
            width: 200,
            marginRight: 10,
            backgroundColor: '#333',
            color: '#1cff03'
          }}
          dropdownStyle={{
            backgroundColor: '#000',
            color: '#1cff03',
          }}
        >
          {warehouses.map(warehouse => (
            <Option key={warehouse.id} value={warehouse.id} style={{ color: '#1cff03' }}>
              {warehouse.name} - {warehouse.city}, {warehouse.country}
            </Option>
          ))}
        </Select>

        <DatePicker
          placeholder="From Date"
          onChange={(date) => setFromDate(date)}
          style={{
            marginRight: 10,
            backgroundColor: '#333',
            color: '#1cff03'
          }}
          className="input-placeholder"
        />

        <DatePicker
          placeholder="To Date"
          onChange={(date) => setToDate(date)}
          style={{
            marginRight: 10,
            backgroundColor: '#333',
            color: '#1cff03'
          }}
          className="input-placeholder"
        />

        <Button
          onClick={() => setCurrentPage(Math.max(currentPage - 1, 0))}
          disabled={currentPage === 0}
          style={{
            color: '#1cff03',
            backgroundColor: '#333'
          }}
        >
          Previous
        </Button>
        <Button
          onClick={() => setCurrentPage(Math.min(currentPage + 1, totalPages - 1))}
          disabled={currentPage >= totalPages - 1}
          style={{
            color: '#1cff03',
            backgroundColor: '#333'
          }}
        >
          Next
        </Button>
      </div>



      <div style={{ height: '400px', backgroundColor: 'white' }}>
        <Bar data={paginatedData} options={options} />
      </div>


      <p className='mt-5'>Page {currentPage + 1} of {totalPages}</p>
    </div>
  );
};  

export default SalesInventoryChart;
