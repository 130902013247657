import React, { useEffect, useState } from 'react';
import axios from 'axios';

function DatesOnDashBoard() {
  const [stockData, setStockData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchStocks = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://pos.farm2bag.com/api/v1/stocks', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.data.status === 200) {
          const allProducts = response.data.data.flatMap(stock => stock.products);
          setStockData(allProducts);
        } else {
          throw new Error('Failed to fetch stocks');
        }
      } catch (error) {
        console.error('Error fetching stock data:', error);
      }
    };

    fetchStocks();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch("https://pos.farm2bag.com/api/v1/categories", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        setCategories(result.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchWarehouses = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://pos.farm2bag.com/api/v1/warehouse', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.status === 200) {
          setWarehouses(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching warehouse data:', error);
      }
    };

    fetchWarehouses();
  }, []);


  useEffect(() => {

    const fetchStockData = async () => {
      if (!selectedWarehouse) return; // Check if warehouse is selected

      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`https://pos.farm2bag.com/api/v1/stocks/warehouse/${selectedWarehouse}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.status === 200) {
          const products = response.data.data.products || [];
          setStockData(products);
          setErrorMessage(""); // Clear any previous error messages
        } else {
          setErrorMessage(response.data.message); // Set error message
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setErrorMessage(error.response.data.message);
          setStockData([]);
        } else {

          console.error('Error fetching stock data:', error);
          setErrorMessage('An error occurred while fetching stock data.'); // Fallback error message
        }
      }
    };

    fetchStockData();
    setCurrentPage(1); // Reset to the first page when warehouse changes
  }, [selectedWarehouse]);


  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split('/').map(num => parseInt(num, 10));
    return new Date(year, month - 1, day);
  };

  const daysUntilExpiration = (expirationDate) => {
    const currentDate = new Date();
    const expDate = parseDate(expirationDate);
    return Math.ceil((expDate - currentDate) / (1000 * 60 * 60 * 24)); // Convert time difference from milliseconds to days
  };

  // Filter products based on expiration date and selected category
  const filteredProducts = stockData
    .filter(product => {
      const productExpDate = product.exp_Date ? parseDate(product.exp_Date) : null;

      if (!productExpDate) return false;

      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth();
      const daysToExp = daysUntilExpiration(product.exp_Date);

      const matchesCategory = selectedCategory ? product.productCategoryId === selectedCategory : true;

      return (
        productExpDate.getFullYear() === currentYear &&
        productExpDate.getMonth() === currentMonth &&
        daysToExp <= 10 &&
        matchesCategory
      );
    })
    .sort((a, b) => parseDate(b.exp_Date) - parseDate(a.exp_Date)); // Sort in descending order of expiration date

  useEffect(() => {
    setTotalItems(filteredProducts.length);
  }, [filteredProducts]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleItemsPerPageChange = (e) => {
    const value = e.target.value;
    setItemsPerPage(parseInt(value, 10));
    setCurrentPage(1);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value); // Set the selected category
    setCurrentPage(1); // Reset to the first page whenever the category changes
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <div className="container mx-auto">
      <div className="flex-1 bg-white shadow-lg rounded-lg p-4">
        <div className="flex justify-between items-center">
          <h1 className="text-xl font-semibold mb-2">Expiration Dates</h1>
          <div className="flex items-center">
            <label htmlFor="itemsPerPage" className="mr-2 text-gray-600">Items per page:</label>
            <select
              id="itemsPerPage"
              name="itemsPerPage"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              className="border rounded px-2 py-1 w-32 text-center"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
            </select>
          </div>
        </div>

        <div className="flex items-center mb-4">
          <label htmlFor="warehouseDropdown" className="mr-2 text-gray-600">Select Warehouse:</label>
          <select
            id="warehouseDropdown"
            value={selectedWarehouse}
            onChange={(e) => setSelectedWarehouse(e.target.value)} // Ensure this updates state
            className="border rounded px-2 py-1 bg-gray-200"
          >
            <option value="">Select a warehouse</option>
            {warehouses.map(warehouse => (
              <option key={warehouse.id} value={warehouse.id}>
                {warehouse.name}
              </option>
            ))}
          </select>

          <select
            className={`border p-1 ml-5 rounded-lg w-40 focus:outline-none ${selectedCategory ? "bg-green-500" : "bg-gray-200"
              }`} value={selectedCategory}
            onChange={handleCategoryChange} >
            <option value="">All Categories</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.categoryName}
              </option>
            ))}
          </select>
        </div>


        {errorMessage && (
          <div className="bg-red-200 text-red-800 p-2 rounded mb-4">
            {errorMessage} {/* Display error message */}
          </div>
        )}

        <table className="min-w-full leading-normal text-left">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-10 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Product Name
              </th>
              <th className="px-9 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider hidden">
                Category ID
              </th>
              <th className="px-9 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Quantity
              </th>
              <th className="px-8 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Expiration Date
              </th>
              <th className="px-8 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {currentProducts.length > 0 ? (
              currentProducts.map((product) => {
                const daysToExp = daysUntilExpiration(product.exp_Date);
                const isExpired = daysToExp < 0;

                return (
                  <tr key={product.productId}>
                    <td className="px-6 py-4 whitespace-nowrap text-left font-medium text-gray-900">
                      {product.name}
                    </td>
                    <td className="px-9 py-4 whitespace-nowrap text-left font-medium text-gray-900 hidden">
                      {product.productCategoryId}
                    </td>
                    <td className="px-9 py-4 whitespace-nowrap text-left font-medium text-gray-900">
                      {product.quantity}
                    </td>
                    <td className="px-8 py-4 whitespace-nowrap text-left text-gray-500">
                      {product.exp_Date}
                    </td>
                    <td className={`px-8 py-4 whitespace-nowrap text-left font-semibold ${isExpired ? 'text-red-500' : 'text-yellow-500'}`}>
                      {isExpired ? 'Expired' : `${daysToExp} days left`}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="5" className="px-6 py-4 text-center text-gray-500">
                  No products available
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="flex items-center mt-4 justify-center">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-3 py-1 border rounded-lg mr-4 ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-100'
              }`}
          >
            Previous
          </button>

          <span className="mx-2">Page {currentPage} of {totalPages}</span>

          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`px-3 py-1 border rounded-lg ml-4 ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-100'
              }`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default DatesOnDashBoard;