import React, { useState, useEffect } from 'react';
import TopFive from './TopFive';
import LeastFive from './LeastFive';
import MostSelling from './MostSelling';

const OrderSummaryOverview = () => {
  const [currentComponent, setCurrentComponent] = useState('TopFive');
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState('');
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchWarehouses = async () => {
      try {
        const response = await fetch('https://pos.farm2bag.com/api/v1/warehouse', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setWarehouses(data.data);
        } else {
          console.error('Failed to fetch warehouses');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchWarehouses();
  }, [token]);

  const toggleComponent = () => {
    if (currentComponent === 'TopFive') {
      setCurrentComponent('LeastFive');
    } else if (currentComponent === 'LeastFive') {
      setCurrentComponent('MostSelling');
    } else {
      setCurrentComponent('TopFive');
    }
  };

  const renderComponent = () => {
    switch (currentComponent) {
      case 'TopFive':
        return <TopFive warehouseId={selectedWarehouseId} />;
      case 'LeastFive':
        return <LeastFive warehouseId={selectedWarehouseId} />;
      case 'MostSelling':
        return <MostSelling warehouseId={selectedWarehouseId} />;
      default:
        return null;
    }
  };

  return (
    <div className="relative">

      <div className='flex justify-between'>
        <select
          className=" p-1 border rounded-lg w-44"
          value={selectedWarehouseId}
          onChange={(e) => setSelectedWarehouseId(e.target.value)}
          style={{
            background: selectedWarehouseId
              ? 'linear-gradient(to right, lightgreen, lightorange)'
              : 'white',
            color: selectedWarehouseId ? 'black' : 'black',
          }}
        >
          <option value="">Select Warehouse</option>
          {warehouses.map((warehouse) => (
            <option key={warehouse.id} value={warehouse.id}>
              {warehouse.name} - {warehouse.city}, {warehouse.country}
            </option>
          ))}
        </select>

        <button
          onClick={toggleComponent}
          className="bg-gradient-to-r from-purple-500 to-rose-500 px-2 h-8 rounded-full text-white font-medium text-md shadow-lg hover:shadow-md transform hover:scale-105 transition-all duration-300 focus:outline-none focus:ring-1 focus:ring-purple-600 focus:ring-offset-2 flex items-center space-x-2">
          <span>
            {currentComponent === 'TopFive'
              ? 'LeastFive'
              : currentComponent === 'LeastFive'
                ? 'MostSelling'
                : 'TopFive'}
          </span>
          <span
            className="transform transition-transform duration-300 ease-in-out group-hover:translate-x-1">
            ➔
          </span>
        </button>
      </div>

      {renderComponent()}

    </div>
  );
};

export default OrderSummaryOverview;