import React, { useState, useEffect } from 'react';
import { IoMdTrash } from 'react-icons/io';
import PaymentPopup from './PaymentPopup';
import { toast, ToastContainer } from 'react-toastify'

const CreateTransfer = ({ onClose, transfer }) => {
  const [fromWarehouse, setFromWarehouse] = useState(null);
  const [toWarehouse, setToWarehouse] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [warehouses, setWarehouses] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [checkedProducts, setCheckedProducts] = useState([]);
  const [isPaymentPopupOpen, setIsPaymentPopupOpen] = useState(false);
  const [invoiceFromWarehouseName, setInvoiceFromWarehouseName] = useState('');
  const [invoiceToWarehouseName, setInvoiceToWarehouseName] = useState('');
  const [stockLevels, setStockLevels] = useState({}); // New state to track stock levels
  useEffect(() => {
    if (fromWarehouse) {
      fetchWarehouseDetails(fromWarehouse, 'from');
    }
  }, [fromWarehouse]);


  useEffect(() => {
    const fetchWarehouses = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch('https://pos.farm2bag.com/api/v1/warehouse', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const result = await response.json();

        if (response.ok && Array.isArray(result.data)) {
          setWarehouses(result.data);
        } else {
          console.error('Unexpected response format:', result);
        }
      } catch (error) {
        console.error('Error fetching warehouse data:', error);
      }

    };

    fetchWarehouses();
  }, []);


  const fetchWarehouseDetails = async (warehouseId, type) => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`https://pos.farm2bag.com/api/v1/stocks/warehouse/${warehouseId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (response.ok && result.data) {
        if (type === 'from') {
          setProducts(result.data.products.map(product => ({
            ...product,
            quantity: 1 // Initialize quantity to 1 for each product
          })));
          // Update stock levels
          setStockLevels(result.data.products.reduce((acc, product) => {
            acc[product.productId] = product.quantity;
            return acc;
          }, {}));
        }
      } else {
        console.error('Unexpected response format:', result);
      }
    } catch (error) {
      console.error('Error fetching warehouse details:', error);
    }
  };

  const handleFromWarehouseChange = async (e) => {
    const selectedWarehouseId = e.target.value;
    setFromWarehouse(selectedWarehouseId);

    const selectedWarehouse = warehouses.find(warehouse => warehouse.id === selectedWarehouseId);
    if (selectedWarehouse) {
      setInvoiceFromWarehouseName(selectedWarehouse.name);
      setSelectedProducts([]);
      setCheckedProducts([]);
      await fetchWarehouseDetails(selectedWarehouseId, 'from');
    } else {
      setInvoiceFromWarehouseName('');
      setProducts([]);
      setSelectedProducts([]);
      setCheckedProducts([]);
    }
  };

  const handleToWarehouseChange = (e) => {
    const selectedWarehouseId = e.target.value;
    setToWarehouse(selectedWarehouseId);

    const selectedWarehouse = warehouses.find(warehouse => warehouse.id === selectedWarehouseId);
    if (selectedWarehouse) {
      setInvoiceToWarehouseName(selectedWarehouse.name);
    } else {
      setInvoiceToWarehouseName('');
    }
  };

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleProductSelect = (productId) => {
    const selectedProduct = products.find(product => product.productId === productId);
    if (checkedProducts.includes(productId)) {
      setCheckedProducts(checkedProducts.filter(id => id !== productId));
      setSelectedProducts(selectedProducts.filter(product => product.productId !== productId));
    } else {
      setCheckedProducts([...checkedProducts, productId]);
      setSelectedProducts([...selectedProducts, selectedProduct]);
    }
  };
  const handleProductDeselect = (productId) => {
    setSelectedProducts(prevSelected => prevSelected.filter(product => product.productId !== productId));
    setCheckedProducts(prevChecked => prevChecked.filter(id => id !== productId));
  };

  const handleIncrementQuantity = (productId) => {
    setSelectedProducts(prevSelected => {
      const product = prevSelected.find(product => product.productId === productId);
      if (product) {
        const newQuantity = product.quantity + 1;
        if (stockLevels[productId] >= newQuantity) {
          return prevSelected.map(p =>
            p.productId === productId ? { ...p, quantity: newQuantity } : p
          );
        } else {
          toast.warn('Quantity exceeds available stock');
          return prevSelected;
        }
      }
      return prevSelected;
    });
  };

  const handleDecrementQuantity = (productId) => {
    setSelectedProducts(prevSelected =>
      prevSelected.map(product =>
        product.productId === productId && product.quantity > 1 ? { ...product, quantity: product.quantity - 1 } : product
      )
    );
    // setCheckedProducts(prevChecked => prevChecked.filter(id => id !== productId));
  };

  const filteredProducts = products.filter(product => {
    const name = product.name ? product.name.toLowerCase() : '';
    const code = product.productsCode ? product.productsCode.toLowerCase() : '';
    const query = searchQuery.toLowerCase();
    return name.includes(query) || code.includes(query);
  });
  

  const calculateSubtotal = (product) => {
    return product.quantity * product.price; // Assuming product has quantity and price attributes
  };

  const handleProceedToPayment = () => {
    if (!fromWarehouse || !toWarehouse) {
      toast.warn('Please select both From and To warehouses before proceeding.', {
        position: 'top-right',
        style: {
          fontSize: '16px', 
          padding: '10px 20px', 
          borderRadius: '4px', 
          marginTop: '100px'
        },
      });
      
      return;
    }
    setIsPaymentPopupOpen(true);
  };

  const handleClosePaymentPopup = () => {
    setIsPaymentPopupOpen(false);
  };

  const fetchData = () => {
    transfer();
  }

  const handleCloseCreatePopup = () => {
    onClose();
  }


  const invoiceData = {
    fromWarehouseName: invoiceFromWarehouseName,
    toWarehouseName: invoiceToWarehouseName,
    products: selectedProducts.map(product => ({
      name: product.name,
      quantity: product.quantity,
      unit: product.unitSymbol,
      price: product.price,
      subtotal: calculateSubtotal(product)
    })),
  };

  return (
    <div className="bg-gray-300 ml-64 mt-64 p-12 overflow-y-auto h-screen w-4/5">
      <div>
      <ToastContainer />
        <div className=" mt-2 px-6">
          <h1 className="font-extrabold text-3xl text-gray-500 mt-2">Create Transfer</h1>
          <div className="mt-4 border-t border-gray-500"></div>
        </div>

        <div className="grid grid-cols-3 gap-6 p-6">
          <div className="mt-4 flex">
            <label className="text-gray-700 text-lg font-bold mb-2">
              From:
              <select
                value={fromWarehouse}
                onChange={handleFromWarehouseChange}
                className="w-54 mt-1 p-2 border border-gray-300 font-semibold rounded-md"
              >
                <option value="">Select a warehouse</option>
                {warehouses.map((warehouse) => (
                  <option key={warehouse.id} value={warehouse.id}>
                    {warehouse.name}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="mt-4">
            <label className="block text-gray-700 text-lg font-bold mb-2">
              To:
              <select
                value={toWarehouse}
                onChange={handleToWarehouseChange}
                className="w-54 mt-1 p-2 border border-gray-300 font-semibold rounded-md"
              >
                <option value="">Select a warehouse</option>
                {warehouses
                  .filter((warehouse) => warehouse.id !== fromWarehouse)
                  .map((warehouse) => (
                    <option key={warehouse.id} value={warehouse.id}>
                      {warehouse.name}
                    </option>
                  ))}
              </select>
            </label>
          </div>
          <div className="mt-4">
            <label className="block text-gray-700 font-medium mb-2">
              <input
                type="text"
                value={searchQuery}
                placeholder="🔍 Search the product"
                onChange={handleSearchQueryChange}
                className="w-54 mt-1 p-2 border border-gray-300 uppercase rounded-md"
              />
            </label>
          </div>
        </div>


        {fromWarehouse && (
          <div className="mt-4 bg-white p-4 rounded-md w-full">
            <label className="block text-xl font-bold text-gray-700 mb-8">Stock Products</label>
            <div className="overflow-y-auto max-h-40 ml-6">
              {filteredProducts.length > 0 ? (
                <ul className="grid grid-cols-3 gap-4">
                  {filteredProducts.map((product) => (
                    <li key={product.productId} className="text-gray-700 font-semibold flex items-center">
                      <input
                        type="checkbox"
                        id={product.productId}
                        className="mr-2 ml-4"
                        onChange={() => handleProductSelect(product.productId)}
                        checked={checkedProducts.includes(product.productId)}
                      />
                      <label htmlFor={product.productId}>{product.name}({product.productsCode})</label>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-black mr-32 text-center">No Products Found</p>
              )}
            </div>
          </div>
        )}

        {selectedProducts.length > 0 && (
          <div className="mt-8 bg-gray-300 p-4 rounded-md w-full">
            <div className="overflow-y-auto max-h-40 rounded">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-100">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-center text-sm font-medium text-gray-500">
                      Product Name
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                      Quantity
                    </th>
                    <th scope="col" className="px-6 py-3 text-center text-sm font-medium text-gray-500">
                      Unit
                    </th>
                    <th scope="col" className="px-6 py-3 text-center text-sm font-medium text-gray-500">
                      Price
                    </th>
                    <th scope="col" className="px-6 py-3 text-center text-sm font-medium text-gray-500">
                      Subtotal
                    </th>
                    <th scope="col" className="px-6 py-3 text-center text-sm font-medium text-gray-500">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {selectedProducts.map((product) => (
                    <tr key={product.productId}>
                      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">{product.name}</td>
                      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                        <div className="flex items-center">
                          <button
                            onMouseDown={() => handleDecrementQuantity(product.productId)}
                            className="px-2 py-1 bg-gray-200 rounded-md"
                          >
                            -
                          </button>
                          <span className="px-3">{product.quantity}</span>
                          <button
                            onMouseDown={() => handleIncrementQuantity(product.productId)}
                            className="px-2 py-1 bg-gray-200 rounded-md"
                          >
                            +
                          </button>
                        </div>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">{product.unitSymbol}</td>
                      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">₹{product.price}</td>
                      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">₹{calculateSubtotal(product)}</td>
                      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                        <button onClick={() => handleProductDeselect(product.productId)}>
                          <IoMdTrash size={20} className="text-red-500" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>


        )}

        {isPaymentPopupOpen && (
          <PaymentPopup
            invoiceData={invoiceData}
            selectedProducts={selectedProducts}
            fromWarehouse={fromWarehouse}
            toWarehouse={toWarehouse}
            onClose={handleClosePaymentPopup}
            createPop={handleCloseCreatePopup}
            transfer={fetchData}
          />
        )}
      </div>
      <div className="flex justify-end mt-5">
        {selectedProducts.length > 0 && (
          <div className="">
            <button
              // disabled={isProceedButtonDisabled}
              onClick={handleProceedToPayment}
              className="px-4 py-2 bg-green-500 font-semibold text-white rounded-md hover:bg-green-300 focus:outline-none"
            >
              Proceed
            </button>
          </div>
        )}

        <button
          onClick={onClose}
          className="px-4 bg-red-500 text-white font-semibold rounded-md hover:bg-red-300 focus:outline-none ml-8 h-10"
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default CreateTransfer;