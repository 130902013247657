import React, { useEffect, useState, useCallback, useRef } from "react";
import debounce from 'lodash.debounce'; // You can install lodash.debounce via npm
import Addcustomer from "../Assets/Billingpage/Addcustomer.png";
import Box1 from "../Assets/Billingpage/menu.png";
import Box2 from "../Assets/Billingpage/house.png";
import Box3 from "../Assets/Billingpage/arrows.png";
import Box4 from "../Assets/Billingpage/calculator.png";
import Box5 from "../Assets/Billingpage/performance.png";
import giftBox from "../Assets/Billingpage/gift-box.png";
import { FaRegEdit } from "react-icons/fa";
import { IoMdTrash } from "react-icons/io";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import InvoicePopup from "../components/InvoicePopup";

import { useNavigate } from "react-router-dom"; // Import useNavigate
import Calculator from "../components/Calculator"; // Update the path to the actual path of your Calculator component
import "../scss/Billing.scss";
import { Oval } from 'react-loader-spinner';
import Dropdown from "../components/Dropdown";
import axios from "axios";


import NetworkSlowPopup from "../components/NetworkSlowPopup";

const SCAN_COMPLETE_TIMEOUT = 100; // Time in milliseconds to wait before considering input as complete

const Billing = () => {
  const [warehouses, setWarehouses] = useState([]);
  const [selectedCustomerName, setSelectedCustomerName] = useState("");
  const [selectedWarehouseName, setSelectedWarehouseName] = useState("");
  const [customers, setCustomers] = useState([]);
  const [searchQuery1, setSearchQuery1] = useState("");
  const [invoiceData, setInvoiceData] = useState(null);
  const [isInvoicePopupOpen, setIsInvoicePopupOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [newCustomerName, setNewCustomerName] = useState("");
  const [newPinCode, setNewPinCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isEditPopupOpen, setEditPopupOpen] = useState(false);
  const [updateStock, setUpdateStock] = useState("");
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [isPaymentPopupOpen, setPaymentPopupOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [products, setProducts] = useState([]);
  const [loadingProductsLocal, setLoadingProductsLocal] = useState(false);
  const [receivedAmount, setReceivedAmount] = useState("");
  const [paymentMode, setPaymentMode] = useState("digital");
  const [paymentStatus, setPaymentStatus] = useState("Paid");
  const [brands, setBrands] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isHoldPopupRefOpen, setIsHoldPopupRefOpen] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState("");
  const [holdProducts, setHoldProducts] = useState([]);
  const [isHoldPopUpOpen, setHoldPopUpScreen] = useState(false);
  const [holdData, setHoldData] = useState([]);
  const [holdCount, setHoldCount] = useState(0);

  //  calc
  const [showCalculator, setShowCalculator] = useState(false);

  // Register-------------------------------------
  const [cashInHand, setCashInHand] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showRegisterDetailsModal, setShowRegisterDetailsModal] =
    useState(false);
  const [showCloseRegisterModal, setShowCloseRegisterModal] = useState(false);
  const [registerDetails, setRegisterDetails] = useState(null);
  const [note, setNote] = useState("");

  // const [isMyPopupOpen, setIsMyPopupOpen] = useState(true);
  const [errorMessage, setErrorMessage] = useState({ name: "", message: "" });
  const [invalidRows, setInvalidRows] = useState(new Set());

  const [isBox5PopupOpen, setIsBox5PopupOpen] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  const [customerNumber, setCustomerNumber] = useState("");



  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);


  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const [discount, setDiscount] = useState();
  const [deliveryCharge, setDeliveryCharge] = useState();

  const [selectedCount, setSelectedCount] = useState(0);

  const [loading, setLoading] = useState(false);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [categoryDropdownVisible, setCategoryDropdownVisible] = useState(false);

  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const [showDropdown, setShowDropdown] = useState(true); // Dropdown visibility state
  const [activeIndex, setActiveIndex] = useState(-1); // To keep track of the highlighted index
  const inputTimeoutRef = useRef(null);

  const [gst, setGst] = useState();

  const [focusedInput, setFocusedInput] = useState(0);
  const [focusedQuantityIndex, setFocusedQuantityIndex] = useState(null);
  const searchInputRef = useRef(null);
  const quantityInputRefs = useRef([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const inputRef = useRef(null);

  const paymentTypeRef = useRef(null);
  const paymentStatusRef = useRef(null);
  const receivedAmountRef = useRef(null);
  const payBillButtonRef = useRef(null);
  const holdButtonRef = useRef(null);
  const resetButtonRef = useRef(null);
  const okButtonRef = useRef(null);
  const cancelButtonRef = useRef(null);

  const [focusCategory, setFocusCategory] = useState(true);
  const selectRefCategory = useRef(null);
  const selectRefProduct = useRef(null);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedGiftPrice, setSelectedGiftPrice] = useState(0);

  const [isNetworkSlowPopupOpen, setIsNetworkSlowPopupOpen] = useState(false);

  const [isPriorityPopupOpen, setIsPriorityPopupOpen] = useState(false);
  const [checkedCategories, setCheckedCategories] = useState(() => {
    const savedCategories = localStorage.getItem('PriorityCategories');
    return savedCategories ? JSON.parse(savedCategories) : [];
  });


  const fetchWithTimeout = async (url, options, timeout = 10000) => {
    const controller = new AbortController();
    const { signal } = controller;

    const fetchPromise = fetch(url, { ...options, signal });

    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => {
        controller.abort();
        reject(new Error('Request timed out'));
      }, timeout)
    );

    return Promise.race([fetchPromise, timeoutPromise]);
  };
  useEffect(() => {
    setSelectedCount(selectedProducts.length);
  }, [selectedProducts]);


  useEffect(() => {
    if (showCloseRegisterModal) {
      setCashInHand(0);
    }
  }, [showCloseRegisterModal]);


  useEffect(() => {
    fetchHoldData();
  }, []);
  const handleBox1Click = () => {
    setHoldPopUpScreen(true);
  };

  // Fetch warehouses
  useEffect(() => {
    const fetchWarehouses = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch("https://pos.farm2bag.com/api/v1/warehouse", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        setWarehouses(result.data);

        // Set the first warehouse as the default selected value and trigger change
        if (result.data && result.data.length > 0) {
          const firstWarehouseId = result.data[0].id;
          setSelectedWarehouse(firstWarehouseId);
          setSelectedWarehouseName(result.data[0].name);

          // Call handleWarehouseChange with a simulated event object
          handleWarehouseChange({ target: { value: firstWarehouseId } });
        }
      } catch (error) {
        console.error("Error fetching warehouses:", error);
      }
    };




    const fetchCustomers = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch("https://pos.farm2bag.com/api/v1/customers", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        setCustomers(result.data);
      } catch (error) {
        console.error("Error fetching customers:", error);
      }
    };

    fetchWarehouses();
    fetchCustomers();
  }, []);

  // Fetch categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch("https://pos.farm2bag.com/api/v1/categories", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        setCategories(result.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);


  // Handle product selection

  const handleProductSelect = (product) => {
    if (product.totalQuantity <= 0) {  // Change this line
      toast.warning("Stock quantity not available for this product", {  // Change toast message
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: "#d8fc0d",
          color: "black",
        },
      });
      // Add the product to selectedProducts even if out of stock
      const existingProductIndex = selectedProducts.findIndex(
        (p) => p.id === product.id
      );
      if (existingProductIndex !== -1) {
        const updatedProducts = [...selectedProducts];
        updatedProducts[existingProductIndex].subtotal =
          updatedProducts[existingProductIndex].quantity *
          updatedProducts[existingProductIndex].price;
        setSelectedProducts(updatedProducts);
      } else {
        const selectedProduct = {
          ...product,
          quantity: 1,
          subtotal: product.price,
        };
        setSelectedProducts([...selectedProducts, selectedProduct]);
      }
      return; // Exit the function here to avoid further processing
    }

    // Original functionality for in-stock products
    setSearchQuery('');
    const existingProductIndex = selectedProducts.findIndex(
      (p) => p.id === product.id
    );
    if (existingProductIndex !== -1) {
      const updatedProducts = [...selectedProducts];
      updatedProducts[existingProductIndex].subtotal =
        updatedProducts[existingProductIndex].quantity *
        updatedProducts[existingProductIndex].price;
      setSelectedProducts(updatedProducts);
    } else {
      const selectedProduct = {
        ...product,
        quantity: 1,
        subtotal: product.price,
      };
      setSelectedProducts([...selectedProducts, selectedProduct]);
    }
  };



  const fetchStock = async () => {
    if (!selectedWarehouse) return;

    try {
      setLoadingProductsLocal(true);
      const token = localStorage.getItem("token");

      // Retrieve the priorityCategorys from local storage
      const PriorityCategories = JSON.parse(localStorage.getItem("PriorityCategories")) || [];

      console.log("PriorityCategories from local storage:", PriorityCategories); // Debugging log

      // Construct the query string for productCategoryId if priorityCategorys exists
      const productCategoryId = PriorityCategories.length > 0
        ? `?${PriorityCategories.map(categoryId => `productCategoryId=${encodeURIComponent(categoryId)}`).join('&')}`
        : '';

      console.log("Constructed query string:", productCategoryId); // Debugging log

      const url = `https://pos.farm2bag.com/api/v1/stocks/billingPageWarehouse/${selectedWarehouse}${productCategoryId}`;
      console.log("Request URL:", url); // Debugging log

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      const warehouseStock = result.data;

      if (!warehouseStock || !warehouseStock.products) {
        throw new Error("Warehouse stock not found or no products available");
      }

      const mergedProducts = warehouseStock.products.map(product => ({
        id: product.productId,
        name: product.name,
        productsCode: product.productsCode,
        image: product.image,
        categoryId: product.productCategoryId,
        brandId: product.brandId,
        totalQuantity: product.quantity,
        quantity: product.quantity,
        unit: product.unitSymbol,
        unitId: product.unitId,
        price: product.price,
        subtotal: product.quantity * product.price,
      }));

      setProducts(mergedProducts);
    } catch (error) {
      console.error("Error fetching stock:", error);
      // Optionally display an error message to the user
    } finally {
      setLoadingProductsLocal(false);
    }
  };




  useEffect(() => {
    fetchStock();
  }, [selectedWarehouse, invoiceData, isPriorityPopupOpen]);



  const handleKeyDown = (e) => {
    if (dropdownVisible && filteredProducts.length > 0) {
      if (e.key === 'ArrowDown') {
        // Move down in the dropdown
        setHighlightedIndex(prevIndex =>
          prevIndex < filteredProducts.length - 1 ? prevIndex + 1 : 0
        );
      } else if (e.key === 'ArrowUp') {
        // Move up in the dropdown
        setHighlightedIndex(prevIndex =>
          prevIndex > 0 ? prevIndex - 1 : filteredProducts.length - 1
        );
      } else if (e.key === 'Enter') {
        // Select the highlighted item
        if (highlightedIndex >= 0) {
          const selectedProduct = filteredProducts[highlightedIndex];
          handleSelectProductFromDropdown(selectedProduct);
        }
      }
    }
  };

  const handleSelectProductFromDropdown = (product) => {

    // Handle the product selection logic
    setSelectedCategory(product.productsCode); // Example usage
    handleSearchInputChange({ target: { value: product.productsCode } });
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };





  const handleSearchInputChange = (e) => {
    const query = e.target.value;

    // Clear the previous timeout if it exists
    if (inputTimeoutRef.current) {
      clearTimeout(inputTimeoutRef.current);
    }

    // Set the new search query
    setSearchQuery(query);

    // Set a timeout to process the input after the user has stopped typing
    inputTimeoutRef.current = setTimeout(() => {
      // Check if the input matches a product code exactly
      const matchingProduct = products.find(product => product.productsCode === query);

      if (matchingProduct) {
        handleExactMatch(matchingProduct);
        setDropdownVisible(false);
      } else if (query) {
        // If not an exact match, show partial matches in the dropdown
        const lowercasedQuery = query.toLowerCase();
        const filtered = products.filter(product =>
          product.name?.toLowerCase().includes(lowercasedQuery) ||
          product.productsCode?.toLowerCase().includes(lowercasedQuery)
        );
        setFilteredProducts(filtered);
        setDropdownVisible(filtered.length > 0);
        setHighlightedIndex(-1);
        setShowDropdown(false);


      } else {
        setFilteredProducts([]);
        setDropdownVisible(false);
        setShowDropdown(false);

      }
    }, SCAN_COMPLETE_TIMEOUT);
  };


  const handleExactMatch = (matchingProduct) => {
    if (matchingProduct.totalQuantity <= 0) {  // Change this line
      toast.warning("Stock quantity not available for this product", {
        position: "bottom-right", // Changed position to bottom-right
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: "#d8fc0d",
          color: "black",
        },
      });

      const existingProductIndex = selectedProducts.findIndex(p => p.productsCode === matchingProduct.productsCode);
      if (existingProductIndex === -1) {
        const productWithDefaultQuantity = { ...matchingProduct, quantity: 1 };
        setSelectedProducts(prevSelected => [...prevSelected, productWithDefaultQuantity]);
      }
      setSearchQuery('');
      setDropdownVisible(false);
      return; // Exit the function to avoid further processing
    }

    if (matchingProduct.totalQuantity > 0) {
      const existingProductIndex = selectedProducts.findIndex(p => p.productsCode === matchingProduct.productsCode);

      if (existingProductIndex !== -1) {
        setSelectedProducts(prevSelected => {
          const updatedProducts = [...prevSelected];
          const currentQuantity = updatedProducts[existingProductIndex].quantity;

          if (currentQuantity < matchingProduct.totalQuantity) {
            updatedProducts[existingProductIndex].quantity += 1;
          } else {
            toast.error('Cannot increase quantity beyond available stock');
          }

          return updatedProducts;
        });
      } else {
        const productWithDefaultQuantity = { ...matchingProduct, quantity: 1 };
        setSelectedProducts(prevSelected => [...prevSelected, productWithDefaultQuantity]);
      }

      setSearchQuery('');
      setDropdownVisible(false);
    } else {
      toast.error('Stock quantity not available');
    }
  };



  const handleSearchChange = useCallback(debounce((query) => {
    const lowercasedQuery = query.toLowerCase();
    const filtered = products.filter(product =>
    (product.name?.toLowerCase().includes(lowercasedQuery) ||
      product.productsCode?.toLowerCase().includes(lowercasedQuery))
    );
    setFilteredProducts(filtered);
    setOffset(0); // Reset offset when search query changes
    setHasMore(true); // Reset hasMore when search query changes
  }, 300), [products]);

  // Effect for handling search query change
  useEffect(() => {
    handleSearchChange(searchQuery);
  }, [searchQuery, handleSearchChange]);


  useEffect(() => {
    const query = searchQuery.toLowerCase();
    const filtered = products.filter(product => {
      return (
        (selectedCategoryId === "" || product.categoryId === selectedCategoryId) &&
        (product.name?.toLowerCase().includes(query) ||
          product.productsCode?.toLowerCase().includes(query))
      );
    });
    setFilteredProducts(filtered);
  }, [searchQuery, selectedCategoryId, products]);




  const dropDownClose = () => {
    setCategoryDropdownVisible(false);
  }

  const handleEditClick = (product) => {
    setSelectedProductId(product.id);
    setUpdateStock(product.price);
    setEditPopupOpen(true);
  };

  const fetchPinCode = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDrxIN5pCE5TTrjdLWMq7VlMCxsj8FGD6k`
      );
      const data = await response.json();

      if (data.results && data.results.length > 0) {
        const addressComponents = data.results[0].address_components;



        const pinCodeComponent = addressComponents.find((component) =>
          component.types.includes("postal_code")
        );

        if (pinCodeComponent) {
          return pinCodeComponent.long_name;
        } else {
          console.warn("Postal code not found in address components");
          // Handle the case where postal code is not available
          return ""; // Or set a default value or prompt the user for input
        }
      } else {
        console.warn("No results found for the given coordinates");
        return ""; // Or handle this case appropriately in your application
      }
    } catch (error) {
      console.error("Error fetching pin code:", error);
      return ""; // Handle the error case
    }
  };


  const handleCreateCustomer = async (pinCode) => {
    const pincodeToUse = pinCode.trim() === "" ? "0" : pinCode.trim();

    try {
      const token = localStorage.getItem("token");
      const role = localStorage.getItem("role");

      if (!token) {
        console.error("No token found in local storage");
        return;
      }

      // Validate phone number (digits only, exact length, example: 10 digits for typical phone number)
      const phoneRegex = /^[0-9]{10}$/;
      if (!phoneRegex.test(phoneNumber)) {
        toast.error("Invalid phone number. Please enter a valid 10-digit phone number.");
        return;
      }

      // Validate customer name (alphabets only)
      const nameRegex = /^[a-zA-Z\s]+$/;
      if (!nameRegex.test(newCustomerName)) {
        toast.error("Invalid name. Please enter alphabetic characters only.");
        return;
      }

      const lowercaseName = newCustomerName.toLowerCase();

      const response = await fetch("https://pos.farm2bag.com/api/v1/customers", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: lowercaseName,
          pincode: pincodeToUse,
          contactNumber: phoneNumber,
          createdBy: role,
        }),
      });

      setPhoneNumber(phoneNumber);

      if (!response.ok) {
        throw new Error("Failed to create customer");
      }

      const data = await response.json();

      if (data.status === 201) {
        setIsPopupOpen(false);
        setNewCustomerName("");
        setPhoneNumber();
        setSearchQuery1(data.data.name + "(" + data.data.contactNumber + ")");
        setCustomers((prevCustomers) => [...prevCustomers, data.data]);
        setSelectedCustomer(data.data.id);
        setShowDropdown(false);
        toast.success("Customer created successfully!");
      } else {
        throw new Error(data.message || "Failed to create customer");
      }
    } catch (error) {
      console.error("Error creating customer:", error);
      toast.error("Error creating customer");
    }
  };

  const handleGetLocation = async (type) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        const pinCode = await fetchPinCode(latitude, longitude);
        console.log("PinCode", pinCode);

        if (type === "new") {
          setNewPinCode(pinCode); // You can keep this if you still need to store the pin code in state
          handleCreateCustomer(pinCode); // Pass the pin code to the customer creation function
        }
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };



  const handleUpdateStock = async () => {
    try {
      if (updateStock === "" || updateStock === null) {
        toast.error("Please Enter The Price Value", { autoClose: 1500 });
        return;
      }

      if (updateStock < 0) {
        toast.error("Do not enter the negative value", { autoClose: 1000 });
        return;
      }

      const warehouseId = selectedWarehouse;
      const productId = selectedProductId;

      const token = localStorage.getItem("token");

      // Making PUT request to update the product price
      const response = await fetch(
        `https://pos.farm2bag.com/api/v1/stocks/price/${warehouseId}/${productId}?price=${updateStock}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({}),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Parsing the response JSON to get updated product details
      const updatedProduct = await response.json();

      toast.success("Price💰 Updated successfully");

      // Update products state
      setProducts((prevProducts) => {
        const newProducts = prevProducts.map((product) =>
          product.id === selectedProductId
            ? {
              ...product,
              price: updateStock,
              subtotal: product.quantity * updateStock,
            }
            : product
        );

        return newProducts;
      });

      // Update selectedProducts state
      setSelectedProducts((prevSelectedProducts) => {
        const newSelectedProducts = prevSelectedProducts.map((product) =>
          product.id === selectedProductId
            ? {
              ...product,
              price: updateStock,
              subtotal: product.quantity * updateStock,
            }
            : product
        );

        return newSelectedProducts;
      });

      setEditPopupOpen(false);
    } catch (error) {
      console.error("Error updating stock:", error);
    }
  };



  const calculateOverallSubTotal = () => {
    return selectedProducts.reduce((sum, product) => sum + product.price * product.quantity, 0);
  };

  const overallSubTotal = calculateOverallSubTotal();
  const parsedDiscount = (parseFloat(discount) || 0) / 100;
  const parsedGst = (parseFloat(gst) || 0) / 100;
  const parsedDeliveryCharge = parseFloat(deliveryCharge) || 0;

  // Calculate subtotal after applying discount
  const subtotalAfterDiscount = overallSubTotal - overallSubTotal * parsedDiscount;

  // Calculate GST based on the discounted subtotal
  const gstAmount = subtotalAfterDiscount * parsedGst;

  // Calculate final total by adding delivery charge to the subtotal and GST
  const finalTotal = subtotalAfterDiscount + gstAmount + parsedDeliveryCharge;


  // Calculate the change to return
  const changeReturn = Math.max(
    (receivedAmount !== "" ? Number(receivedAmount) : 0) - finalTotal,
    0
  );

  const handleCloseNetworkSlowPopup = () => {
    setIsNetworkSlowPopupOpen(false);
  };


  const handlePayBillClick = async () => {
    if (!selectedCustomer.trim()) {
      toast.error("Please select a customer");
      return;
    }

    setLoading(true);

    const role = localStorage.getItem("role");
    const ProductStatus = "Purchase";
    const productNames = selectedProducts.map((product) => product.name);

    // Prepare billing data
    const billingData = {
      customerId: selectedCustomer,
      warehouseId: selectedWarehouse,
      gst: gst || 0,
      shippingCharges: deliveryCharge || 0,
      discount: discount || 0,
      totalPrice: finalTotal.toFixed(2),
      paymentMode,
      status: paymentStatus,
      createdBy: role,
      billingItems: [
        {
          warehouseId: selectedWarehouse,
          createdBy: role,
          product: selectedProducts.map((product) => ({
            productId: product.id,
            categoryId: product.categoryId,
            productName: product.name,
            unit: product.unit,
            unitId: product.unitId,
            quantity: product.quantity,
            totalPrice: parseFloat((product.price * product.quantity).toFixed(2)),
            productStatus: ProductStatus,
          })),
        },
      ],
      productNames: productNames,
    };

    if (!finalTotal || isNaN(finalTotal) || finalTotal === null || finalTotal === "") {
      toast.error("Error: Sale amount is invalid. Please check the final total.");
      setLoading(false);
      return;
    }

    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 10000); // 10 seconds timeout

    try {
      const token = localStorage.getItem("token");

      // Call billing API with a timeout
      const billingResponse = await fetch("https://pos.farm2bag.com/api/v1/billing", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(billingData),
        signal: controller.signal,
      });

      clearTimeout(timeoutId); // Clear timeout if request completes in time

      if (billingResponse.ok) {
        const billingResult = await billingResponse.json();

        setInvoiceData({
          warehouseName: selectedWarehouseName,
          customerName: selectedCustomerName || searchQuery1,
          contactNumber: phoneNumber,
          productDetails: selectedProducts,
          overallSubTotal,
          deliveryCharge,
          discount,
          totalPrice: finalTotal,
          gst: gst,
          gstAmount: gstAmount,
          paymentMode: paymentMode,
          selectedGiftPrice: selectedGiftPrice,
          responseData: billingResult,
        });

        setIsInvoicePopupOpen(true);
        toast.success("Bill created successfully");

        setSelectedProducts([]);
        setReceivedAmount("");
        setSelectedGiftPrice('');
        setSearchQuery1("");
        setSelectedCustomer("");
        setSelectedCustomerName("");
        setPaymentMode("digital");
        setDeliveryCharge("");
        setDiscount("");
        setGst("");
        setFocusedInput(0);
        setPhoneNumber("");

        if (billingData.status === "Paid") {
          await postRegisterSale(token, finalTotal);
        }
      } else {
        setIsInvoicePopupOpen(false);

        throw new Error(`HTTP error! status: ${billingResponse.status}`);
      }
    } catch (error) {
      console.error("Error during billing or sale recording:", error);

      if (error.name === "AbortError") {

        setIsNetworkSlowPopupOpen(true);

        setSelectedProducts([]);
        setReceivedAmount("");
        setSelectedGiftPrice('');
        setSearchQuery1("");
        setSelectedCustomer("");
        setSelectedCustomerName("");
        setPaymentMode("digital");
        setDeliveryCharge("");
        setDiscount("");
        setGst("");
        setFocusedInput(0);
        setPhoneNumber("");

        toast.error("Network timeout: The request took too long.");
        // Fallback: Attempt to post sale to register even if billing times out
        const token = localStorage.getItem("token");
        await postRegisterSale(token, finalTotal);
      } else {
        toast.error("Error during billing or sale recording.");
      }
    } finally {
      setLoading(false);
    }
  };





  // Function to post sale to register
  const postRegisterSale = async (token, saleAmount) => {
    try {
      const response = await axios.post(
        `https://pos.farm2bag.com/api/v1/registers/record-sale?paymentMode=${paymentMode}&saleAmount=${saleAmount}&warehouseId=${selectedWarehouse}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setRegisterDetails(response.data);
      toast.success("Sale recorded successfully");
    } catch (error) {
      console.error("Error recording sale:", error);
      toast.error("Error recording sale. Please try again.");
    }
  };


  // Handle checkbox selection/deselection
  const handleCheckboxChangeCategory = (categoryId) => {
    setCheckedCategories((prevCheckedCategories) => {
      let updatedCheckedCategories;
      if (prevCheckedCategories.includes(categoryId)) {
        updatedCheckedCategories = prevCheckedCategories.filter((id) => id !== categoryId);
      } else {
        updatedCheckedCategories = [...prevCheckedCategories, categoryId];
      }

      // Save the updated checked categories to localStorage under the new key "PriorityCategories"
      localStorage.setItem('PriorityCategories', JSON.stringify(updatedCheckedCategories));
      return updatedCheckedCategories;
    });
  };

  useEffect(() => {
    // Sync the checked categories with localStorage whenever they change
    localStorage.setItem('PriorityCategories', JSON.stringify(checkedCategories));
  }, [checkedCategories]);

  // Function to toggle the popup visibility
  const togglePopup = () => {
    setIsPriorityPopupOpen(!isPriorityPopupOpen);
  };

  const handleCategorySelect = (categoryId) => {
    if (categoryId === "") {
      // Hide the dropdown if no category is selected
      setCategoryDropdownVisible(false);
      setSelectedCategoryId(categoryId);
      setFilteredProducts([]);
    } else {
      setSelectedCategoryId(categoryId);
      setCategoryDropdownVisible(true);
      setOffset(0); // Reset offset when category changes
      setHasMore(true); // Reset hasMore when category changes
    }
  };


  const handleCategoryChange = (e) => {
    const value = e.target.value;
    setSelectedCategory(value);
    handleCategorySelect(value); // Assuming handleCategorySelect is defined elsewhere
  };

  // Handler for product change
  const handleSelectChange = (e) => {
    const value = e.target.value;
    setSelectedProduct(value);

    // Assuming you want to find the product by its code
    const selectedProductData = filteredProducts.find(product => product.productsCode === value);
    if (selectedProductData) {
      handleSearchInputChange({ target: { value: selectedProductData.productsCode } }); // Assuming handleSearchInputChange is defined elsewhere

    }
    selectRefCategory.current.focus();
    // Resetting selections
    setSelectedCategory('');
    setSelectedProduct('');
  };

  // Hotkey handler
  const handleKeyDowns = (e) => {
    if (e.key === 'F1') {
      e.preventDefault(); // Prevent default F1 help action
      if (focusCategory) {
        selectRefCategory.current.focus(); // Focus the category select
      } else {
        selectRefProduct.current.focus(); // Focus the product select
      }
      setFocusCategory(!focusCategory); // Toggle focus state
    }
  };

  // Attach event listener on mount and clean up on unmount
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDowns);
    return () => {
      window.removeEventListener('keydown', handleKeyDowns);
    };
  }, [focusCategory]);





  const isPayBillEnabled =
    !loading &&
    selectedCustomer.trim() &&
    selectedProducts.length > 0 &&
    registerDetails &&
    (paymentMode !== 'cash' || receivedAmount);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Tab') {
        event.preventDefault(); // Prevent default tab behavior

        if (isPayBillEnabled || (paymentMode === 'cash')) {
          // Cycle through Payment Type, Payment Status, Received Amount, and Pay Bill button
          if (focusedInput === 0) {
            // Move focus to Payment Type input
            setFocusedInput(2);
          } else if (focusedInput === 2) {
            // Move focus to Payment Status input
            setFocusedInput(3);
          } else if (focusedInput === 3) {
            // Move focus to Received Amount input
            setFocusedInput(4);
          } else if (focusedInput === 4) {
            // Move focus to Pay Bill button
            setFocusedInput(5);
          } else if (focusedInput === 5) {
            // Move focus to Pay Bill button
            setFocusedInput(6);
          } else if (focusedInput === 6) {
            // Move focus to Pay Bill button
            setFocusedInput(7);
          } else if (focusedInput === 7) {
            // Return to Search input
            setFocusedInput(0);
          }
        } else {
          // Normal behavior: switch between search input and quantity inputs
          if (focusedInput === 0) {
            // Move focus from search input to first quantity input
            setFocusedInput(1);
            setFocusedQuantityIndex(0); // Focus on first quantity input
          } else if (focusedInput === 1) {
            // Go back to search input
            setFocusedInput(0);
          }
        }
      } else if (event.key === 'ArrowDown' && focusedInput === 1) {
        // Navigate to the next quantity input
        event.preventDefault();
        setFocusedQuantityIndex((prevIndex) => {
          const nextIndex = prevIndex + 1;
          return nextIndex < selectedProducts.length ? nextIndex : prevIndex;
        });
      } else if (event.key === 'ArrowUp' && focusedInput === 1) {
        // Navigate to the previous quantity input
        event.preventDefault();
        setFocusedQuantityIndex((prevIndex) => {
          const nextIndex = prevIndex - 1;
          return nextIndex >= 0 ? nextIndex : prevIndex;
        });
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [focusedInput, focusedQuantityIndex, isPayBillEnabled, selectedProducts, paymentMode]);

  // Effect to handle focusing the correct input based on state changes
  useEffect(() => {
    if (focusedInput === 0 && searchInputRef.current) {
      // Focus the search input
      searchInputRef.current.focus();
    } else if (focusedInput === 1 && focusedQuantityIndex !== null && quantityInputRefs.current[focusedQuantityIndex]) {
      // Focus the selected quantity input
      quantityInputRefs.current[focusedQuantityIndex].focus();
    } else if (focusedInput === 2 && paymentTypeRef.current) {
      // Focus the Payment Type input
      paymentTypeRef.current.focus();
    } else if (focusedInput === 3 && paymentStatusRef.current) {
      // Focus the Payment Status input
      paymentStatusRef.current.focus();
    } else if (focusedInput === 4 && receivedAmountRef.current) {
      // Focus the Received Amount input
      receivedAmountRef.current.focus();
    } else if (focusedInput === 5 && holdButtonRef.current) {
      // Focus the Pay Bill button
      holdButtonRef.current.focus();
    } else if (focusedInput === 6 && resetButtonRef.current) {
      // Focus the Pay Bill button
      resetButtonRef.current.focus();
    } else if (focusedInput === 7 && payBillButtonRef.current) {
      // Focus the Pay Bill button
      payBillButtonRef.current.focus();
    }
  }, [focusedInput, focusedQuantityIndex]);



  const handleCheckboxChange = (index) => {
    setSelectedProducts((prevProducts) => {
      const updatedProducts = [...prevProducts];
      const actualIndex = prevProducts.length - 1 - index;

      // Get the current product
      const currentProduct = updatedProducts[actualIndex];

      // Toggle price: if the checkbox is unchecked, restore the original price; if checked, set price to 0
      if (currentProduct.price === 0) {
        // Checkbox was unchecked, restore original price
        updatedProducts[actualIndex] = {
          ...currentProduct,
          price: originalPriceRef.current[actualIndex],  // Restore original price
          subtotal: currentProduct.quantity * originalPriceRef.current[actualIndex], // Recalculate subtotal
        };
      } else {
        // Checkbox was checked, set price to 0
        originalPriceRef.current[actualIndex] = currentProduct.price;  // Store the current price before setting to 0
        updatedProducts[actualIndex] = {
          ...currentProduct,
          price: 0,  // Set price to 0
          subtotal: 0,  // Set subtotal to 0
        };
      }

      // Calculate the total price of selected (checked) products
      const totalGiftPrice = updatedProducts
        .filter(product => product.price === 0) // Get all products with price 0
        .reduce((total, product) => total + (originalPriceRef.current[updatedProducts.indexOf(product)] || 0) * product.quantity, 0); // Sum the original prices multiplied by their quantities

      setSelectedGiftPrice(totalGiftPrice); // Update the selectedGiftPrice with the calculated total

      return updatedProducts;
    });
  };



  // Ref to store original prices of the products
  const originalPriceRef = useRef(selectedProducts.map(product => product.price));



  const handleQuantityChange = (index, value) => {
    setSelectedProducts((prevProducts) => {
      const updatedProducts = [...prevProducts];
      const actualIndex = prevProducts.length - 1 - index;

      const newValue = parseFloat(value);
      const isStartingWithZero = value.startsWith("0") && value.length > 1 && !value.startsWith("0.");

      if (isNaN(newValue) || newValue < 0.001 || isStartingWithZero) {
        // Set error message if the value is below 0.001 or starts with zero but isn't a valid decimal like 0.1
        setErrorMessage({
          name: updatedProducts[actualIndex].name,
          message: "Quantity must be at least 0.001",
        });
        updatedProducts[actualIndex] = {
          ...updatedProducts[actualIndex],
          quantity: value, // Allow user to see what they're typing
          subtotal: 0, // Reset subtotal on error
        };
        setInvalidRows((prevInvalidRows) => {
          const newInvalidRows = new Set(prevInvalidRows);
          newInvalidRows.add(actualIndex);
          return newInvalidRows;
        });
      } else {
        // Update product with the new quantity
        updatedProducts[actualIndex] = {
          ...updatedProducts[actualIndex],
          quantity: newValue,
          subtotal: updatedProducts[actualIndex].price === 0
            ? 0
            : newValue * updatedProducts[actualIndex].price, // Use price to calculate subtotal
        };

        // Display toast if quantity exceeds total stock
        if (newValue > updatedProducts[actualIndex].totalQuantity) {
          toast.warning("Stocks not available for given quantity", {
            autoClose: 2000,
          });
        }

        setErrorMessage({ name: "", message: "" });
        setInvalidRows((prevInvalidRows) => {
          const newInvalidRows = new Set(prevInvalidRows);
          newInvalidRows.delete(actualIndex);
          return newInvalidRows;
        });
      }

      // Calculate total price of selected (checked) products
      const totalGiftPrice = updatedProducts
        .filter(product => product.price === 0) // Get all products with price 0
        .reduce((total, product) => total + (originalPriceRef.current[updatedProducts.indexOf(product)] || 0) * product.quantity, 0); // Sum original prices multiplied by their quantities

      setSelectedGiftPrice(totalGiftPrice); // Update the selectedGiftPrice with the calculated total

      return updatedProducts;
    });
  };



  const incrementQuantity = (index) => {
    setSelectedProducts((prevProducts) => {
      const updatedProducts = [...prevProducts];
      const actualIndex = prevProducts.length - 1 - index;

      const currentProduct = updatedProducts[actualIndex];
      const maxQuantity = parseFloat(currentProduct.totalQuantity);
      const currentQuantity = parseFloat(currentProduct.quantity);

      // Increment the quantity
      const newQuantity = currentQuantity + 1;

      // Check if newQuantity exceeds maxQuantity
      if (newQuantity > maxQuantity) {
        toast.warning("Stocks quantity was reached", {
          autoClose: 2000,
        });
      }

      // Update the product with the new quantity and recalculate subtotal based on the price
      updatedProducts[actualIndex] = {
        ...currentProduct,
        quantity: newQuantity, // Update to new quantity
        subtotal: newQuantity * (currentProduct.price === 0 ? originalPriceRef.current[actualIndex] : currentProduct.price), // Recalculate subtotal
      };

      setErrorMessage({ name: "", message: "" });
      setInvalidRows((prevInvalidRows) => {
        const newInvalidRows = new Set(prevInvalidRows);
        newInvalidRows.delete(actualIndex);
        return newInvalidRows;
      });

      // Update total price for selected products
      updateTotalPrice(updatedProducts);

      return updatedProducts;
    });
  };


  const decrementQuantity = (index) => {
    setSelectedProducts((prevProducts) => {
      const updatedProducts = [...prevProducts];
      const actualIndex = prevProducts.length - 1 - index;
      const currentQuantity = updatedProducts[actualIndex].quantity;

      if (currentQuantity === 1) {
        toast.info("Minimum quantity value reached", {
          autoClose: 1200,
        });
        return updatedProducts; // Return early without updating state
      }

      // Decrement the quantity and recalculate subtotal based on the price
      updatedProducts[actualIndex] = {
        ...updatedProducts[actualIndex],
        quantity: Math.max(currentQuantity - 1, 1), // Ensure quantity doesn't go below 1
        subtotal: Math.max(currentQuantity - 1, 1) * (updatedProducts[actualIndex].price === 0 ? originalPriceRef.current[actualIndex] : updatedProducts[actualIndex].price),
      };

      setErrorMessage({ name: "", message: "" });
      setInvalidRows((prevInvalidRows) => {
        const newInvalidRows = new Set(prevInvalidRows);
        newInvalidRows.delete(actualIndex);
        return newInvalidRows;
      });

      // Update total price for selected products
      updateTotalPrice(updatedProducts);

      return updatedProducts;
    });
  };

  // Helper function to update total price
  const updateTotalPrice = (updatedProducts) => {
    const totalGiftPrice = updatedProducts
      .filter(product => product.price === 0) // Get all products with price 0
      .reduce((total, product) => total + (originalPriceRef.current[updatedProducts.indexOf(product)] || 0) * product.quantity, 0); // Sum original prices multiplied by their quantities

    setSelectedGiftPrice(totalGiftPrice); // Update the selectedGiftPrice with the calculated total
  };



  // New function to validate quantities
  const validateAndSetQuantities = () => {
    let isValid = true;
    setSelectedProducts((prevProducts) => {
      const updatedProducts = prevProducts.map((product, index) => {
        if (!product.quantity || product.quantity === "") {
          isValid = false;
          setInvalidRows((prevInvalidRows) => {
            const newInvalidRows = new Set(prevInvalidRows);
            newInvalidRows.add(index);
            return newInvalidRows;
          });
          return {
            ...product,
            quantity: "",
            subtotal: 0,
          };
        }
        return product;
      });
      return updatedProducts;
    });

    if (isValid) {
      setPaymentPopupOpen(true);
    }
  };

  const handleDelete = (productToDelete) => {
    const newProducts = selectedProducts.filter(
      (product) => product.id !== productToDelete.id
    );
    setSelectedProducts(newProducts);
  };

  const handleReset = () => {
    // Reset the selectedProducts state to an empty array
    setSelectedProducts([]);
    setSearchQuery1("");
    setSelectedCustomerName("");
    setInvalidRows(new Set());
    setDeliveryCharge("");
    setDiscount("");
  };
  const toggleFullScreen = () => {
    if (!isFullScreen) {
      // Enter fullscreen mode
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        /* Firefox */
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        /* IE/Edge */
        document.documentElement.msRequestFullscreen();
      }
    } else {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen();
      }
    }
    // Toggle the fullscreen state
    setIsFullScreen(!isFullScreen);
  };
  const handleCancelHold = () => {
    // Close the hold popup
    setIsHoldPopupRefOpen(false);
    // Clear the reference number field
    setReferenceNumber("");
    setHoldPopUpScreen(false);
    // Clear the hold products
    setHoldProducts([]);
  };

  // Function to send hold data to the server
  const sendHoldData = async () => {
    try {
      if (!referenceNumber) {
        toast("Reference number cannot be empty❌❌");
        return; // Exit function if reference number is empty
      }
      const role = localStorage.getItem("role");
      const token = localStorage.getItem("token");

      const holdData = {
        referenceNo: referenceNumber,
        warehouseId: selectedWarehouse,
        products: selectedProducts.map((product) => ({
          productId: product.id,
          price: product.price,
          unit: product.unit,
          quantity: product.quantity,
          stockQuantity: product.totalQuantity,
          subtotal: product.quantity * product.price,
        })),
        createdBy: role,
      };

      // Check if reference number already exists
      const existingHold = holdDataExists(holdData.referenceNo);
      if (existingHold) {
        toast("Reference number already exists❌❌");
        return; // Exit function if hold with same reference number exists
      }

      const response = await fetch("https://pos.farm2bag.com/api/v1/holds", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(holdData),
      });

      if (response.ok) {
        const updatedHoldData = await fetchHoldData(selectedWarehouse);
        fetchHoldData();

        // Hold data saved successfully
        // Close the hold popup
        setIsHoldPopupRefOpen(false);
        // Clear the reference number field
        setReferenceNumber("");
        // Clear the hold products

        setHoldProducts([]);
        setSelectedProducts([]);
        setCashInHand(0);
        setHoldData(updatedHoldData);
        setHoldCount(updatedHoldData.length);

        toast("Data Holds Successfully");
      } else {
        // Handle error response
        const errorMessage = await response.text();
        console.error("Error saving hold data:", errorMessage);
      }
    } catch (error) {
      // Handle network or other errors
      console.error("Network error:", error.message);
    }
  };

  const holdDataExists = (referenceNo) => {
    // Assuming holdData is an array containing existing holds
    // Check if there's already a hold with the same reference number
    const existingHold = holdData.find(
      (hold) => hold.referenceNo === referenceNo
    );
    return existingHold;
  };

  const fetchHoldData = async (warehouseId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `https://pos.farm2bag.com/api/v1/holds/warehouse/${warehouseId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        if (responseData && responseData.data) {
          const dataLength = responseData.data.length;
          setHoldData(responseData.data);
          setHoldCount(dataLength > 0 ? dataLength : 0); // Set the count to zero if data length is 0
          return responseData.data;
        } else {
          console.error("No hold data found in response:", responseData);
          return [];
        }
      } else {
        console.error("Failed to fetch hold data:", response.statusText);
        return [];
      }
    } catch (error) {
      console.error("Error fetching hold data:", error);
      return [];
    }
  };


  const handleEditClickInHoldPopup = async (holdId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`https://pos.farm2bag.com/api/v1/holds/${holdId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        if (responseData && responseData.data && responseData.data.data) {
          const productsWithDetails = responseData.data.data.products.map(product => ({
            id: product.productId,
            name: product.name,
            quantity: product.quantity,
            totalQuantity: product.stocksquantity, // Ensure this is set correctly
            price: product.price,
            unit: product.unitSymbol,
            totalCost: product.price * product.quantity, // Calculate total cost based on price and quantity
          }));
          setSelectedProducts(productsWithDetails);
        } else {
          console.error("No hold details found in response:", responseData);
        }
      } else {
        console.error("Failed to fetch hold details:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching hold details:", error);
    }
  };





  const handleRowClick = (holdId) => {
    handleEditClickInHoldPopup(holdId);
    setHoldPopUpScreen(false);
  };

  const handleHoldDelete = async (item) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `https://pos.farm2bag.com/api/v1/holds/${item.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseData = await response.json();
      const { message, status, data } = responseData;

      if (response.ok) {
        // Remove the deleted item from the state
        setHoldData((prevData) =>
          prevData.filter((holdItem) => holdItem.id !== item.id)
        );
        // Update hold count
        setHoldCount((prevCount) => prevCount - 1);
        toast("Hold Deleted Successfully");
      } else {
        console.error("Failed to delete hold data:", response.statusText);
      }
    } catch (error) {
      console.error("Delete Error:", error.message);
    }
  };

  // calculator----------------------------------

  const toggleCalculator = () => {
    setShowCalculator(!showCalculator);
  };

  const handleWarehouseChange = async (e) => {
    setFilteredProducts([]);
    setHoldData([]);
    setHoldCount(0);
    const selectedWarehouseId = e.target.value;
    const selectedWarehouse = warehouses.find(
      (warehouse) => warehouse.id === selectedWarehouseId
    );

    setSelectedWarehouse(selectedWarehouseId);
    setSelectedWarehouseName(selectedWarehouse?.name || "");

    // Call toggleDropdown with the selected warehouse ID
    await toggleDropdown(String(selectedWarehouseId));

    // Fetch hold data for the selected warehouse
    await fetchHoldData(selectedWarehouseId);
  };



  const openRegisterDetailsModal = () => {
    setShowRegisterDetailsModal(true);
  };

  const openCloseRegisterModal = () => {
    setShowRegisterDetailsModal(false);

    setShowCloseRegisterModal(true);
  };

  const closeModal = () => {
    setShowRegisterDetailsModal(false);
    setShowCloseRegisterModal(false);
  };

  const handleKeyDownClose = (e) => {
    if (e.key === 'Escape' && showRegisterDetailsModal) {
      closeModal();
    }
  };


  useEffect(() => {
    if (showRegisterDetailsModal) {
      document.addEventListener('keydown', handleKeyDownClose);
    } else {
      document.removeEventListener('keydown', handleKeyDownClose);
    }

    // Cleanup the event listener on unmount or when modal is closed
    return () => {
      document.removeEventListener('keydown', handleKeyDownClose);
    };
  }, [showRegisterDetailsModal]);



  const handleCloseRegister = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }

      // Ensure selectedWarehouse is not null
      if (!selectedWarehouse) {
        toast.error("Warehouse is not selected");
        return;
      }

      const url = `https://pos.farm2bag.com/api/v1/registers/close?warehouseId=${encodeURIComponent(
        selectedWarehouse
      )}&inHandCash=${encodeURIComponent(cashInHand)}&note=${encodeURIComponent(
        note
      )}`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        setRegisterDetails(null);
        toast.success("Register closed successfully!");
        setIsOpen(false);
        closeModal();
      } else {
        console.error("Error closing register:", response.statusText);
      }
    } catch (error) {
      console.error("Network error:", error.message);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleInputChanges = (e) => {
    const value = e.target.value;

    // Allow only digits and an empty value
    const sanitizedValue = value.replace(/[^0-9]/g, '');

    // Convert to a number and ensure it's non-negative
    const numericValue = parseFloat(sanitizedValue);

    // Update the state if the sanitized value is a valid number
    if (!isNaN(numericValue)) {
      setCashInHand(numericValue);
    } else if (sanitizedValue === '') {
      setCashInHand('');
    }
  };

  const handlePostRequest = async () => {
    try {
      const token = localStorage.getItem("token"); // Retrieve token from local storage
      const role = localStorage.getItem("role"); // Define the role

      // Ensure selectedWarehouse is not null
      if (!selectedWarehouse) {
        toast.error("Warehouse is not selected");
        return;
      }

      const response = await axios.post(
        "https://pos.farm2bag.com/api/v1/registers/start",
        {
          createdBy: role,
          amount: cashInHand,
          warehouseId: selectedWarehouse, // Include warehouseId in the request body
        }, // API URL
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the headers
            "Content-Type": "application/json",
          },
        }
      );

      setRegisterDetails(response.data);
      toast.success("Register Opened Successfully!");
      // setCashInHand(null);
      closePopup();
    } catch (error) {
      console.error("There was a problem with the axios request:", error);
    }
  };


  const toggleDropdown = async (warehouseId) => {
    try {
      const token = localStorage.getItem("token"); // Retrieve token from local storage or wherever it's stored

      // Ensure warehouseId is not null
      if (!warehouseId) {
        toast.error("Warehouse is not selected");
        return;
      }

      // Include the warehouseId as a query parameter
      const response = await axios.get(
        `https://pos.farm2bag.com/api/v1/registers/open-register-for-today?warehouseId=${warehouseId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the headers
            "Content-Type": "application/json",
          },
        }
      );

      // If a response is received with status 200
      if (response.status === 200) {
        const responseData = response.data;
        const inHandCash = responseData.data.inHandCash;
        setRegisterDetails(responseData.data);
        setCashInHand(inHandCash);
        // setIsOpen(!isOpen); // Toggle dropdown
        setShowRegisterDetailsModal(true);
        // alert("Already in Open---Register");
      }
    } catch (error) {
      // If the error is a 404, set registerDetails to null and show the popup
      if (error.response && error.response.status === 404) {
        setRegisterDetails(null); // Set registerDetails to null if no data (404)
        setShowPopup(true); // Show popup if registerDetails is null
      } else {
        console.error("Error fetching open register:", error);
        // Handle other errors (e.g., show error message, retry logic)
      }
    }
  };

  const handlePaymentStatusChange = (event) => {
    setPaymentStatus(event.target.value);
  };


  const handleBox5ButtonClick = () => {
    setIsBox5PopupOpen(true);
  };

  const handleBox5OkClick = () => {
    localStorage.setItem("activeNavItem", "Dashboard"); // Set activeNavItem to "Dashboard" in local storage
    navigate("/dashboard"); // Navigate to the dashboard page
  };
  const handleBox5CancelClick = () => {
    setIsBox5PopupOpen(false);
  };
  // Function to close the popup when Esc key is pressed
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setIsBox5PopupOpen(false); // Close the popup when Esc is pressed
      }
    };

    // Attach the event listener when the popup is open
    if (isBox5PopupOpen) {
      window.addEventListener("keydown", handleKeyDown);
    }

    // Clean up the event listener when the component unmounts or popup closes
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isBox5PopupOpen]);
  // Add keyboard shortcut for "Alt + B" to navigate to the dashboard immediately
  useEffect(() => {
    const handleKeyPress = (event) => {
      // Command + B for macOS (metaKey), Alt + B for Windows (altKey)
      if ((event.metaKey && event.key === "b") || (event.altKey && event.key === "b")) {
        // Directly navigate to the dashboard without confirmation
        localStorage.setItem("activeNavItem", "Dashboard");
        navigate("/dashboard");
        setIsBox5PopupOpen(false); // Close the popup if it's open
      }
    };

    // Attach the event listener
    window.addEventListener("keydown", handleKeyPress);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [navigate, setIsBox5PopupOpen]);


  const filteredCustomers = customers.filter((customer) => {
    const searchLower = searchQuery1.toLowerCase(); // Convert the search query to lowercase
    return (
      customer.contactNumber.toLowerCase().includes(searchLower) || // Check if the contactNumber includes the search query
      customer.name.toLowerCase().includes(searchLower) // Check if the name includes the search query
    );
  });


  const handleKeyDownCustomer = (e) => {
    // Check if the input field is focused
    if (document.activeElement !== inputRef.current) {
      // If Escape key is pressed, focus the input
      if (e.key === 'Escape') {
        setFocusedInput(0);
        inputRef.current.focus(); // Focus the input field
        return; // Exit the function
      }
      return; // Exit the function if the input is not focused
    }

    if (e.key === 'ArrowDown') {
      // Move down in the list
      setActiveIndex((prevIndex) =>
        prevIndex < filteredCustomers.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      // Move up in the list
      setActiveIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    } else if (e.key === 'Enter' && activeIndex >= 0) {
      // Select the currently highlighted item
      const selected = filteredCustomers[activeIndex];

      const name = selected.name;

      const number = selected.contactNumber;

      const nameNumber = name + "(" + number + ")";

      setSearchQuery1(nameNumber);
      setSelectedCustomer(selected.id);
      setPhoneNumber(selected.contactNumber);
      setShowDropdown(false);
      setActiveIndex(-1);
    }
  };

  useEffect(() => {
    // Add event listener for keydown
    document.addEventListener('keydown', handleKeyDownCustomer);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDownCustomer);
    };
  }, [activeIndex, filteredCustomers]);



  return (
    <div className="bg-gray-100 pt-5 h-screen  ">
      <div className=" mx-auto h-screen w-full ">
        <div className="flex justify-between items-center mb-0">
          <div className="flex items-center w-75 bg-gray-200 rounded-lg">
            <div className="flex items-center">
              <button className="flex items-center pl-1" onClick={() => setIsPopupOpen(true)}>
                <img
                  src={Addcustomer}
                  className="w-10 h-10 bg-green-500 rounded-lg"
                  alt="Addcustomer Icon"
                />
              </button>
            </div>



            <div className="relative flex-1">
              <input
                ref={inputRef} // Attach the ref to the input
                type="text"
                placeholder="WALK - IN - CUSTOMER"
                className="border p-4 rounded-lg w-full font-semibold bg-gray-200 pr-10 focus:outline-none focus:border-blue-500"
                value={searchQuery1}
                onChange={(e) => {
                  setSearchQuery1(e.target.value);
                  setPhoneNumber(e.target.value);
                  setCustomerNumber(e.target.value);
                  setShowDropdown(true);
                  setActiveIndex(-1);
                }}
              />

              {showDropdown && searchQuery1.length > 0 && (
                <div className="absolute z-10 mt-1 w-full font-semibold text-lg text-left bg-white rounded-lg shadow-lg">
                  {filteredCustomers.length > 0 ? (
                    filteredCustomers.map((customer, index) => (
                      <div
                        key={customer.id}
                        className={`p-2 cursor-pointer text-sm hover:bg-gray-300 ${activeIndex === index ? 'bg-gray-300' : ''}`}
                        onClick={() => {
                          setSearchQuery1(customer.name);
                          setSelectedCustomer(customer.id);
                          setPhoneNumber(customer.contactNumber);
                          setCustomerNumber(customer.contactNumber);
                          setShowDropdown(false);
                          setActiveIndex(-1); // Reset active index on selection
                        }}
                      >
                        {customer.contactNumber} ({customer.name})
                      </div>
                    ))
                  ) : (
                    <div className="p-2 text-sm hover:bg-gray-300 cursor-pointer">
                      <p className="text-gray-700">Customer not found. Add new customer:</p>
                      <input
                        type="text"
                        placeholder="Enter new customer name"
                        className="border p-2 rounded-lg w-full mt-2"
                        value={newCustomerName}
                        onChange={(e) => setNewCustomerName(e.target.value)}
                      />
                      <div className="pl-14">
                        <button
                          onClick={() => handleGetLocation("new")}
                          className={`bg-green-500 text-white px-4 py-2 rounded-lg mt-2 hover:bg-green-700 ${newCustomerName.trim() === "" ? "bg-orange-400 hover:bg-orange-600" : ""
                            }`}
                        >
                          Add Customer
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>


          </div>
          <div className="flex gap-3.5 space-x-3.5">
            <select
              className="border p-3 rounded-lg bg-gray-200"
              style={{ width: "360px" }} // Adjust the width as needed
              value={selectedWarehouse}
              onChange={(event) => {
                const selectedValue = event.target.value;
                setSelectedWarehouse(selectedValue); // Update state on change
                handleWarehouseChange(event);
                handleReset();
                setDiscount("");
                setDeliveryCharge("");
                setGst("");
              }}
            >
              <option value="" className="hidden">
                SELECT WAREHOUSE
              </option>
              {warehouses.map((warehouse) => (
                <option key={warehouse.id} value={warehouse.id}>
                  {warehouse.name}
                </option>
              ))}
            </select>


            <div className="relative w-80">
              <input
                type="text"
                placeholder="Search by Code and Name"
                className="border border-gray-200 w-full bg-gray-200 px-10 py-3 rounded-lg focus:outline-none focus:border-blue-500 uppercase"
                value={searchQuery}
                onChange={handleSearchInputChange}
                onBlur={() => setTimeout(() => setDropdownVisible(false), 100)} // Hide dropdown on blur
                onKeyDown={handleKeyDown} // Handle arrow keys and Enter
                onFocus={() => {
                  setFocusedInput(0);
                  setFocusedQuantityIndex(null); // Reset focused quantity index
                }} // Set focusedInput to 0 when this field is focused
                ref={searchInputRef} // Reference to the search input
              />

              {dropdownVisible && (
                <Dropdown
                  products={filteredProducts}
                  onSelect={handleSelectProductFromDropdown}
                  highlightedIndex={highlightedIndex} // Pass highlighted index to Dropdown
                />
              )}
            </div>



            <button
              className="bg-gray-200 p-1 rounded-lg relative"
              onClick={handleBox1Click}
            >
              <img src={Box1} alt="Box1 Icon" className="h-9 w-10" />
              {holdCount !== null && holdCount !== undefined && (
                <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white rounded-full h-6 w-6 flex items-center justify-center text-xs">
                  {holdCount}
                </span>
              )}

            </button>


            {/* --------------------------------------------------------Register---------------------------------------- */}
            <div className="relative inline-block text-left">
              {showPopup && (
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
                  <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-sm mx-auto">
                    <div className="flex justify-between items-center mb-4">
                      <h2 className="text-lg font-medium">POS Register</h2>
                      <button
                        className="text-gray-500 hover:text-gray-700 focus:outline-none"
                        onClick={closePopup}
                      >
                        ❌
                      </button>
                    </div>
                    <div className="mb-6">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Cash In Hand:
                      </label>
                      <input
                        type="number"
                        min={0}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        value={cashInHand}
                        onChange={handleInputChanges}
                      />
                    </div>
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded-md w-full hover:bg-blue-600 focus:outline-none focus:bg-blue-700"
                      onClick={handlePostRequest}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}

              <button
                className="bg-gray-200 p-2 rounded-lg relative"
                onClick={() => toggleDropdown(String(selectedWarehouse))}
              >
                <img src={Box2} alt="Box2 Icon" className="h-10 w-10" />
              </button>

              {showRegisterDetailsModal && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                  <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div
                      className="fixed inset-0 transition-opacity"
                      aria-hidden="true"
                    >
                      <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>

                    <span
                      className="hidden sm:inline-block sm:align-middle sm:h-screen"
                      aria-hidden="true"
                    >
                      &#8203;
                    </span>

                    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                              Register Details - {new Date(registerDetails.startDate).toLocaleDateString('en-GB')}
                            </h3>

                            <button
                              className="text-gray-500 hover:text-gray-700 focus:outline-none absolute top-4 right-6"
                              onClick={closeModal}
                            >
                              ❌
                            </button>
                            <div className="mt-2">
                              <table className="min-w-full">
                                <thead>
                                  <tr>
                                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Payment Type
                                    </th>
                                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Amount
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                      Total Sales:
                                    </td>
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                      ₹ {registerDetails.totalSalesAmount.toFixed(2)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                      Cash:
                                    </td>
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                      ₹ {registerDetails.cashReceived.toFixed(2)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                      Card:
                                    </td>
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                      ₹ {registerDetails.cardReceived.toFixed(2)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                      UPI:
                                    </td>
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                      ₹ {registerDetails.digitalReceived.toFixed(2)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                      In Hand Cash:
                                    </td>
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                      ₹ {registerDetails.inHandCash.toFixed(2)}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button
                          type="button"
                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                          onClick={openCloseRegisterModal}
                        >
                          Settlement
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {showCloseRegisterModal && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                  <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div
                      className="fixed inset-0 transition-opacity"
                      aria-hidden="true"
                    >
                      <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>

                    <span
                      className="hidden sm:inline-block sm:align-middle sm:h-screen"
                      aria-hidden="true"
                    >
                      &#8203;
                    </span>

                    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                              Close Register
                            </h3>
                            <div className="mt-2">
                              <label className="block text-sm font-medium text-gray-700 mb-1">
                                Cash In Hand:
                              </label>
                              <input
                                type="number"
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                value={cashInHand}
                                onChange={handleInputChanges}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button
                          type="button"
                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                          onClick={handleCloseRegister}
                        >
                          Close Register
                        </button>
                        <button
                          type="button"
                          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:mt-0 sm:text-sm sm:leading-5"
                          onClick={closeModal}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <button
              className="bg-gray-200 p-2 rounded-lg"
              onClick={toggleFullScreen}
            >
              <img src={Box3} alt="Box3 Icon" className="h-9 w-10" />
            </button>

            {/* calculator */}
            <div>
              <button
                className="bg-gray-200 p-2 rounded-lg"
                onClick={toggleCalculator}
              >
                <img src={Box4} alt="Box4 Icon" className="h-10 w-10" />
              </button>

              {showCalculator && <Calculator toggleModal={toggleCalculator} />}
            </div>

            <button
              className="bg-gray-200 p-2 rounded-lg"
              onClick={handleBox5ButtonClick}
              title="Alt + B (Option + B on Mac)" // Updated tooltip
            >
              <img src={Box5} alt="Box5 Icon" className="h-9 w-10" />
            </button>
          </div>
        </div>

        <marquee behavior="" direction="">
          <span className="text-red-500 font-bold mr-10">Use Hot keys are there 👉👉</span>

          <span className="bg-blue-600 text-white text-sm border border-purple-500 rounded px-3 ml-5">Tab</span>
          <span className="digital-text"> Choose the quantity fields or search fields</span>

          <span className="bg-blue-600 text-white text-sm border border-purple-500 rounded px-1 ml-5">Escap</span>
          <span className="digital-text"> Close Register popup or Click 2 times select customer input</span>

          <span className="bg-blue-600 text-white text-sm border border-purple-500 rounded px-2 ml-5">fn + F1</span>
          <span className="digital-text"> Choose category field</span>

          <span className="digital-text text-yellow-600 ml-10">Note : </span>
          <span className="digital-text"> After entering the customer details, click the 'Tab' button to navigate the cursor to the 'Make Payment' section and its associated fields.</span>
        </marquee>




        <div className="grid grid-cols-2 min-h-screen bg-gray-100 gap-8 mb-4 ">
          <div className="col-span-1 bg-gray-200 p-0 rounded-lg shadow-lg">
            <div className="rounded-lg overflow-hidden">
              {errorMessage.message && (
                <div className="text-red-600 bg-yellow-300 px-2 py-1 rounded-lg mb-2">
                  {errorMessage.name} {errorMessage.message}
                </div>
              )}
              <div
                className="overflow-x-auto rounded-lg"
                style={{
                  maxHeight: '400px', // Set a fixed max-height for vertical scrolling
                  overflowY: selectedCount > 4 ? 'scroll' : 'hidden', // Enable vertical scroll if selectedCount > 0
                  overflowX: 'hidden', // Hide horizontal scroll
                  scrollbarWidth: 'thin', // Firefox scrollbar width
                }}

              >
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="w-1/5 py-3 px-6 text-left text-xs font-medium text-gray-500 tracking-wider overflow-hidden text-nowrap">
                        PRODUCT <span className="text-white text-sm ml-5 p-1 rounded-lg bg-purple-600 ">Count : {selectedCount} nos</span>
                      </th>
                      <th className="w-1/5 py-3 px-6 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        QTY
                      </th>
                      <th className="w-1/5 py-3 px-6 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        PRICE
                      </th>
                      <th className="w-1/5 py-3 px-6 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        SUB TOTAL
                      </th>
                      <th className="w-1/5 py-3 px-6 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...selectedProducts].reverse().map((product, index) => {
                      const actualIndex = selectedProducts.length - 1 - index;
                      const isInvalid = invalidRows.has(actualIndex);
                      const isChecked = product.price === 0;

                      return (
                        <tr
                          key={product.id}
                          className={`${isInvalid ? "bg-green-100 animate-blink" : ""
                            }${isChecked ? "bg-white" : ""}`}
                        >
                          <td className="py-4 px-6">
                            <div className="flex items-center">
                              <button
                                className="mr-2 text-blue-500 text-left"
                                onClick={() => handleEditClick(product)}
                              >
                                <FaRegEdit size={18} />
                              </button>
                              <span
                                className="font-medium text-sm truncate text-left overflow-hidden whitespace-nowrap text-wrap"
                                style={{ maxWidth: "180px" }} // Adjust max-width as needed
                                title={product.name} // Tooltip to show full name on hover
                              >
                                {product.name}{" "}
                              </span>
                              <span className="text-green-500">({product.unit})</span>
                            </div>
                          </td>

                          <td className="py-4 px-6">
                            <div className="flex items-center">
                              <button
                                className="font-extrabold focus:outline-none px-3 bg-red-400 rounded-xl"
                                onMouseDown={() => decrementQuantity(index)}
                              >
                                {" "}
                                -{" "}
                              </button>



                              <input
                                key={index}
                                type="number"
                                className="mx-2 w-16 text-center border border-gray-300 rounded bg-yellow-400 font-bold"
                                value={product.quantity}
                                onChange={(e) => handleQuantityChange(index, e.target.value)} // Handle change with direct index
                                step="0.001" // Allows up to three decimal places
                                ref={(el) => (quantityInputRefs.current[index] = el)} // Add ref for each quantity input using direct index
                                onFocus={() => {
                                  setFocusedInput(1); // Set focusedInput to 1 for quantity inputs
                                  setFocusedQuantityIndex(index); // Track the specific quantity input that's focused
                                }}
                                onInput={(e) => {
                                  // Limit the number of decimal places to three
                                  const value = e.target.value;
                                  if (value.includes('.')) {
                                    const [integerPart, decimalPart] = value.split('.');
                                    if (decimalPart.length > 3) {
                                      e.target.value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                                    }
                                  }
                                }}
                              />


                              <button
                                className="font-bold focus:outline-none px-3 bg-green-500 rounded-xl"
                                onMouseDown={() => incrementQuantity(index)}
                              >
                                {" "}
                                +{" "}
                              </button>
                            </div>
                          </td>

                          <td className="py-4 px-6">
                            {product.price === 0 ? (
                              <img src={giftBox} alt="Gift Box" className="w-8 h-8" />
                            ) : (
                              `₹${product.price}`
                            )}
                          </td>
                          <td className="py-4 px-6">
                            {product.quantity * product.price === 0 && product.price === 0 ? (
                              <img src={giftBox} alt="Gift Box" className="w-8 h-8" />
                            ) : (
                              `₹${(product.quantity * product.price).toFixed(2)}`
                            )}
                          </td>


                          <td className="py-4 px-6 flex items-center">
                            <button
                              onClick={() => handleDelete(product)}
                              className=" text-white font-bold rounded"
                            >
                              <IoMdTrash
                                size={30}
                                style={{ color: "red" }}
                                className="inline-block mr-1"
                              />
                            </button>

                            <input
                              type="checkbox"
                              onChange={() => handleCheckboxChange(index)}
                              className="transform scale-150"
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="mt-2 border-t border-gray-500"></div>

            <div className="grid grid-cols-3 gap-4 px-2 mb-1 bg-yellow-400 py-2">

              {/* Discount Input */}
              <div className="flex items-center">
                <label className="font-semibold w-1/2 text-right pr-2">Discount (%):</label>
                <input
                  type="number"
                  className="border p-1 w-2/5 rounded-lg font-bold"
                  value={discount}
                  onChange={(e) => {
                    const value = e.target.value;
                    setDiscount(value === '' ? '' : Math.max(0, Math.min(100, value)));
                  }}
                  placeholder="Enter discount"
                  min="0"
                  max="100"
                />
              </div>

              {/* Shipping Charge Input */}
              <div className="flex items-center">
                <label className="font-semibold w-1/2 text-left  text-nowrap">Shipping Charge (₹):</label>
                <input
                  type="number"
                  className="border p-1 w-2/5 rounded-lg font-bold ml-10"
                  value={deliveryCharge}
                  onChange={(e) => {
                    const value = e.target.value;
                    setDeliveryCharge(value === '' ? '' : Math.max(0, value));
                  }}
                  placeholder="Enter shipping charge"
                  min="0"
                />
              </div>

              {/* Sub Total */}
              <div className="flex items-center">
                <label className="font-semibold w-1/2 text-right pr-2">Sub Total:</label>
                <span className="font-bold">₹ {overallSubTotal.toFixed(2)}</span>
              </div>

              {/* GST Input */}
              <div className="flex items-center">
                <label className="font-semibold w-1/2 text-right pr-2">GST (%):</label>
                <input
                  type="number"
                  className="border p-1 w-2/5 rounded-lg font-bold"
                  value={gst}
                  onChange={(e) => {
                    const value = e.target.value;
                    setGst(value === '' ? '' : Math.max(0, Math.min(100, value)));
                  }}
                  placeholder="Enter GST"
                  min="0"
                  max="100"
                />
              </div>

              {/* GST Amount */}
              <div className="flex items-center">
                <label className="font-semibold w-1/2 text-left pr-2">GST Amount:</label>
                <span className="font-bold">₹ {gstAmount.toFixed(2)}</span>
              </div>

              {/* Overall Total */}
              <div className="flex items-center">
                <label className="font-semibold w-1/2 text-right pr-2">Overall Total:</label>
                <span className="px-2 bg-green-400 rounded-lg font-bold">₹ {finalTotal.toFixed(2)}</span>
              </div>
            </div>

            <div className="p-0">
              <div className="bg-gradient-to-r from-teal-200 via-blue-200 to-purple-400 p-5 rounded-lg w-full">
                <h2 className="text-xl font-bold mb-4">Make Payment</h2>

                {/* Create a 3-column, 2-row grid for inputs */}
                <div className="grid grid-cols-3 gap-4 mb-4">
                  {/* Column 1 - Paying Amount */}
                  <div className="col-span-1">
                    <label className="block mb-2">Paying Amount:</label>
                    <input
                      type="number"
                      className="border p-2 w-full bg-green-300 font-bold"
                      value={finalTotal.toFixed(2)}
                      readOnly
                    />
                  </div>

                  {/* Column 2 - Payment Type */}
                  <div className="col-span-1">
                    <label className="block mb-2">Payment Type:</label>
                    <select
                      ref={paymentTypeRef}
                      className="border p-2 w-full"
                      value={paymentMode}
                      onChange={(e) => setPaymentMode(e.target.value)}
                    >
                      <option value="cash">Cash</option>
                      <option value="card">Card</option>
                      <option value="digital">UPI</option>
                    </select>
                  </div>

                  {/* Column 3 - Payment Status */}
                  <div className="col-span-1">
                    <label className="block mb-2">Payment Status:</label>
                    <select
                      ref={paymentStatusRef}
                      className="border p-2 w-full"
                      value={paymentStatus}
                      onChange={handlePaymentStatusChange}
                    >
                      <option value="Paid">Paid</option>
                      <option value="Pending">Pending</option>
                      <option value="COD">COD</option>
                    </select>
                  </div>

                  {/* Row 2, Column 1 - Received Amount */}
                  <div className="col-span-1">
                    <label className="block mb-2">Received Amount:</label>
                    <input
                      ref={receivedAmountRef}
                      type="number"
                      className="border p-2 w-full"
                      value={receivedAmount}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === "" || Number(value) > 0) {
                          setReceivedAmount(value);
                        }
                      }}
                      placeholder="Enter amount"
                    />
                  </div>

                  {/* Row 2, Column 2 - Change Return */}
                  <div className="col-span-1">
                    <label className="block mb-2">Change Return:</label>
                    <input
                      type="number"
                      className="border p-2 w-full bg-green-300 font-bold"
                      value={changeReturn.toFixed(2)}
                      readOnly
                    />
                  </div>

                  {/* Row 2, Column 3 - Notes */}
                  <div className="col-span-1">
                    <label className="block mb-2">Notes:</label>
                    <textarea className="border p-2 w-full"></textarea>
                  </div>
                </div>

                {/* Row 3 - Buttons */}
                <div className="flex justify-between mt-4">
                  <button
                    ref={holdButtonRef}
                    className="bg-gray-400 text-white px-4 py-2 rounded-lg hover:bg-green-600"
                    onClick={() => setIsHoldPopupRefOpen(true)}
                  >
                    Hold
                  </button>
                  <button
                    ref={resetButtonRef}
                    className="bg-red-500 text-white px-4 py-2 rounded-lg"
                    onClick={handleReset}
                  >
                    Reset
                  </button>

                  <button
                    ref={payBillButtonRef}
                    className={`bg-green-500 text-white px-4 py-2 rounded-lg ${(paymentMode === "cash" && !receivedAmount) || loading ||
                      !selectedCustomer.trim() ||
                      !selectedProducts.length ||
                      !registerDetails
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                      }`}
                    onClick={() => {
                      // Set discount and delivery charge to 0 if they are empty or null
                      if (!discount || discount === "") {
                        setDiscount(0);
                      }
                      if (!gst || gst === "") {
                        setGst(0);
                      }
                      if (!deliveryCharge || deliveryCharge === "") {
                        setDeliveryCharge(0);
                      }

                      // Proceed with validation and other operations
                      validateAndSetQuantities();

                      // Call the payment function
                      handlePayBillClick();
                    }}
                    disabled={(paymentMode === "cash" && !receivedAmount) || loading ||
                      !selectedCustomer.trim() ||
                      !selectedProducts.length ||
                      !registerDetails
                    }
                  >
                    {loading ? "Processing..." : "Pay Bill"}
                  </button>

                </div>
              </div>
            </div>

          </div>
          <div>



            <div className="flex justify-between mb-4 pr-2">
              <select
                ref={selectRefCategory}
                className="border p-4 rounded-lg bg-gray-200 w-[290px] h-14"
                onChange={handleCategoryChange}
                value={selectedCategory}
                disabled={loadingProductsLocal}
              >
                <option value="">All Categories</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.categoryName}
                  </option>
                ))}
              </select>



              <button
                onClick={togglePopup}
                className="bg-blue-500 text-white p-1 h-10 mt-2 rounded"
                disabled={loadingProductsLocal}
              >
                Priority Category
              </button>


              <div className="flex justify-between mb-4 pr-2">
                <select
                  ref={selectRefProduct}
                  className="border p-4 rounded-lg bg-gray-200 w-[290px] h-14"
                  onChange={handleSelectChange}
                  value={selectedProduct}
                  disabled={loadingProductsLocal}
                >
                  <option value="">Select a Product</option>
                  {filteredProducts.map((product) => (
                    <option key={product.productsCode} value={product.productsCode}>
                      {product.name} ({product.productsCode})
                    </option>
                  ))}
                </select>
              </div>
            </div>



            {/* Popup for selecting priority categories */}
            {isPriorityPopupOpen && (
              <div className="fixed top-0 left-0 right-0 bottom-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white p-6 rounded-lg w-[80%] sm:w-[800px]">
                  <h2 className="text-lg font-semibold mb-4 text-center">Select Priority Categories</h2>

                  <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 mt-4">
                    {categories.map((category) => (
                      <div key={category.id} className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          id={`checkbox-${category.id}`}
                          checked={checkedCategories.includes(category.id)}
                          onChange={() => handleCheckboxChangeCategory(category.id)}
                          disabled={loadingProductsLocal}
                          className="form-checkbox h-5 w-5 text-blue-500"
                        />
                        <label htmlFor={`checkbox-${category.id}`} className="text-sm">
                          {category.categoryName}
                        </label>
                      </div>
                    ))}
                  </div>

                  {/* Close button for the popup */}
                  <button
                    onClick={togglePopup}
                    className="mt-4 bg-red-500 text-white p-2 rounded w-full hover:bg-red-600 transition"
                  >
                    Save
                  </button>
                </div>
              </div>
            )}


            <div className="max-h-[800px] overflow-y-scroll">
              <div className="grid grid-cols-4 gap-4">
                {Array.isArray(filteredProducts) && filteredProducts.length > 0 ? (
                  filteredProducts.map((product) => (
                    <div key={product.id}>
                      <div
                        className={`p-2 rounded-lg shadow-lg flex flex-col justify-between duration-300 hover:bg-gradient-to-r from-[#1efc05] via-[#f8fc05] to-[#cbfc05] hover:scale-90 ${product.totalQuantity < 1
                          ? "bg-gradient-to-r from-red-500 via-orange-500 to-yellow-500 cursor-not-allowed"
                          : "bg-white"
                          }`}
                        onClick={() => handleProductSelect(product)}
                      >

                        <div className="text-sm text-white p-1 rounded-lg text-center w-24 ml-0 text-nowrap" style={{ backgroundColor: "#151716" }}>
                          ⚖️{`${product.quantity.toFixed(2)} ${product.unit}`}
                        </div>
                        <div>
                          <img src={product.image} alt={product.name} className="w-full h-24 object-cover rounded-lg mb-2" />
                          <div className="font-semibold overflow-hidden text-ellipsis whitespace-nowrap text-sm">{product.name}</div>
                          <div className="font-semibold hidden">{product.categoryId}</div>
                          <div className="hidden">{product.totalQuantity}</div>
                          <div className="text-sm text-gray-600">{`${product.productsCode}`}</div>
                        </div>
                        <div className="text-sm p-1 font-bold bg-yellow-300 text-black rounded-lg mt-2">
                          ₹ {`${product.price}`} <span className="text-[#fa3402]">{product.unit}</span>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="col-span-4 flex justify-center items-center">
                    <p className="text-gray-500">No results found</p>
                  </div>
                )}
              </div>

              {loadingProductsLocal && (
                <div className="flex justify-center items-center w-full h-full">
                  <Oval
                    height={80}
                    width={80}
                    color="#3498db"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#f3f3f3"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </div>
              )}

            </div>
          </div>
        </div>
      </div>
      {isBox5PopupOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-xl font-bold mb-4">Confirmation</h2>
            <p className="text-xl mb-8">
              Are you sure you want to move to the{" "}
              <span className="text-green-800 font-bold"> Dashboard</span>?
            </p>
            <div className=" mt-4">
              <button
                ref={okButtonRef}
                onClick={handleBox5OkClick}
                className="bg-green-500 text-white px-8 py-2 float-left rounded-lg font-bold"
              >
                Ok
              </button>
              <button
                ref={cancelButtonRef}
                onClick={handleBox5CancelClick}
                className="bg-red-500 text-white px-4 py-2 rounded-lg mr-2 float-right font-bold"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {isEditPopupOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
          <div className="bg-purple-200 p-6 rounded-lg">
            <h2 className="text-xl font-bold mb-4 bg-gray-300 p-2">
              Edit Stock Price
            </h2>
            <p className="text-lg mb-4 font-bold">
              {
                products.find((product) => product.id === selectedProductId)
                  ?.name
              }{" "} /
              <span className="font-semibold text-red-600 bg-yellow-400 rounded-lg">
                ({" "}
                {
                  products.find((product) => product.id === selectedProductId)
                    ?.unit
                }{" "}
                )
              </span>
            </p>
            <label htmlFor="text" className="font-bold text-xl">
              {" "}
              ₹{" "}
            </label>
            <input
              type="number"
              value={updateStock}
              onChange={(e) => setUpdateStock(e.target.value)}
              placeholder="Enter new price"
              className="border border-gray-200 px-4 py-2 rounded-lg mb-4 w-4/5"
            />
            <button
              onClick={() => setEditPopupOpen(false)}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg float-left mr-11 mt-10 hover:bg-red-600"
            >
              Cancel
            </button>
            <button
              onClick={handleUpdateStock}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg ml-2 float-right ml-11 mt-10 hover:bg-green-500"
            >
              Update
            </button>
          </div>
        </div>
      )}


      {isHoldPopupRefOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
          <div className="bg-gray-200 w-96 p-6 rounded-lg relative">
            <h2 className="text-xl font-bold mb-4">Hold Data</h2>
            <button
              className="absolute top-4 right-4 text-red-500 hover:text-gray-700"
              onClick={() => setIsHoldPopupRefOpen(false)}
            >
              ✕
            </button>
            <div className="mb-4">
              <label htmlFor="referenceNumber" className="block mb-2">
                Reference Number:
              </label>
              <input
                type="text"
                id="referenceNumber"
                value={referenceNumber}
                onChange={(e) => setReferenceNumber(e.target.value)}
                className="border p-2 w-full"
                placeholder="Enter reference number"
              />
            </div>
            {/* Display hold products */}
            {holdProducts.length > 0 && (
              <div>
                <h3 className="text-lg font-bold mb-2">Hold Products</h3>
                <ul>
                  {holdProducts.map((product, index) => (
                    <li key={index}>
                      {product.name} - {product.quantity}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div className="flex justify-end mt-4">
              <button
                onClick={sendHoldData}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg mr-2"
              >
                Save
              </button>
              <button
                onClick={handleCancelHold}
                className="bg-red-500 text-white px-4 py-2 rounded-lg"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {isHoldPopUpOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg w-2/5">
            <button
              className="text-gray-500 float-right hover:text-gray-700"
              onClick={() => setHoldPopUpScreen(false)}
            >
              ❌
            </button>
            <h2 className="text-xl font-bold mb-4">Hold Orders</h2>
            <div className="overflow-auto max-h-80">
              {holdData.length === 0 ? (
                <p className="text-center text-gray-500">No hold found</p>
              ) : (
                <table className="w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Ref No
                      </th>
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date
                      </th>
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {holdData.map((item) => (
                      <tr key={item.id} onClick={() => handleRowClick(item.id)}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {item.referenceNo}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {new Date(item.createdAt).toLocaleDateString("en-GB")}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditClickInHoldPopup(item.id);
                              setHoldPopUpScreen(false); // Close popup on edit button click
                            }}
                            className="mr-2 font-bold rounded"
                          >
                            <FaRegEdit
                              size={22}
                              className="inline-block mr-1"
                            />
                          </button>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setHoldPopUpScreen(false);
                              handleHoldDelete(item);
                            }}
                            className="text-white font-bold rounded"
                          >
                            <IoMdTrash
                              size={30}
                              style={{ color: "red" }}
                              className="inline-block mr-1"
                            />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
      <InvoicePopup
        isOpen={isInvoicePopupOpen}
        onClose={() => setIsInvoicePopupOpen(false)}
        invoiceData={invoiceData}
      />


      <NetworkSlowPopup
        isOpen={isNetworkSlowPopupOpen}
        onClose={handleCloseNetworkSlowPopup}
      />
    </div>
  );
};

export default Billing;