import React, { useState, useEffect } from "react";
import Searchicon from "../Assets/tableicon/Searchicon.png";
import Eye from "../Assets/tableicon/Eye.svg";
import { IoMdTrash } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import SalesEdit from "../components/SalesEdit";
import SalesView from "../components/SalesView";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { startOfDay, endOfDay, parseISO, isWithinInterval } from "date-fns";
import { Link, useLocation, useParams } from "react-router-dom";

const Sales = () => {
  const { invoice } = useParams(); // Get the dynamic invoice number from URL
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const passedDate = queryParams.get('date'); // Get the 'date' query param from URL

  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState(invoice || ""); 
  const [error, setError] = useState(null);
  const [isEditPopupOpen, setEditPopupOpen] = useState(false);
  const [isViewPopupOpen, setViewPopupOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [viewItemId, setViewItemId] = useState(null);
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [loading, setLoading] = useState(true);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState("all"); // Initialize with 'all' to show all payment modes
  useEffect(() => {
    fetchBillingData();
  }, [selectedPaymentMode]);
  useEffect(() => {
    if (passedDate) {
      // If a date is passed, use it for both startDate and endDate
      const parsedDate = new Date(passedDate);
      setStartDate(parsedDate);
      setEndDate(parsedDate);
    } else {
      // Default behavior: Set today's date empty if  no date is passed
     
      setStartDate("");
      setEndDate("");
    }
  }, [passedDate]);
  const fetchBillingData = async () => {
    const token = localStorage.getItem("token");
    console.log(selectedPaymentMode);
    try {
      const response = await fetch(
        "https://pos.farm2bag.com/api/v1/billing/getallForSales",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = await response.json();
      if (Array.isArray(result.data)) {
        setData(result.data);
      } else {
        throw new Error("Data format is not valid");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    fetch("https://pos.farm2bag.com/api/v1/warehouse", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data.data)) {
          setWarehouses(data.data);
        } else {
          throw new Error("Data format is not valid");
        }
      })
      .catch((error) => setError(error.message));
  }, []);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const handleDelete = (item) => {
    setItemToDelete(item);
    setDeletePopupOpen(true);
  };
  // Function to toggle payment mode filter
  const handlePaymentModeToggle = () => {
    if (selectedPaymentMode === "all") {
      setSelectedPaymentMode("cash");
    } else if (selectedPaymentMode === "cash") {
      setSelectedPaymentMode("card");
    } else if (selectedPaymentMode === "card") {
      setSelectedPaymentMode("digital");
    } else {
      setSelectedPaymentMode("all"); // Reset to 'all' after toggling through the modes
    }
  };
  const confirmDelete = () => {
    const token = localStorage.getItem("token");

    fetch(`https://pos.farm2bag.com/api/v1/billing/${itemToDelete.billing.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const newData = data.filter(
          (dataItem) => dataItem.billing.id !== itemToDelete.billing.id
        );
        setData(newData);
        setDeletePopupOpen(false);
        toast.success("Sales Deleted successfully");
        setItemToDelete(null);
        // Check if the current page is empty and adjust the currentPage if needed
        if (
          newData.length > 0 &&
          currentPage > Math.ceil(newData.length / itemsPerPage)
        ) {
          setCurrentPage(currentPage - 1);
        }
      })
      .catch((error) => setError(error.message));
  };

  const handleClosePopup = () => {
    setEditPopupOpen(false);
    setSelectedItem(null);
  };

  const handleViewClosePopup = () => {
    setViewPopupOpen(false);
    setViewItemId(null);
  };

  const handleView = (item) => {
    setViewItemId(item.billing.id);
    setViewPopupOpen(true);
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setEditPopupOpen(true);
  };

  const handleWarehouseChange = (event) => {
    setSelectedWarehouse(event.target.value);
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleItemsPerPageChange = (e) => {
    setCurrentPage(1);
    setItemsPerPage(parseInt(e.target.value));
  };

  const clearFilter = () => {
    setEndDate(null);
    setStartDate(null);
    setCurrentPage(1);
  };

  const filteredData = data.filter((item) => {
    const createdAt = parseISO(item.billing.createdAt);

    const isDateInRange = isWithinInterval(createdAt, {
      start: startDate ? startOfDay(startDate) : new Date(0), // Minimum date if no startDate
      end: endDate ? endOfDay(endDate) : new Date(8640000000000000), // Maximum date if no endDate
    });

    return (
      item.billing &&
      item.billing.invoice.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (selectedWarehouse ? item.warehouseName === selectedWarehouse : true) &&
      isDateInRange
    );
  });
  // Filter data based on selected payment mode
  const filteredItems = filteredData.filter((item) => {
    // If 'all' is selected, return all items, otherwise filter by payment mode
    return (
      selectedPaymentMode === "all" ||
      item.billing.paymentMode === selectedPaymentMode
    );
  });

  // Sort the filtered data based on the createdAt date in descending order
  const sortedData = [...filteredItems].sort((a, b) => {
    return new Date(b.billing.createdAt) - new Date(a.billing.createdAt);
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  if (loading) {
    return (
      <p className="text-center text-lg font-bold text-red-500 ml-64 mt-60">
        Please Wait Loading...
      </p>
    );
  }

  return (
    <div className="bg-gray-100 ml-64 mt-20 p-12 h-screen">
      <div className="container mx-auto">
        <div className="mb-10">
          <h1 className="font-extrabold text-3xl text-left text-gray-800">
            Sales
          </h1>
          <div className="mt-2 border-t border-gray-300"></div>
        </div>
        <div className="flex items-center mb-5">
          <div className="ml-0">
            <form>
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search invoice"
                  value={searchQuery}
                  onChange={handleSearch}
                  className="border border-gray-300 px-4 py-2 pl-10 rounded-lg focus:outline-none focus:border-blue-500"
                />
                <img
                  src={Searchicon}
                  alt="search"
                  className="absolute left-3 top-3 w-4 h-4"
                />
              </div>
            </form>
          </div>
          <div className="ml-4">
            <select
              value={selectedWarehouse}
              onChange={handleWarehouseChange}
              className="border border-gray-300 px-4 py-2 rounded-lg focus:outline-none focus:border-blue-500"
            >
              <option value="">All Warehouses</option>
              {warehouses.map((warehouse) => (
                <option key={warehouse.id} value={warehouse.name}>
                  {warehouse.name}
                </option>
              ))}
            </select>
          </div>

          <div className="ml-auto flex items-center">
            <div className="flex items-center">
              <span className="mx-2">Start</span>
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  setCurrentPage(1);
                }}
                placeholderText="Start Date"
                dateFormat="yyyy/MM/dd"
                className="border border-gray-300 px-4 py-2 rounded-lg focus:outline-none focus:border-blue-500"
              />
              <span className="mx-2">End</span>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                placeholderText="End Date"
                dateFormat="yyyy/MM/dd"
                className="border border-gray-300 px-4 py-2 rounded-lg focus:outline-none focus:border-blue-500"
              />
              <button
                onClick={clearFilter}
                className=" ml-4 p-2 bg-orange-400 hover:bg-red-500 rounded-lg"
              >
                {" "}
                Clear Filter
              </button>
            </div>
          </div>
        </div>

        {filteredItems.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
              <thead className="bg-blue-500">
                <tr>
                  <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">
                    S.NO
                  </th>
                  <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">
                    INVOICE
                  </th>
                  <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">
                    CUSTOMER NAME
                  </th>
                  <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider hidden">
                    STATUS
                  </th>
                  <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">
                    TOTAL PRICE
                  </th>
                  <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">
                    PAYMENT MODE
                    <button
                      onClick={handlePaymentModeToggle}
                      className="ml-2 text-white hover:text-gray-300 font-extrabold bg-yellow-500 px-1"
                    >
                      ↓↑
                    </button>
                    <span className="text-sm ml-1">
                      (
                      {selectedPaymentMode === "all"
                        ? "All"
                        : selectedPaymentMode.charAt(0).toUpperCase() +
                          selectedPaymentMode.slice(1)}
                      )
                    </span>
                  </th>
                  <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider hidden">
                    INVOICE DATE
                  </th>
                  <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">
                    CREATED AT
                  </th>
                  <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">
                    ACTION
                  </th>
                </tr>
              </thead>

              <tbody className="divide-y divide-gray-200">
                {currentItems.map((item, index) => {
                  const rowClass =
                    item.billing.status === "Paid"
                      ? "bg-white"
                      : "bg-gradient-to-r from-red-500 to-yellow-300 via-orange-500 text-black hover:text-white";

                  return (
                    <tr
                      key={item.billing.id}
                      className={`hover:bg-green-200 ${rowClass}`}
                    >
                      <td className="py-3 px-4 text-left">
                        {indexOfFirstItem + index + 1}
                      </td>
                      <td className="py-3 px-4 text-left text-nowrap">
                        {item.billing.invoice}
                      </td>
                      <td className="py-3 px-4 text-left">
                      <Link to={`/Customer-Analytics/${item.billing.customerId}/${encodeURIComponent(item.customer)}`} className="text-blue-500 hover:underline">
                        {item.customer}
                        </Link>
                      </td>
                      <td className="py-3 px-4 text-left hidden">
                        {item.billing.status}
                      </td>
                      <td className="py-3 px-4 text-left">
                        ₹ {item.billing.totalPrice}
                      </td>
                      <td className="py-3 px-4 text-left">
                        <span className="bg-yellow-200 border border-yellow-400 px-2 py-1 rounded font-bold">
                          {item.billing.paymentMode === "digital"
                            ? "UPI"
                            : item.billing.paymentMode.toUpperCase()}
                        </span>
                      </td>
                      <td className="py-3 px-4 text-left hidden">
                        {item.billing.invoiceDate}
                      </td>
                      <td className="py-3 px-4 text-left text-nowrap">
                        {(() => {
                          const date = new Date(item.billing.createdAt);
                          const options = {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          };
                          return date.toLocaleString(undefined, options);
                        })()}
                      </td>
                      <td className="px-4 py-2 flex justify-center">
                        <button
                          className="flex items-center p-2 text-white rounded focus:outline-none"
                          onClick={() => handleView(item)}
                        >
                          <img
                            src={Eye}
                            className="w-6 h-6 mr-2"
                            alt="Eye Icon"
                          />
                        </button>
                        <button
                          className="text-blue-500"
                          onClick={() => handleEdit(item)}
                        >
                          <FaRegEdit size={22} />
                        </button>
                        <button
                          className="text-red-500"
                          onClick={() => handleDelete(item)}
                        >
                          <IoMdTrash size={30} />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-center text-gray-500">No sales data found</p>
        )}

        <div className="flex justify-between items-center mt-5 pb-10">
          <div>
            <label htmlFor="itemsPerPage" className="mr-2">
              Items per page:
            </label>
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              className="border border-gray-300 rounded-lg px-2 py-1"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </select>
          </div>
          <div className="flex space-x-2">
            <span className="px-3 py-1">
              {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => currentPage > 1 && paginate(currentPage - 1)}
              className={`px-3 py-1 rounded ${
                currentPage === 1
                  ? "bg-gray-200 text-gray-700"
                  : "bg-blue-500 text-white"
              }`}
            >
              Previous
            </button>
            <button
              onClick={() =>
                currentPage < totalPages && paginate(currentPage + 1)
              }
              className={`px-3 py-1 rounded ${
                currentPage === totalPages
                  ? "bg-gray-200 text-gray-700"
                  : "bg-blue-500 text-white"
              }`}
            >
              Next
            </button>
          </div>
        </div>

        {isEditPopupOpen && (
          <SalesEdit
            isOpen={isEditPopupOpen}
            onClose={handleClosePopup}
            initialData={selectedItem}
            fetchBillingData={fetchBillingData}
          />
        )}
        {isViewPopupOpen && (
          <SalesView
            isOpen={isViewPopupOpen}
            onClose={handleViewClosePopup}
            itemId={viewItemId}
          />
        )}

        {isDeletePopupOpen && itemToDelete && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded shadow-md text-center">
              <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
              <p className="mb-4">
                Are you sure you want to delete the invoice{" "}
                <strong className="text-red-600">
                  {itemToDelete.billing.invoice}
                </strong>
                ?
              </p>
              <div className="flex justify-between space-x-4">
                <button
                  onClick={() => setDeletePopupOpen(false)}
                  className="bg-green-400 text-gray-700 px-4 py-2 rounded hover:bg-green-700"
                >
                  Cancel
                </button>
                <button
                  onClick={confirmDelete}
                  className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default Sales;
