import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';

const PurchaseReturn = ({ isOpen, onClose, purchaseId, warehouseId }) => {
    const [purchaseDetails, setPurchaseDetails] = useState(null);
    const [qualityChecks, setQualityChecks] = useState([]);

    useEffect(() => {
        if (isOpen) {
            fetchPurchaseValue();
        }
    }, [isOpen, purchaseId, warehouseId]);

    const fetchPurchaseValue = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                toast.error('No token found. Please log in again.');
                return;
            }

            const response = await fetch(`https://pos.farm2bag.com/api/v1/purchaseDetails/quality-return?id=${purchaseId}&warehouseId=${warehouseId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                setPurchaseDetails(data.purchaseDetails);
                setQualityChecks(data.matchedQualityChecks);
            } else {
                toast.error('Failed to fetch purchase return.');
            }
        } catch (error) {
            console.error('Error fetching purchase return:', error);
            toast.error('Error fetching purchase return.');
        }
    };

    const handleClose = () => {
        setPurchaseDetails(null);
        setQualityChecks([]);
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="Purchase Return" ariaHideApp={false} className='bg-gray-100 ml-64 mt-20 p-12 h-screen'>
            <div className="container mx-auto">
                <div className='flex justify-between'>
                    <h2 className="font-extrabold text-4xl text-left text-teal-600">Purchase Return</h2>
                    <button onClick={handleClose} className='py-1 px-2 bg-red-500 rounded-lg text-white'>Close</button>   
                </div>
                <div className="mt-2 border-t border-teal-600"></div>
                {purchaseDetails && (
                    <div>
                        <div className="grid grid-cols-2 gap-4 justify-items-center mt-2">
                            <h3 className="text-lg font-bold text-white bg-gradient-to-r from-orange-400 to-yellow-400 p-4 rounded-lg shadow-md">Purchase Order : <span className='text-teal-600'>{purchaseDetails.purchaseOrder}</span></h3>
                            <h4 className="text-lg font-bold text-white bg-gradient-to-r from-orange-400 to-yellow-400 p-4 rounded-lg shadow-md">Warehouse ID : <span className='text-teal-600 uppercase'>{purchaseDetails.warehouseName}</span></h4>
                        </div>

                        <div className="mt-4">
                            {qualityChecks.length === 0 ? (
                                <p className='text-center mt-5 font-bold'>No quality checks available.</p>
                            ) : (
                                <table className="min-w-full bg-white border border-gray-300">
                                    <thead>
                                        <tr className="bg-blue-500">
                                            <th className="border text-white px-4 py-2">Invoice No</th>
                                            <th className="border text-white px-4 py-2">Invoice Date</th>
                                            <th className="border text-white px-4 py-2">Product ID</th>
                                            <th className="border text-white px-4 py-2">Quantity</th>
                                            <th className="border text-white px-4 py-2">Price</th>
                                            <th className="border text-white px-4 py-2">Return Quantity</th>
                                            <th className="border text-white px-4 py-2">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {qualityChecks.map((check) => (
                                            check.product.map((product) => (
                                                <tr key={product.productId} className="hover:bg-gray-100">
                                                    {product === check.product[0] ? (
                                                        <>
                                                            <td className="border border-gray-300 px-4 py-2" rowSpan={check.product.length}>{check.invoiceNo}</td>
                                                            <td className="border border-gray-300 px-4 py-2" rowSpan={check.product.length}>{new Date(check.invoiceDate).toLocaleDateString()}</td>
                                                        </>
                                                    ) : null}
                                                    <td className="border border-gray-300 px-4 py-2">{product.name}</td>
                                                    <td className="border border-gray-300 px-4 py-2">{product.quantity}</td>
                                                    <td className="border border-gray-300 px-4 py-2">{product.price}</td>
                                                    <td className="border border-gray-300 px-4 py-2">{product.returnQuantity}</td>
                                                    <td className="border border-gray-300 px-4 py-2">{product.status}</td>
                                                </tr>
                                            ))
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default PurchaseReturn;