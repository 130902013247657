import React, { useState, useEffect } from "react";
import Select from "react-select";
import JsBarcode from "jsbarcode";
import "tailwindcss/tailwind.css";
import { IoMdTrash } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";

const BarcodeGenerator = () => {
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [warehouse, setWarehouse] = useState("");
  const [warehouses, setWarehouses] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [stockData, setStockData] = useState([]);
  const [paperSize, setPaperSize] = useState("");
  const [showCompanyName, setShowCompanyName] = useState(true);
  const [showProductName, setShowProductName] = useState(true);
  const [showPrice, setShowPrice] = useState(true);
  const [barcodes, setBarcodes] = useState([]);
  const [isBarcodeGenerated, setIsBarcodeGenerated] = useState(false);
  const [expiryDate, setExpiryDate] = useState('');
  const [manufactureDate, setManufactureDate] = useState('');

  const paperSizes = [
    "1 per sheet (2 * 1)",
    "40 per sheet (a4) (1.799 * 1.003)",
    "30 per sheet (2.625 * 1)",
    "24 per sheet (a4) (2.48 * 1.334)",
    "20 per sheet (4 * 1)",
    "18 per sheet (a4) (2.5 * 1.835)",
    "14 per sheet (4 * 1.33)",
    "12 per sheet (a4) (2.5 * 2.834)",
  ];

  useEffect(() => {
    const fetchWarehouses = async () => {
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          console.error("No token found in local storage");
          return;
        }

        const response = await fetch("https://pos.farm2bag.com/api/v1/warehouse", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();
        setWarehouses(responseData.data);
      } catch (error) {
        console.error("Error fetching warehouses:", error);
      }
    };

    fetchWarehouses();
  }, []);

  const handleWarehouseChange = async (selectedOption) => {
    try {
      setWarehouse(selectedOption.value);
      setSelectedWarehouse(selectedOption);
      setProducts([]);
      setSelectedProduct(null);
      setIsBarcodeGenerated(false);
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("No token found in local storage");
        return;
      }

      const response = await fetch(
        `https://pos.farm2bag.com/api/v1/stocks?wareHouseId=${selectedOption.value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();

      const filteredStockData = responseData.data.filter(
        (stock) => stock.wareHouseId === selectedOption.value
      );
      setStockData(filteredStockData[0]?.products || []);
    } catch (error) {
      console.error("Error fetching stock data:", error);
    }
  };

  const handleProductSelect = (selectedOption) => {
    setSelectedProduct(selectedOption);

    // Check if the product is already in the products array
    const productExists = products.some(
      (p) => p.id === selectedOption.value
    );

    if (!productExists) {
      const selectedProduct = stockData.find(
        (p) => p.productId === selectedOption.value
      );
      if (selectedProduct) {
        const newProduct = {
          id: selectedProduct.productId,
          name: selectedProduct.name,
          productsCode: selectedProduct.productsCode,
          qty: 1,
          price: selectedProduct.price,
          date: selectedProduct.exp_Date,
          key: new Date().getTime(),
        };
        setProducts([...products, newProduct]);
      }
    } else {
      // Optionally, you can show a notification or alert to the user
      console.log("Product already added");
    }
  };

  const updateProductQty = (index, qty) => {
    const newProducts = [...products];
    newProducts[index].qty = qty;
    setProducts(newProducts);
  };
  // Function to update manufacture date for a specific product
  const updateProductManufactureDate = (index, value) => {
    const updatedProducts = [...products];  // Create a copy of the products array
    updatedProducts[index].manufactureDate = value;  // Update the manufacture date
    setProducts(updatedProducts);  // Update the state with the modified array
  };

  // Function to update expiry date for a specific product
  const updateProductExpiryDate = (index, value) => {
    const updatedProducts = [...products];  // Create a copy of the products array
    updatedProducts[index].expiryDate = value;  // Update the expiry date
    setProducts(updatedProducts);  // Update the state with the modified array
  };


  const handleReset = async () => {
    setBarcodes([]);
    setIsBarcodeGenerated(false);
    setWarehouse("");
    setProducts([]);
    setPaperSize("");
    setSelectedWarehouse("");
    setSelectedProduct(null);
    setExpiryDate("");
    setManufactureDate("");

    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("No token found in local storage");
        return;
      }

      const response = await fetch("https://pos.farm2bag.com/api/v1/warehouse", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      setWarehouses(responseData.data);
    } catch (error) {
      console.error("Error fetching warehouses:", error);
    }

    setStockData([]);
  };

  const generateBarcode = () => {
    const newBarcodes = [];
    products
      .filter((product) => product.id !== "")
      .forEach((product) => {
        for (let i = 0; i < product.qty; i++) {
          const svgElement = document.createElement("svg");
          JsBarcode(svgElement, `${product.productsCode.trim()}`, {
            format: "CODE128",
            width: 1.5,
            height: 20,
            font: "Arial",
            textMargin: 0,
            fontSize: 18,
            displayValue: false,
            margin: 0,
          });
          svgElement.classList.add("w-24");

          newBarcodes.push({
            svg: svgElement.outerHTML,
            name: product.name,
            price: product.price,
            id: product.id,
            productsCode: product.productsCode,
            expiryDate: product.expiryDate,             // Use expiry date from state
            manufactureDate: product.manufactureDate,   // Use manufacture date from state
            barcodeKey: `${product.key}-${i}`,
          });
        }
      });
    setBarcodes(newBarcodes);
    setIsBarcodeGenerated(newBarcodes.length > 0);

    console.log("newBarcodes", newBarcodes);
  };

  const printBarcode = () => {
    if (!isBarcodeGenerated) {
      console.warn("Barcode not generated yet.");
      return;
    }

    const printContents = document.getElementById("barcode-preview").innerHTML;

    if (!printContents) {
      console.warn("No content found to print.");
      return;
    }

    const printWindow = window.open("", "_blank");

    if (!printWindow) {
      console.error("Failed to open the print window.");
      return;
    }


    printWindow.document.write(`
      <html>
          <head>
              <title>Print Barcodes</title>
              <style>
                  /* Styles for printing */
                  body, html {
                      margin: 0;
                      padding: 0;
                      width: 48mm; /* Set the width to match POS58L printer */
                      height: 25mm;
                      font-family: Arial, sans-serif;
                      box-sizing: border-box;
                  }
                      .price{
                      font-weight:bold;
                      }
                      .barcode-label{
                      margin-bottom:1mm;
                      }
                  .print-container {
                      width: 48mm;
                      height: 25mm;
                      box-sizing: border-box;
                      border: none; /* Remove border for print */
                  }
                  .barcode-svg {
                      margin-left: 2mm;
                      margin-right: 12mm;
                      margin-bottom: 1mm;
                      width: 80%;
                      height: auto;
                  }
                  @media print {
                      /* Ensure no border during print */
                      .barcode-item {
                          border: none !important; /* Remove border during print */
                          page-break-inside: avoid;
                          page-break-before: avoid;
                          page-break-after: avoid;
                          break-inside: avoid;
                          break-before: avoid;
                          break-after: avoid;
                      }
                      /* Hide the default header and footer */
                      @page {
                          margin: 0;
                          size: 48mm 25mm;
                      }
                  }
              </style>
          </head>
          <body>
              <div class="print-container">
                  ${printContents}
              </div>
          </body>
      </html>
  `);

    printWindow.document.close();

    printWindow.onload = () => {
      printWindow.focus();
      printWindow.print();

      printWindow.onafterprint = () => {
        printWindow.close();
      };
    };
  };

  const getGridColumns = (paperSize) => {
    switch (paperSize) {
      case "1 per sheet (2 * 1)":
        return 1;
      case "40 per sheet (a4) (1.799 * 1.003)":
        return 4;
      case "30 per sheet (2.625 * 1)":
        return 4;
      case "24 per sheet (a4) (2.48 * 1.334)":
        return 4;
      case "20 per sheet (4 * 1)":
        return 4;
      case "18 per sheet (a4) (2.5 * 1.835)":
        return 3;
      case "14 per sheet (4 * 1.33)":
        return 2;
      case "12 per sheet (a4) (2.5 * 2.834)":
        return 2;
      default:
        return 1;
    }
  };

  const columns = getGridColumns(paperSize);

  const handleDeleteProduct = (index) => {
    const updatedProducts = products.filter((_, idx) => idx !== index);
    setProducts(updatedProducts);

    const deletedProductKey = products[index].key;
    const updatedBarcodes = barcodes.filter(
      (barcode) => !barcode.barcodeKey.startsWith(`${deletedProductKey}-`)
    );
    setBarcodes(updatedBarcodes);
    setIsBarcodeGenerated(updatedBarcodes.length > 0);
    toast.info(`Product successfully removed from the list`, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  // Converts yyyy-mm to mm/yyyy format
  const convertToMonthYearFormat = (date) => {
    if (!date) return '';
    const [year, month] = date.split('-');
    return `${month}-${year}`;
  };

  // Converts mm/yyyy back to yyyy-mm for input value
  const formatMonthYear = (date) => {
    if (!date) return '';
    const [month, year] = date.split('-');
    return `${year}-${month}`;
  };


  return (
    <div className="bg-gray-100 ml-64 mt-20 p-12 min-h-screen">
      <div className="container mx-auto flex flex-col flex-1">
        <div className="mb-10">
          <h1 className="font-extrabold text-3xl text-left text-gray-500">
            Print Barcode
          </h1>
          <div className="mt-2 border-t border-gray-500 w-full"></div>
        </div>

        <div className="container mx-auto grid grid-cols-2 gap-6 mb-6">
          {/* Warehouse Selector */}
          <div className="flex flex-col">
            <span className="text-lg text-gray-700 font-semibold mb-2 text-left">
              Warehouse
            </span>
            <div className="relative">
              <Select
                className="w-full"
                classNamePrefix="react-select"
                onChange={handleWarehouseChange}
                options={warehouses.map((wh) => ({
                  value: wh.id,
                  label: wh.name,
                }))}
                placeholder="Select Warehouse"
                isSearchable
                value={selectedWarehouse}
                styles={{
                  control: (base) => ({
                    ...base,
                    padding: "4px",
                    backgroundColor: "rgb(229 231 235)",
                    borderRadius: "0.5rem",
                  }),
                  menu: (base) => ({
                    ...base,
                    borderRadius: "0.5rem",
                    backgroundColor: "rgb(229 231 235)",
                  }),
                }}
              />
            </div>
          </div>

          {/* Product Selector */}
          <div className="flex flex-col">
            <span className="text-lg text-gray-700 font-semibold mb-2 text-left">
              Product
            </span>
            <div className="relative">
              <Select
                value={selectedProduct}
                className="w-full"
                classNamePrefix="react-select"
                onChange={handleProductSelect}
                options={stockData.map((prod) => ({
                  value: prod.productId,
                  label: `${prod.name} (${prod.productsCode})`,
                }))}
                placeholder="Select Product"
                isSearchable
                styles={{
                  control: (base) => ({
                    ...base,
                    padding: "4px",
                    borderRadius: "0.5rem",
                    backgroundColor: "rgb(229 231 235)",
                  }),
                  menu: (base) => ({
                    ...base,
                    borderRadius: "0.5rem",
                    backgroundColor: "rgb(229 231 235)",
                  }),
                }}
              />
            </div>
          </div>
        </div>

        {products.length > 0 && (
          <div className="mb-4">
            <table className="table-auto w-full rounded-lg overflow-hidden">
              <thead>
                <tr className="bg-gray-200">
                  <th className="p-3 text-center text-gray-700 w-1/6">
                    PRODUCT
                  </th>
                  <th className="p-3 text-center text-gray-700">
                    LABEL QUANTITY
                  </th>
                  <th className="p-3 text-center text-gray-700">
                    PRICE
                  </th>
                  <th className="p-3 text-center text-gray-700">
                    MANUFACTURE DATE
                  </th>
                  <th className="p-3 text-center text-gray-700">
                    EXPIRY DATE
                  </th>
                  <th className="p-3 text-center text-gray-700">
                    ACTION
                  </th>
                </tr>
              </thead>
              <tbody>
                {products.map((product, index) => (
                  <tr
                    key={product.id}
                    className={index % 2 === 0 ? "bg-white" : "bg-gray-200"}
                  >
                    <td className="p-3">{`${product.name} (${product.productsCode})`}</td>
                    <td className="p-3">
                      <input
                        type="number"
                        className="form-input p-2 rounded-lg text-center w-16 outline-none border border-gray-300"
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "white" : "#e5e7eb", // Tailwind's gray-200
                        }}
                        value={product.qty}
                        onChange={(e) =>
                          updateProductQty(index, parseInt(e.target.value, 10))
                        }
                        min="1"
                      />
                    </td>
                    <td className="p-3">{product.price.toFixed(2)}</td>
                    <td className="p-3">
                      <input
                        type="month"
                        className="form-input p-2 rounded-lg text-center w-full outline-none border border-gray-300"
                        value={convertToMonthYearFormat(product.manufactureDate)} // Converts the value to mm-yyyy
                        onChange={(e) =>
                          updateProductManufactureDate(index, formatMonthYear(e.target.value)) // Converts and updates the value
                        }
                      />
                    </td>
                    {/* Expiry Date */}
                    <td className="p-3">
                      <input
                       type="month"
                        className="form-input p-2 rounded-lg text-center w-full outline-none border border-gray-300"
                        value={convertToMonthYearFormat(product.expiryDate)}
                        onChange={(e) =>
                          updateProductExpiryDate(index, formatMonthYear(e.target.value))
                        }
                      />
                    </td>

                    <td className="p-3">
                      <button
                        onClick={() => handleDeleteProduct(index)}
                        className="p-2 text-white rounded-lg"
                      >
                        <IoMdTrash
                          size={30}
                          style={{ color: "red" }}
                          className="inline-block mr-1"
                        />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <div className="flex items-center mb-4">
          <div className="flex flex-col mr-4">
            <label className="block mb-2 text-lg font-semibold text-left">
              <span className="text-gray-700">Paper Size</span>
              <select
                className="mt-1 block p-2 pr-10 border-2 w-80 form-select rounded-lg bg-gray-200"
                value={paperSize}
                onChange={(e) => setPaperSize(e.target.value)}
              >
                <option value="">Choose Paper Size</option>
                {paperSizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </label>
          </div>

          <div className="flex space-x-4 justify-center items-center mt-8">
            <div className="flex flex-col items-center">
              <span className="mb-1">Show Company</span>
              <div
                className={`w-12 h-6 flex items-center bg-gray-300 rounded-full p-1 cursor-pointer ml-8 mr-24 ${showCompanyName ? "bg-green-600" : "bg-gray-300"
                  }`}
                onClick={() => setShowCompanyName(!showCompanyName)}
              >
                <div
                  className={`bg-white w-4 h-4 rounded-full shadow-md transform ${showCompanyName ? "translate-x-6" : ""
                    }`}
                ></div>
              </div>
            </div>

            <div className="flex flex-col items-center">
              <span className="mb-1">Show Product</span>
              <div
                className={`w-12 h-6 flex items-center bg-gray-300 rounded-full p-1 cursor-pointer ml-12 mr-24 ${showProductName ? "bg-green-600" : "bg-gray-300"
                  }`}
                onClick={() => setShowProductName(!showProductName)}
              >
                <div
                  className={`bg-white w-4 h-4 rounded-full shadow-md transform ${showProductName ? "translate-x-6" : ""
                    }`}
                ></div>
              </div>
            </div>

            <div className="flex flex-col items-center">
              <span className="mb-1">Show Price</span>
              <div
                className={`w-12 h-6 flex items-center bg-gray-300 rounded-full p-1 cursor-pointer mr-24 ml-16 ${showPrice ? "bg-green-600" : "bg-gray-300"
                  }`}
                onClick={() => setShowPrice(!showPrice)}
              >
                <div
                  className={`bg-white w-4 h-4 rounded-full shadow-md transform ${showPrice ? "translate-x-6" : ""
                    }`}
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex space-x-2 mb-4">
          <button
            className="bg-green-600 text-white py-2 px-4 rounded"
            onClick={generateBarcode}
          >
            Preview
          </button>
          <button
            className="bg-red-600 text-white py-2 px-4 rounded"
            onClick={handleReset}
          >
            Reset
          </button>
          <button
            onClick={printBarcode}
            className={`${!isBarcodeGenerated
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-green-500 hover:bg-green-700"
              } text-white font-bold py-2 px-4 rounded`}
            disabled={!isBarcodeGenerated}
          >
            Print
          </button>
        </div>

        {isBarcodeGenerated && (
          <div
            id="barcode-preview"
            className={`grid grid-cols-${columns} gap-2`} // Dynamically set the number of columns
            style={{
              width: "60%", // Full width of the container
              padding: "10px", // Padding for better spacing in the preview
              boxSizing: "border-box",
            }}
          >
            {barcodes.map((barcode, index) => (
              <div
                key={index}
                className="barcode-item"
                style={{
                  width: "48mm", // Adjust width according to paper size
                  height: "25mm", // Adjust height according to paper size
                  border: "1px solid #000", // Border applied for preview
                  boxSizing: "border-box",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  margin: "auto", // Center the item within the grid
                }}
              >
                {showCompanyName && (
                  <h5
                    className="barcode-label"
                    style={{
                      fontSize: "8px",
                      marginTop: "2px", // Adjust margins for spacing
                      fontWeight: "bold",
                    }}
                  >
                    FARM2BAG
                  </h5>
                )}
                {showProductName && (
                  <p
                    style={{
                      fontSize: "8px",
                      margin: "0",
                    }}
                  >
                    {barcode.name}
                  </p>
                )}
                {showPrice && (
                  <p
                    style={{
                      fontSize: "8px",
                      margin: "0",
                      fontWeight: "bold"
                    }}
                  >
                    Price:₹{barcode.price.toFixed(2)}
                  </p>
                )

                }
                <div className="flex">
                  <p
                    style={{
                      fontSize: "8px",
                      margin: "0",
                      fontWeight: "bold"
                    }}
                  >
                    MFG: {barcode.manufactureDate} ▮ EXP: {barcode.expiryDate}
                  </p>
                </div>
                <div
                  className="barcode-svg"
                  dangerouslySetInnerHTML={{ __html: barcode.svg }}
                  style={{
                    width: "80%", // Adjust width to fit within container
                    height: "auto",
                    marginTop: "1mm",
                    marginLeft: "12mm"
                  }}
                />
                <p
                  style={{
                    fontSize: "8px",
                    marginTop: "0",
                  }}
                >
                  {barcode.productsCode}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default BarcodeGenerator;
