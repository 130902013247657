import React, { useState, useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
import "../scss/chart.scss";

const TopFive = ({ warehouseId }) => {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);

  const divStyle = {
    boxShadow: "none",
  };

  const fetchChartData = async () => {
    const token = localStorage.getItem('token'); 

    try {
      let url = 'https://pos.farm2bag.com/api/v1/billingItems/graph';
      if (warehouseId) {
        url += `?warehouseId=${warehouseId}`;
      }

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`, 
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }

      const result = await response.json();
      const topFiveProducts = result.data["Top Five Products"];
      const totalPrices = topFiveProducts.map(product => product.totalPrice);
      const productNames = topFiveProducts.map(product => product.productName);

      setChartData(totalPrices);
      setChartLabels(productNames);

    } catch (error) {
      console.error('Error fetching chart data:', error);
    }
  };

  useEffect(() => {
    fetchChartData();
  }, [warehouseId]); 

  useEffect(() => {
    if (chartData.length > 0 && chartLabels.length > 0) {
      const ctx = document.getElementById('myBarChart').getContext('2d');

      if (chartRef.current) {
        chartRef.current.destroy();
      }

      const myChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: chartLabels,
          datasets: [{
            data: chartData,
            backgroundColor: [
              'rgba(255, 99, 132, 0.6)',
              'rgba(255, 159, 64, 0.6)',
              'rgba(255, 205, 86, 0.6)',
              'rgba(75, 192, 192, 0.6)',
              'rgba(54, 162, 235, 0.6)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(255, 159, 64, 1)',
              'rgba(255, 205, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(54, 162, 235, 1)',
            ],
            borderWidth: 1,
            barPercentage: 0.5,
            categoryPercentage: 0.8,
          }],
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            y: {
              type: 'linear',
              beginAtZero: true,
              stepSize: 5000,
            },
            x: {
              ticks: {
                callback: (value) => {
                  const maxLength = 10;
                  return value.length > maxLength ? `${value.substring(0, maxLength)}...` : value;
                },
                autoSkip: true,
                maxTicksLimit: 5,
                maxRotation: 0, 
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (tooltipItem) => {
                  const price = tooltipItem.raw; 
                  return `₹ ${price.toLocaleString('en-IN')}`; 
                }
              }
            },
            title: {
              display: false,
            }
          }
        },
      });

      chartRef.current = myChart;

      return () => {
        if (myChart) {
          myChart.destroy();
        }
      };
    }
  }, [chartData, chartLabels]);

  return (
    <div className="bg-white rounded-lg shadow-md" style={divStyle}>

      <div className="w-full h-96">
        <canvas id="myBarChart"></canvas>
      </div>
      <div className="flex justify-center">
        <h1 className="text-xl text-center font-bold text-blue-600 w-full">Top 5 Products by Unit Sales</h1>
      </div>
    </div>
  );
};

export default TopFive;
