const BASE_URL = 'https://pos.farm2bag.com/api/v1/purchaseDetails';
const VENDORS_URL = 'https://pos.farm2bag.com/api/v1/vendors';
const MEASUREMENTS_URL = 'https://pos.farm2bag.com/api/v1/measurements';
const CATEGORIES_URL = 'https://pos.farm2bag.com/api/v1/categories';
const PRODUCTS_URL = 'https://pos.farm2bag.com/api/v1/products/all';
const GST_URL = 'https://pos.farm2bag.com/api/v1/gst';

const fetchPurchaseById = async (id) => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('No token found in local storage');
    }

    const response = await fetch(`${BASE_URL}/${id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Failed to fetch purchase details');
    }

    const data = await response.json();
    return data;
};



const handleEditPurchase = async (purchaseDetails, status, fetchData, onClose) => {
    try {
        const token = localStorage.getItem('token');
        const role = localStorage.getItem('role');
        if (!token) {
            console.error('No token found in local storage');
            return;
        }

        const response = await fetch(`${BASE_URL}/${purchaseDetails.id}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...purchaseDetails, // Send all existing purchase details
                status, // Update the status field
                modifiedBy: role, // Update with the role of the person modifying
            }),
        });

        if (!response.ok) throw new Error('Failed to edit purchase');
        fetchData(); // Refetch the data
        onClose(); // Close the modal
    } catch (error) {
        console.error('Error editing purchase:', error);
    }
};

const fetchVendors = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('No token found in local storage');
    }

    const response = await fetch(VENDORS_URL, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Failed to fetch vendors');
    }

    const data = await response.json();
    return data;
};


const fetchMeasurements = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('No token found in local storage');
    }

    const response = await fetch(MEASUREMENTS_URL, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Failed to fetch measurements');
    }

    const data = await response.json();
    return data;
};


const fetchCategories = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('No token found in local storage');
    }

    const response = await fetch(CATEGORIES_URL, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Failed to fetch categories');
    }

    const data = await response.json();
    return data;
};

const fetchProducts = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('No token found in local storage');
    }

    const response = await fetch(PRODUCTS_URL, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Failed to fetch products');
    }

    const data = await response.json();
    return data;
};



const fetchGstData = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('No token found in local storage');
    }

    const response = await fetch(GST_URL, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Failed to fetch products');
    }

    const data = await response.json();
    return data;
};


// Export the updated API functions
export default {
    fetchPurchaseById,
    handleEditPurchase,
    fetchVendors,
    fetchMeasurements,
    fetchCategories,
    fetchProducts,
    fetchGstData
};
