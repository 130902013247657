import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const EditCapitalPopup = ({ capital, onClose, onUpdate }) => {
    const [updateCapital, setUpdateCapital] = useState({
        date: '',
        capitalTypeId: '',
        amount: '',
        description: '',
        warehouseId: '',
    });

    const [warehouses, setWarehouses] = useState([]);
    const [capitalTypes, setCapitalTypes] = useState([]); // Initialize as an empty array

    useEffect(() => {
        const fetchWarehouses = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.error('No token found in local storage');
                    return;
                }

                const response = await fetch('https://pos.farm2bag.com/api/v1/warehouse', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch warehouses');
                }

                const data = await response.json();
                setWarehouses(data.data || []); // Ensure that we have an array
            } catch (error) {
                console.error('Error fetching warehouses:', error);
                toast.error('Error fetching warehouses: ' + error.message);
            }
        };

        const fetchCapitalTypes = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.error('No token found in local storage');
                    return;
                }

                const response = await fetch('https://pos.farm2bag.com/api/v1/capitalTypes', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch capital types');
                }

                const data = await response.json();
                setCapitalTypes(data.data || []); // Ensure that we have an array
            } catch (error) {
                console.error('Error fetching capital types:', error);
                toast.error('Error fetching capital types: ' + error.message);
            }
        };

        fetchWarehouses();
        fetchCapitalTypes();
    }, []);

    useEffect(() => {
        if (capital) {
            setUpdateCapital({
                date: capital.date,
                capitalTypeId: capital.capitalTypeId,
                amount: capital.amount,
                description: capital.description,
                warehouseId: capital.warehouseId || '',
            });
        }
    }, [capital]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUpdateCapital(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleUpdateCapital = async () => {
        try {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');

            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const { date, capitalTypeId, amount, description, warehouseId } = updateCapital;

            if (!date || !capitalTypeId || amount < 0 || !description || !warehouseId) {
                toast.error('Please fill in all fields correctly and ensure the amount is not negative');
                return;
            }

            const id = capital.id;

            const response = await fetch(`https://pos.farm2bag.com/api/v1/capital/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    date,
                    capitalTypeId,
                    amount,
                    description,
                    warehouseId,
                    modifiedBy: role,
                })
            });

            if (!response.ok) {
                throw new Error('Failed to update capital');
            }

            console.log('Capital updated successfully');
            onClose();
            onUpdate();
            toast.success('Capital updated successfully');
        } catch (error) {
            console.error('Error updating capital:', error);
            toast.error('Error updating capital');
        }
    };

    return (
        <div className="fixed inset-0 flex items-center bg-gray-500 bg-opacity-50 justify-center z-50">
            <div className="bg-yellow-400 rounded-lg p-6 shadow-lg w-full md:w-3/4 lg:w-1/2">
                <h2 className="text-xl font-bold mb-4">Edit Capital</h2>
                <form className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="mb-4">
                        <label htmlFor="date" className="block text-gray-700 font-medium mb-2">Date</label>
                        <input type="date" id="date" name="date" value={updateCapital.date} onChange={handleInputChange} className="border border-gray-300 rounded-md p-2 w-full" />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="warehouseId" className="block text-gray-700 font-medium mb-2">Warehouse</label>
                        <select id="warehouseId" name="warehouseId" value={updateCapital.warehouseId} onChange={handleInputChange} className="border border-gray-300 rounded-md p-2 w-full">
                            <option value="">Select Warehouse</option>
                            {warehouses && warehouses.map(warehouse => (
                                <option key={warehouse.id} value={warehouse.id}>
                                    {warehouse.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="capitalTypeId" className="block text-gray-700 font-medium mb-2">Capital Type</label>
                        <select id="capitalTypeId" name="capitalTypeId" value={updateCapital.capitalTypeId} onChange={handleInputChange} className="border border-gray-300 rounded-md p-2 w-full">
                            <option value="">Select Type</option>
                            {capitalTypes && capitalTypes.map(type => (
                                <option key={type.id} value={type.id}>{type.capitalType}</option>
                            ))}
                        </select>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="amount" className="block text-gray-700 font-medium mb-2">Amount</label>
                        <input type="number" id="amount" name="amount" value={updateCapital.amount} onChange={handleInputChange} className="border border-gray-300 rounded-md p-2 w-full" />
                    </div>
                </form>
                <div className="mb-4">
                    <label htmlFor="description" className="block text-gray-700 font-medium mb-2">Description</label>
                    <input type="text" id="description" name="description" value={updateCapital.description} onChange={handleInputChange} className="border border-gray-300 rounded-md p-2 w-full" />
                </div>
                <div className="flex justify-between mt-4">
                    <button type="button" onClick={onClose} className="mr-4 px-4 py-2 bg-red-500 text-white rounded-lg float-left">Cancel</button>
                    <button type="button" onClick={handleUpdateCapital} className="px-4 py-2 bg-green-500 text-white rounded-lg">Save</button>
                </div>
            </div>
        </div>
    );
};

export default EditCapitalPopup;
