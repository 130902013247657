import React, { useEffect } from 'react';
import OverviewOne from '../components/OverviewOne';
import ProductOverview from '../components/ProductOverview';
import SalesProfitOverview from '../components/SalesProfitOverview';
import OrderSummaryOverview from '../components/OrderSummaryOverview';
import DatesOnDashBoard from '../components/DatesOndDashBoard';
import { Link } from 'react-router-dom';
import SalesInventoryChart from '../components/SalesInventoryChart';
import StocksVsSales from '../components/StocksVsSales';
const Dashboard = () => {
  useEffect(() => {
    // Push the current state to history to overwrite the previous state
    window.history.pushState(null, document.title, window.location.href);
    
    const handlePopState = (event) => {
      // Prevent navigating back to the login page
      window.history.pushState(null, document.title, window.location.href);
    };
    
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const divStyle = {
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Example shadow style
    padding: '10px', // Example padding
    borderRadius: '8px', // Example border radius
  };

  return (
    <div id="dashboard" className="grid grid-cols-1 gap-4 px-4 py-4 bg-gray-200 ml-64 mt-20">
      <div className="text-center">
      </div>
      <div className="gap-4 justify-items-center">
        
        <div className="bg-gray-200 rounded-lg transition duration-300 ease-in-out w-full md:auto">
          <OverviewOne style={divStyle} />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-3 bg-black-300">
        <div className="col-span-1 md:col-span-2 bg-white " style={divStyle}>
          
          <SalesProfitOverview />
        </div>
        <div className="col-span-1 md:col-span-1 bg-white" style={divStyle}>
          <OrderSummaryOverview />
          
        </div>
      </div>
      <ProductOverview /> 
      <DatesOnDashBoard/>  
      <SalesInventoryChart/>
      {/* <StocksVsSales /> */}
    </div>


  );
};

export default Dashboard;
