import React, { useEffect, useState } from 'react';

const RegisterTable = () => {
    const [registers, setRegisters] = useState([]);
    const [filteredRegisters, setFilteredRegisters] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [registersPerPage, setRegistersPerPage] = useState(10);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [totalSalesAmount, setTotalSalesAmount] = useState(0);
    const [totalCashReceived, setTotalCashReceived] = useState(0);
    const [totalCardReceived, setTotalCardReceived] = useState(0);
    const [totalDigitalReceived, setTotalDigitalReceived] = useState(0);
    const [inHandCash, setInHandCash] = useState(0);

    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchRegisters = async () => {
            const formattedFromDate = fromDate ? new Date(fromDate).toLocaleDateString('en-GB') : '';
            const formattedToDate = toDate ? new Date(toDate).toLocaleDateString('en-GB') : '';

            const url = formattedFromDate && formattedToDate
                ? `https://pos.farm2bag.com/api/v1/registers?fromDate=${formattedFromDate}&toDate=${formattedToDate}`
                : `https://pos.farm2bag.com/api/v1/registers`;

            try {
                const response = await fetch(url, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                if (data.status === 200) {
                    const sortedData = data.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                    setRegisters(sortedData);
                    setFilteredRegisters(sortedData);
                    calculateTotals(sortedData);
                } else {
                    console.error('Failed to fetch registers:', data.message);
                }
            } catch (error) {
                console.error('Error fetching registers:', error);
            }
        };

        fetchRegisters();
    }, [token, fromDate, toDate]);

    const calculateTotals = (data) => {

        const inHandCashTotal = data.reduce((acc, register) => acc + register.inHandCash, 0);
        const cashReceivedTotal = data.reduce((acc, register) => acc + register.cashReceived, 0);
        const cardReceivedTotal = data.reduce((acc, register) => acc + register.cardReceived, 0);
        const digitalReceivedTotal = data.reduce((acc, register) => acc + register.digitalReceived, 0);
        const totalSalesAmount = data.reduce((acc, register) => acc + register.totalSalesAmount, 0);

        const withOutIHandCashTotalSalesAmount = cashReceivedTotal+ cardReceivedTotal+digitalReceivedTotal;


        setTotalCashReceived(cashReceivedTotal);
        setTotalCardReceived(cardReceivedTotal);
        setTotalDigitalReceived(digitalReceivedTotal);
        setTotalSalesAmount(withOutIHandCashTotalSalesAmount);
        setInHandCash(inHandCashTotal);
    };

    const handleDateChange = (event) => {
        const { name, value } = event.target;

        if (name === 'fromDate') {
            setFromDate(value);
        } else if (name === 'toDate') {
            setToDate(value);
        }

        const filtered = registers.filter((register) => {
            const createdAtDate = new Date(register.createdAt);
            const fromDateValue = fromDate ? new Date(fromDate) : null;
            const toDateValue = toDate ? new Date(toDate) : null;

            return (!fromDateValue || createdAtDate >= fromDateValue) &&
                (!toDateValue || createdAtDate <= toDateValue);
        });

        setFilteredRegisters(filtered);
        setCurrentPage(1);
        calculateTotals(filtered);
    };

    const clearDateFilter = () => {
        setFromDate('');
        setToDate('');
        setFilteredRegisters(registers);
        setCurrentPage(1);
        calculateTotals(registers);
    };

    const formatDate = (dateString) => {
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        };
        return new Date(dateString).toLocaleString('en-GB', options).replace(',', '');
    };

    const indexOfLastRegister = currentPage * registersPerPage;
    const indexOfFirstRegister = indexOfLastRegister - registersPerPage;
    const currentRegisters = filteredRegisters.slice(indexOfFirstRegister, indexOfLastRegister);

    const nextPage = () => {
        if (currentPage < Math.ceil(filteredRegisters.length / registersPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleItemPerPageChange = (event) => {
        const value = parseInt(event.target.value, 10);
        if (!isNaN(value) && value > 0) {
            setRegistersPerPage(value);
            setCurrentPage(1); // Reset to the first page when items per page change
        }
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);
    };

    return (
        <div className='my-10'>
            <hr />
            <div className="flex items-center justify-between text-center mt-5 mb-3 p-3 bg-gradient-to-r from-red-500 to-blue-500 rounded-lg">
                <h1 className='text-2xl font-bold flex-grow text-white text-left rounded-md'>Register Table</h1>
                <div className="flex items-center space-x-4">
                    <div className="flex items-center space-x-2">
                        <label htmlFor="fromDate" className="text-sm text-white font-medium w-24 uppercase">From Date :</label>
                        <input id="fromDate" type="date" name="fromDate" value={fromDate} onChange={handleDateChange}
                            className="px-3 py-1 border border-gray-300 rounded-md" />
                    </div>
                    <div className="flex items-center space-x-2">
                        <label htmlFor="toDate" className="text-sm text-white font-medium w-24 uppercase">To Date :</label>
                        <input id="toDate" type="date" name="toDate" value={toDate} onChange={handleDateChange}
                            className="px-3 py-1 border border-gray-300 rounded-md" />
                    </div>
                    <button onClick={clearDateFilter} className="px-3 py-1 bg-red-500 text-white rounded-md">
                        Clear
                    </button>
                    <p className='text-base font-semibold text-white uppercase'>Per Page :</p>
                    <input type="text" className='border-b p-1 w-12 text-center rounded-md' value={registersPerPage} onChange={handleItemPerPageChange} placeholder='Per Page' />
                </div>
            </div>

            {fromDate && toDate && (
                <div className="mb-2 p-3 bg-gradient-to-r from-orange-400 to-yellow-400 rounded-lg text-center rounded-lg flex justify-between">
                    <p className="text-base font-semibold">Cash Received: {formatCurrency(totalCashReceived)}</p>
                    <p className="text-base font-semibold">Card Received: {formatCurrency(totalCardReceived)}</p>
                    <p className="text-base font-semibold">UPI Received: {formatCurrency(totalDigitalReceived)}</p>
                    <p className="text-base font-semibold">Sales Amount: {formatCurrency(totalSalesAmount)}</p>
                </div>
            )}

            <div className="overflow-x-auto rounded-lg">
                <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
                    <thead className="bg-gradient-to-r from-red-500 to-blue-500 rounded-lg">
                        <tr>
                            <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">S.No</th>
                            <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">Start Date</th>
                            <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">In Hand Cash</th>
                            <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">Cash Received</th>
                            <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">Card Received</th>
                            <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">UPI Received</th>
                            <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">Total Sales Amount</th>
                            <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">Status</th>
                            <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">Created By</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {currentRegisters.map((register, index) => (
                            <tr key={register.id} className="even:bg-gray-100 hover:bg-green-200">
                                <td className="py-3 px-4 text-left">{index + 1 + (currentPage - 1) * registersPerPage}</td>
                                <td className="py-3 px-4 text-left">{formatDate(register.startDate)}</td>
                                <td className="py-3 px-4 text-left">{formatCurrency(register.inHandCash)}</td>
                                <td className="py-3 px-4 text-left">{formatCurrency(register.cashReceived)}</td>
                                <td className="py-3 px-4 text-left">{formatCurrency(register.cardReceived)}</td>
                                <td className="py-3 px-4 text-left">{formatCurrency(register.digitalReceived)}</td>
                                <td className="py-3 px-4 text-left">{formatCurrency(register.totalSalesAmount)}</td>
                                <td className="py-3 px-4 text-left">
                                    <span className={`px-2 py-0.5 rounded-lg ${register.status === 'open' ? 'bg-[#03fc0f]' : 'bg-yellow-400'}`}>{register.status}</span>
                                </td>
                                <td className="py-3 px-4 text-left">{register.createdBy}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="flex justify-between items-center mt-3">
                <button onClick={prevPage} disabled={currentPage === 1} className="px-3 py-1 bg-gray-300 rounded-md disabled:opacity-50">
                    Previous
                </button>
                <span>
                    {currentPage} of {Math.ceil(filteredRegisters.length / registersPerPage)}
                </span>
                <button onClick={nextPage}
                    disabled={currentPage === Math.ceil(filteredRegisters.length / registersPerPage)}
                    className="px-3 py-1 bg-gray-300 rounded-md disabled:opacity-50">
                    Next
                </button>
            </div>
        </div>
    );
};

export default RegisterTable;
