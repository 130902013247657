import React, { useState, useEffect } from 'react';

const StocksStatus = () => {
  const [warehouses, setWarehouses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState('');
  const [stockStatus, setStockStatus] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchWarehouses = async () => {
      try {
        const response = await fetch('https://pos.farm2bag.com/api/v1/warehouse', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        setWarehouses(result.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchWarehouses();
  }, [token]);

  useEffect(() => {
    const fetchStockStatus = async () => {
      if (!selectedWarehouseId) {
        return;
      }

      try {
        const url = selectedCategory
          ? `https://pos.farm2bag.com/api/v1/stocks/status/${selectedWarehouseId}?productCategoryId=${selectedCategory}`
          : `https://pos.farm2bag.com/api/v1/stocks/status/${selectedWarehouseId}`;

        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        
        // Check if result.data is null
        if (result.data === null) {
          setStockStatus({ message: result.message, products: [] }); // Set message if data is null
        } else if (result.data && result.data.products && result.data.products.length === 0) {
          setStockStatus({ message: result.message, products: [] }); // Set message if no products found
        } else {
          setStockStatus(result.data); // Set stock status to the actual data
        }
        setCurrentPage(1);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchStockStatus();
  }, [selectedWarehouseId, selectedCategory, token]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch('https://pos.farm2bag.com/api/v1/categories/getAll/master', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        const result = await response.json();

        if (response.ok) {
          setCategories(result.data);
        } else {
          throw new Error(result.message);
        }
      } catch (err) {
        setError(err.message);
      }
    };

    fetchCategories();
  }, [token]);

  const handleWarehouseChange = (event) => {
    setSelectedWarehouseId(event.target.value);
    setStockStatus(null);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setStockStatus(null);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const filteredProducts = stockStatus?.products?.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  ) || [];

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  const totalProducts = filteredProducts.length;
  const totalPages = Math.ceil(totalProducts / productsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

    // Calculate the overall total price
    const overallTotalPrice = filteredProducts.reduce(
      (total, product) => total + product.quantity * product.price,
      0
    );

  if (loading) return <div className="text-center p-4">Loading...</div>;
  if (error) return <div className="text-red-600 text-center p-4">Error: {error}</div>;

  return (
    <div className="container mx-auto p-6 bg-gray-50 min-h-screen">
      <h1 className="text-3xl font-bold text-blue-700 mb-6 text-center">Stocks Status</h1>

      <div className="flex flex-wrap space-x-2 mb-6">

        <div className="flex flex-col">
          <label htmlFor="search" className="mb-1 font-semibold"> Search by Product Name:</label>
          <input id="search" type="text" placeholder="🔍 Search by Product Name"
            value={searchTerm}
            onChange={handleSearchChange}
            className="border border-gray-300 rounded px-4 py-2 w-72"
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="warehouse" className="mb-1 font-semibold"> Select Warehouse:</label>
          <select id="warehouse"
            onChange={handleWarehouseChange}
            value={selectedWarehouseId}
            className={`border border-gray-300 rounded px-4 py-2 ${selectedWarehouseId ? 'bg-green-400' : 'bg-orange-400'}`}
          >
            <option value="" className="hidden"> Select Warehouse </option>
            {warehouses.map((warehouse) => (
              <option key={warehouse.id} value={warehouse.id}>
                {warehouse.name} , {warehouse.city} , {warehouse.country}
              </option>
            ))}
          </select>
        </div>

        <div className="flex flex-col">
          <label htmlFor="category" className="mb-1 font-semibold"> Select Category:</label>
          <select id="category"
            onChange={handleCategoryChange}
            value={selectedCategory}
            className={`border border-gray-300 rounded w-64 px-4 py-2 ${selectedCategory ? 'bg-green-400' : 'bg-white'}`}
          >
            <option value="">Select Category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.categoryName}
              </option>
            ))}
          </select>
        </div>

      </div>

      {stockStatus && (
        <div>
          {stockStatus.products && stockStatus.products.length === 0 && stockStatus.message ? ( // Check if there are no products and message exists
            <div className="text-center font-semibold bg-yellow-200 p-2 text-red-600">{stockStatus.message}</div> // Display message instead of table
          ) : (
            <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
              <thead>
                <tr className="bg-blue-600 text-white uppercase text-sm leading-normal">
                  <th className=" px-4 py-2">S.No</th>
                  <th className=" px-4 py-2 text-left">Product Name</th>
                  <th className=" px-4 py-2 text-left">Product Code</th>
                  <th className=" px-4 py-2">Quantity</th>
                  <th className=" px-4 py-2">Price (in Rs.)</th>
                  <th className=" px-4 py-2">Total Price (in Rs.)</th>
                  <th className=" px-4 py-2">Unit</th>
                </tr>
              </thead>
              <tbody className="text-black text-sm font-semibold">
                {currentProducts.map((product, index) => {
                  const totalPrice = product.quantity * product.price; // Calculate total price
                  return (
                    <tr key={product.productId} className="hover:bg-gray-100">
                      <td className=" px-4 py-2">{index + 1 + indexOfFirstProduct}</td>
                      <td className=" px-4 py-2 text-left">{product.name}</td>
                      <td className=" px-4 py-2 text-left">{product.productsCode}</td>
                      <td className=" px-4 py-2">{product.quantity.toFixed(2)}</td>
                      <td className=" px-4 py-2">{product.price.toFixed(2)}</td>
                      <td className=" px-4 py-2">{totalPrice.toFixed(2)}</td>
                      <td className=" px-4 py-2">{product.unitSymbol}</td>
                    </tr>
                  );
                })}
              </tbody>

              <tfoot>
                <tr className="bg-gray-200 font-bold">
                  <td colSpan="5" className="text-right px-4 py-2">Overall Total Price:</td>
                  <td className="px-4 py-2">{overallTotalPrice.toFixed(2)}</td>
                  <td></td>
                </tr>
              </tfoot>

            </table>
          )}

          <div className="flex justify-between items-center mt-4">
            <div>
              Page {currentPage} of {totalPages}
            </div>
            <div>
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className={`border border-gray-300 rounded px-4 py-2 mr-2 text-white ${currentPage === 1 ? 'bg-gray-300' : 'bg-blue-600 hover:bg-blue-700'}`}
              >
                Previous
              </button>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className={`border border-gray-300 rounded px-4 py-2 text-white ${currentPage === totalPages ? 'bg-gray-300' : 'bg-blue-600 hover:bg-blue-700'}`}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StocksStatus;